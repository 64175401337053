import React, { useState } from "react";
import RewardModal from "../partials/modals/Reward";

const RewardDemo: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const id =
    navigator.userAgent.indexOf("Android") > 0
      ? "3Rukrlbm3MW9"
      : "fgHTtJgwf3zQ";
  return (
    <div className="p-5">
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              フォームDEMO (送信ボタンクリックでreward)
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  About
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={100}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              window.scrollTo(0, 0);
              setOpen(true);
            }}
          >
            送信
          </button>
        </div>
      </form>
      <RewardModal
        open={open}
        setOpen={setOpen}
        id={id}
        adCount={3}
        onMovieComplete={() => alert("finished")}
      />
    </div>
  );
};
export default RewardDemo;
