import React from 'react';

interface IconWithTitleProps {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    title: string;
    url: string;
    isActive: boolean;
}

const IconWithTitle: React.FC<IconWithTitleProps> = ({icon, title, url, isActive}) => {
    const IconComponent = icon;
    const baseClasses = 'flex font-medium rounded-lg hover:bg-gray-100 hover:text-cwaipurple-600';
    const activeClasses = isActive ? 'bg-gray-100 text-cwaipurple-600' : '';
    return (
        <div className={`${baseClasses} ${activeClasses}`}>
            <a href={url} className="p-1 w-full">
                <div className="flex items-center space-x-4">
                    <IconComponent className="w-6 h-6" />
                    <span className="text-md font-semibold">{title}</span>
                </div>
            </a>
        </div>
    );
};

export default IconWithTitle;
