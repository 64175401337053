import React from "react";
import PlanFaqs from "../../../users/plans/Faqs";

const UsersPlansShow: React.FC = () => {
  return (
    <>
      <PlanFaqs />
    </>
  );
};
export default UsersPlansShow;
