import getClient from "../client";
import { PostImageAiFormParams } from "../../../interfaces/requests/image_ai_form";

/**
 * サブカテゴリー一覧を取得
 */
export const vendorBulkPostImageAiForms = async (
  params: PostImageAiFormParams
) => {
  return getClient().post(`/vendors/image_ai_forms/bulk_create.json`, params);
};
