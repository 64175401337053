import React from "react";
type Props = {
  modelType: string;
  inputFields: {
    label: string;
    inputName: string;
  }[];
  register: any;
  errors: any;
  fields: Array<{
    id: string;
    lastSystemMessage?: string;
  }>;
  append: () => void;
  remove: (index: number) => void;
  claudeFields: Array<{
    id: string;
    claudeUserMessage?: string;
  }>;
  appendClaude: () => void;
  removeClaude: (index: number) => void;
};
const AdminsAisPromptEditor: React.FC<Props> = (props) => {
  return (
    <>
      {props.modelType == "chatgpt" && (
        <div className="mb-4 text optional ai_prompt">
          <label
            className="block text optional text-sm font-medium text-gray-600"
            htmlFor="ai_prompt"
          >
            プロンプト
          </label>
          <div className="p-3 bg-gray-100 border mb-2 rounded-md">
            <h2 className="text-sm">置換ルール</h2>
            <div className="flex flex-wrap">
              {props.inputFields.map((inputField, i) => {
                return (
                  <div
                    className="text-xs mt-1 text-gray-600 mr-2"
                    key={`ai-form-${i}`}
                  >
                    {inputField.label}: %{inputField.inputName}%
                  </div>
                );
              })}
            </div>
          </div>
          <textarea
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
            rows={20}
            id="ai_prompt"
            {...props.register("prompt", { required: true })}
          ></textarea>
          {props.errors.prompt?.type === "required" && (
            <p className="font-semibold text-sm text-red-500">
              入力してください
            </p>
          )}
        </div>
      )}
      {(props.modelType == "claude" || props.modelType == "claude_rag") && (
        <div className="mb-4 text optional ai_prompt">
          <label
            className="block text optional text-sm font-medium text-gray-600"
            htmlFor="ai_prompt"
          >
            システムPrompt（任意）
          </label>
          <div className="p-3 bg-gray-100 border mb-2 rounded-md">
            <h2 className="text-sm">置換ルール</h2>
            <div className="flex flex-wrap">
              {props.inputFields.map((inputField, i) => {
                return (
                  <div
                    className="text-xs mt-1 text-gray-600 mr-2"
                    key={`ai-form-${i}`}
                  >
                    {inputField.label}: %{inputField.inputName}%
                  </div>
                );
              })}
            </div>
          </div>
          <textarea
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
            rows={3}
            id="ai_system_prompt"
            {...props.register("claudeSystem")}
          ></textarea>
        </div>
      )}

      {props.modelType == "chatgpt" && (
        <>
          <div className="mb-4 text optional ai_assist_message">
            <label
              className="block text optional text-sm font-medium text-gray-600"
              htmlFor="ai_assist_message"
            >
              追加指示placeholder
            </label>
            <textarea
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
              rows={3}
              id="ai_order_assist_message"
              placeholder="追加指示を書いてください（例）続きを書いて"
              {...props.register("orderAssistMessage")}
            ></textarea>
          </div>
          <div className="mb-4 text optional ai_prompt">
            <label
              className="block text optional text-sm font-medium text-gray-600"
              htmlFor="ai_prompt"
            >
              追加指示用プロンプト
            </label>
            <div className="p-3 bg-gray-100 border mb-2 rounded-md">
              <h2 className="text-sm">置換ルール</h2>
              <div className="flex flex-wrap">
                {props.inputFields.map((inputField, i) => {
                  return (
                    <div
                      className="text-xs mt-1 text-gray-600 mr-2"
                      key={`ai-form-${i}`}
                    >
                      {inputField.label}: %{inputField.inputName}%
                    </div>
                  );
                })}
                <div className="text-xs mt-1 text-gray-600 mr-2">
                  前回の生成文章: %last_text%
                </div>
                <div className="text-xs mt-1 text-gray-600 mr-2">
                  追加指示文: %order_text%
                </div>
              </div>
            </div>
            <textarea
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
              rows={20}
              id="ai_order_prompt"
              {...props.register("orderPrompt")}
            ></textarea>
          </div>
        </>
      )}

      {props.modelType == "chatgpt" && (
        <>
          {props.fields.map((_, i) => {
            return (
              <div
                className="mb-4 text optional ai_user_message"
                key={`last-system-message-${i}`}
              >
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_user_message"
                >
                  最後のシステムメッセージ - {i + 1}
                </label>
                <div className="grid grid-cols-12 items-top">
                  <textarea
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                    rows={3}
                    id="ai_user_message"
                    {...props.register(`lastSystemMessage.${i}`)}
                  ></textarea>
                  <div className="mt-1 text optional ai_user_message col-span-1">
                    <div className="text-center">
                      <button
                        type="button"
                        className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => props.remove(i)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="text-center">
            <button
              type="button"
              className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => props.append()}
            >
              追加
            </button>
          </div>
        </>
      )}
      {(props.modelType == "claude" || props.modelType == "claude_rag") && (
        <>
          {props.claudeFields.map((_, i) => {
            return (
              <div
                className="mb-4 text optional ai_user_message"
                key={`claude-user-message-${i}`}
              >
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_user_message"
                >
                  ユーザメッセージPrompt - {i + 1}
                </label>
                <div className="grid grid-cols-12 items-top">
                  <textarea
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                    rows={20}
                    id="ai_claude_user_message"
                    {...props.register(`claudeUserMessage.${i}`, {
                      required: true,
                    })}
                  ></textarea>
                  <div className="mt-1 text optional ai_user_message col-span-1">
                    <div className="text-center">
                      <button
                        type="button"
                        className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => props.removeClaude(i)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  {props.errors.claudeUserMessage?.[i]?.type === "required" && (
                    <p className="font-semibold text-sm text-red-500 col-span-12">
                      入力してください
                    </p>
                  )}
                </div>
              </div>
            );
          })}
          <div className="text-center">
            <button
              type="button"
              className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => props.appendClaude()}
            >
              追加
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default AdminsAisPromptEditor;
