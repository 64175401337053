import React from "react";
import { User } from "../../../../interfaces/user";
import { toast } from "react-toastify";
import BankForm from "../../../users/affiliates/BankForm";
import { updateUserBankAccount } from "../../../../libs/api/users/bank_account";
type Props = {
  user: User;
};

const UsersAffiliatesIndex: React.FC<Props> = (props) => {
  const onSubmit = async (data) => {
    try {
      await updateUserBankAccount(data);
      toast.success("登録しました");
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <>
      <BankForm onSubmit={onSubmit} bankAccount={props.user.bankAccount} />
    </>
  );
};
export default UsersAffiliatesIndex;
