import React, {useState} from "react";
import {Ai} from "@/interfaces/ai";
import {StandardCardFull} from "@/components/biz/partials/ais/AiCards/StandardCards";

export type Props = {
    ais: Ai[];
}
const RecommendedAiMenu: React.FC<Props> = (props) => {
    return (
        <section className="recommended-ai-menu">
            <div className="bg-gray-100 p-8">
                <h2 className="text-lg font-semibold mb-4">おすすめのAIメニュー</h2>
                <ul className="grid sm:grid-cols-4 grid-col-1 gap-4 bg-gray-100">
                    {props.ais.map((ai, index) => (
                        <li className="bg-gray-100" key={`recommend-${index}`}>
                            <StandardCardFull ai={ai}/>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default RecommendedAiMenu;
