import getClient from "../client";

export const createOgpSkinBudgetSummary = async (params) => {
  return getClient().post(
    `/users/ogp_skins/create_budget_summary.json`,
    params
  );
};

export const createOgpSkinAiImage = async (params) => {
  return getClient().post(`/users/ogp_skins/create_image_ai.json`, params);
};
