import React, { useState, useEffect } from "react";
import { vendorCloneAi } from "../../../../libs/api/vendors/ai";
import { toast } from "react-toastify";

type Props = {
  slug: string;
};

const AdminsAiCloneLink: React.FC<Props> = (props) => {
  const onClickClone = async (e) => {
    e.preventDefault();
    await vendorCloneAi(props.slug);
    toast("複製しました");
  };
  return (
    <>
      <div>
        <a
          className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
          href="#"
          onClick={onClickClone}
        >
          複製する
        </a>
      </div>
    </>
  );
};
export default AdminsAiCloneLink;
