import getClient from "../client";
import { GetAisParams } from "../../../interfaces/requests/ai";
/**
 * サブカテゴリー一覧を取得
 */
export const usersGetImageAis = async (params: GetAisParams) => {
  return getClient().get(`/users/image_ais.json`, {
    params: params,
  });
};

/*
  サジェスチョン
 */
export const suggestImageAi = async (query: string) => {
  const response = await getClient().get(`/users/image_ais/suggestion.json`, {
    params: {
      q: query,
    },
  });
  return response.data; // AxiosResponseのdata部分を返す
};
