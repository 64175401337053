import React, { useRef, useMemo, useEffect, useState } from "react";
import Header from "../../../users/ais/chat/Header";
import AiMessage from "../../../users/ais/chat/messages/Ai";
import UserMessage from "../../../users/ais/chat/messages/User";
import FreeSpeedLimit from "../../../users/ais/chat/messages/FreeSpeedLimit";
import PromptSettingForm from "../../../users/ais/chat/PromptSettingForm";
import MessageForm from "../../../users/ais/chat/MessageForm";
import RewardModal from "../../../partials/modals/Reward";
import EmailConfirm from "../../../../components/partials/modals/EmailConfirm";
import { Ai } from "../../../../interfaces/ai";
import { AdMovie } from "../../../../interfaces/ad_movie";
import { User } from "../../../../interfaces/user";
import { Message } from "../../../../interfaces/message";
import { AiForm } from "../../../../interfaces/ai_form";
import { createConsumer } from "@rails/actioncable";
import { toggleUserFavorite } from "../../../../libs/api/users/user_favorite_ai";
import { checkEmailConfirmStatus } from "../../../../libs/api/user";
import UpgradeAlert from "../../../../components/partials/modals/UpgradeAlert";
import Ckeditor from "../../../../components/partials/forms/input/Ckeditor";
import SaveGeneratedText from "../../../../components/partials/modals/SaveGeneratedText";
import { FileSignature } from "lucide-react";
type Props = {
  ai: Ai;
  aiForms: AiForm[];
  cableEndpoint: string;
  user: User;
  favorites: Ai[];
  adCount: number;
  adMovies: AdMovie[];
};

const UsersAisShow: React.FC<Props> = (props) => {
  const [formData, setFormData] = useState(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [streaming, setStreaming] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);
  const [showEditor, setShowEditor] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [editorBody, setEditorBody] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [openPremiumOnly, setOpenPremiumOnly] = useState(false);
  const [initEditor, setInitEditor] = useState(false);
  const [emailConfirmModalOpen, setEmailConfirmModalOpen] = useState(false);
  const freeSpeedLimitRef = useRef<HTMLDivElement>(null);
  const [scrollAfterReward, setScrollAfterReward] = useState(0);

  const [currentGenerateCount, setCurrentGenerateCount] = useState(
    props.user.currentGenerateCount
  );
  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const [channelToken, setChannelToken] = useState(generateRandomString(20));
  const [formToken, setFormToken] = useState("");

  const addFavorite = async (ai: Ai) => {
    setFavorites((prevFavorites) => [...prevFavorites, ai]);
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("addFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
  };

  const removeFavorite = async (ai: Ai) => {
    setFavorites((prevFavorites) =>
      prevFavorites.filter((faAi) => faAi.id !== ai.id)
    );
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("removeFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
  };

  const messageBottomRef = useRef(null);

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription != null) {
      subscription.perform("stop", {
        token: formToken,
      });
    }
  };

  const asystMessage = () => {
    if (props.ai.assistMessage == null) {
      return "以下の情報を入れてください";
    } else {
      return props.ai.assistMessage;
    }
  };

  const isFree = () => {
    if (props.user) {
      return !props.user.isPaidText;
    } else {
      return false;
    }
  };

  const isBasic = () => {
    return props.user.planName === "basic";
  };

  const saveEditor = () => {
    if (isFree()) {
      setOpenUpgrade(true);
    } else {
      setOpenSave(true);
    }
  };

  const cable = useMemo(() => createConsumer(props.cableEndpoint), []);

  // channelTokenが変更されたときにサブスクリプションを作成
  useEffect(() => {
    const sub = cable.subscriptions.create(
      { channel: "PrivateChatChannel", token: channelToken },
      {
        connected: () => {
          // コネクションが確立したことをここで処理
          console.log("ws connected");
        },
        received: (res) => {
          // メッセージ受信時の処理
          if (res.error) {
            alert(res.error);
            setMessage(
              (prev) => prev + "申し訳ございません、問題が発生しました"
            );
            if (res?.errorCode == "upgrade") {
              location.reload();
            }
          } else if (res.status) {
            if (res.status == "finish") {
              setStreaming(false);
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data);
            }
          }
        },
      }
    );
    setSubscription(sub);

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [channelToken, cable]);

  const submitForm = (data) => {
    setShowMessage(true);
    setCurrentGenerateCount(currentGenerateCount + 1);
    const event = new CustomEvent("incrementGenerateCount", {
      detail: currentGenerateCount + 1,
    });
    window.dispatchEvent(event);
    setStreaming(true);
    setFormData(data);
    // ここでランダムの文字列生成
    const _token = generateRandomString(20);
    setFormToken(_token);
    if (subscription) {
      subscription.perform("stream_message", {
        ai_slug: props.ai.slug,
        form_data: data,
        token: _token,
        user_token: props.user.token,
      });
    }
    setMessage("");
  };

  const onPromptSettingFormSubmit = async (data) => {
    if (currentGenerateCount >= props.user.generateLimit && isBasic()) {
      setModalOpen(true);
      return;
    } else if (isFree() && !props.user.emailConfirmed) {
      let res = await checkEmailConfirmStatus();
      if (!res.data.emailConfirmed) {
        setEmailConfirmModalOpen(true);
      }
    } else if (isFree() && props.ai.paidFlag && currentGenerateCount >= 5) {
      setOpenPremiumOnly(true);
      return;
    } else if (isFree() && currentGenerateCount >= 5) {
      setFormData(data);
      setTimeout(() => {
        setShowRewardModal(true);
      }, 100);
    } else {
      submitForm(data);
    }
  };

  const showFreeSpeedLimit = () => {
    return !props.user.isPaidText;
  };

  // useEffect を用いて subscription のクリーンアップを行う
  useEffect(() => {
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [subscription]);

  useEffect(() => {
    if (isFree() && props.ai.paidFlag && currentGenerateCount >= 5) {
      setOpenPremiumOnly(true);
    }
  }, [props.ai.paidFlag]);

  const addMessage = (orderMessage: Message) => {
    if (subscription != null) {
      // ここでランダムの文字列生成
      const token = generateRandomString(20);
      setFormToken(token);
      //既存のAIが生成している分を追加
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          body: message,
          type: "aiSent",
        },
        orderMessage,
      ]);

      // typeが 'aiSent' のメッセージのみをフィルタリングし、そのbodyを結合します。
      const concatenatedAISentBodies = messages
        .filter((message) => message.type === "aiSent")
        .map((message) => message.body)
        .join("");

      // 結合した文字列の最後の1000文字を取得します。
      const last1000CharsOfAISent = concatenatedAISentBodies.slice(-1000);
      console.log("last1000CharsOfAISent", last1000CharsOfAISent);

      // ここでstream開始
      subscription.perform("order_stream_message", {
        ai_slug: props.ai.slug,
        form_data: formData,
        token: token,
        user_token: props.user.token,
        last_text: last1000CharsOfAISent,
        order_text: orderMessage.body,
      });

      //生成中の文章をリセット
      setMessage("");
      // mock streamingしているように
      setStreaming(true);
    }
  };

  const toggleEditor = () => {
    setShowEditor(!showEditor);
  };

  const addEditorBody = (s) => {
    //console.log("addEditorBody", editorBody + "\n" + s);
    const formattedString = (editorBody + "\n" + s).replace(/\n/g, "<br />");
    setEditorBody(formattedString);
    setShowEditor(true);
    //editor初期化
    setInitEditor(true);
  };

  return (
    <>
      <div className="flex-1 justify-between flex flex-col pb-32">
        <Header
          ai={props.ai}
          favorites={favorites}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
        />
        <div className="sm:grid sm:grid-cols-2">
          <div className="py-4 md:px-4 pt-10">
            <AiMessage
              ai={props.ai}
              message={asystMessage()}
              user={props.user}
              streaming={false}
              editable={false}
            />
            <div className="mt-10">
              <PromptSettingForm
                submit={(data) => onPromptSettingFormSubmit(data)}
                aiForms={props.aiForms}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
                user={props.user}
              />
            </div>
          </div>
          <div className="p-4">
            <div ref={freeSpeedLimitRef} />
            <div className="flex flex-col space-y-12 py-5 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
              {messages.map((message, i) => {
                if (message.type == "userSent") {
                  return (
                    <UserMessage
                      message={message.body}
                      key={`user-sent-${i}`}
                    />
                  );
                } else {
                  return (
                    <AiMessage
                      ai={props.ai}
                      message={message.body}
                      showCounter={true}
                      showAction={true}
                      user={props.user}
                      streaming={false}
                      key={`ai-sent-${i}`}
                      addEditorBody={addEditorBody}
                      openSave={openSave}
                      openUpgrade={openUpgrade}
                      setOpenSave={setOpenSave}
                      setOpenUpgrade={setOpenUpgrade}
                      setMessage={setMessage}
                      editable={true}
                    />
                  );
                }
              })}
              {showMessage && showFreeSpeedLimit() && (
                <FreeSpeedLimit user={props.user} />
              )}

              {showMessage && (
                <AiMessage
                  ai={props.ai}
                  message={message}
                  addEditorBody={addEditorBody}
                  showCounter={true}
                  showAction={true}
                  user={props.user}
                  streaming={streaming}
                  openSave={openSave}
                  openUpgrade={openUpgrade}
                  setOpenSave={setOpenSave}
                  setOpenUpgrade={setOpenUpgrade}
                  setMessage={setMessage}
                  editable={!streaming}
                />
              )}
              <div ref={messageBottomRef}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative justify-end items-center hidden md:block">
        <div className="relative min-h-screen flex justify-end items-center">
          <div
            className={`transform transition-transform duration-500 ease-in-out fixed right-0 top-16 flex justify-end items-start ${
              showEditor ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <button
              onClick={toggleEditor}
              className={`transform transition-transform duration-500 ease-in-out bg-white border p-1 rounded-tl-lg rounded-bl-lg rounded-tr-none rounded-br-none text-xs ${
                showEditor ? "translate-y-0" : "translate-y-full"
              }`}
              style={{ marginLeft: "-30px" }}
            >
              <span className="block">
                {/* <i className="fa-solid fa-save cursor-pointer"></i> */}
                <FileSignature />
              </span>
              {showEditor
                ? "閉じる".split("").map((char, index) => (
                    <span key={index} className="block text-sm">
                      {char}
                    </span>
                  ))
                : "エディタI".split("").map((char, index) => (
                    <span key={index} className="block text-sm">
                      {char}
                    </span>
                  ))}
            </button>
            <div className="top-0">
              <Ckeditor
                inputName={`aiEditor`}
                body={editorBody}
                disableImage={true}
                setEditorBody={setEditorBody}
                initEditor={initEditor}
                setInitEditor={setInitEditor}
              />
              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-full bg-indigo-500 w-full py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    saveEditor();
                  }}
                >
                  テキストを保存する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditor && (
        <div className="md:hidden">
          <div
            className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => setShowEditor(false)}
          ></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setShowEditor(false)}
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center">
                    <Ckeditor
                      inputName={`aiEditor`}
                      body={editorBody}
                      disableImage={true}
                      setEditorBody={setEditorBody}
                      initEditor={initEditor}
                      setInitEditor={setInitEditor}
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 flex justify-end">
                  <button
                    type="button"
                    className="inline-flex mr-2 justify-center rounded-md bg-gray-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    onClick={() => {
                      setShowEditor(false);
                    }}
                  >
                    閉じる
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      saveEditor();
                    }}
                  >
                    保存する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {streaming && (
        <>
          <div className="fixed bottom-0 left-0 w-full flex justify-center">
            <button
              type="button"
              className="inline-flex mb-5 justify-center items-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={cancelStreaming}
            >
              <i className="fa-solid fa-stop mr-2"></i>
              <span>生成をストップ</span>
            </button>
          </div>
        </>
      )}
      {!streaming && showMessage && props.ai.showOrderInput && (
        <MessageForm addMessage={addMessage} />
      )}
      <UpgradeAlert
        open={modalOpen}
        setOpen={setModalOpen}
        title={`basicプランの生成上限に達しました`}
        message={`basicプランではAIの月ごとの生成が「30回」までとなっております。以下のボタンからプラン選択ページに移動し、Premiumプランにアップグレードしてください`}
      />
      <UpgradeAlert
        open={openPremiumOnly}
        setOpen={setOpenPremiumOnly}
        title={`こちらの生成ツールはpremiumプラン限定です。`}
        message={`以下のボタンからプラン選択ページに移動し、有料プランにアップグレードしてください`}
      />
      <SaveGeneratedText
        text={editorBody}
        open={openSave}
        setOpen={setOpenSave}
        ai={props.ai}
      />
      <UpgradeAlert
        open={openUpgrade}
        setOpen={setOpenUpgrade}
        title={`プレミアムプラン限定機能となります`}
        message={`こちらの機能をご利用いただくには、以下のボタンからプラン詳細ページに移動し、プレミアムプランにアップグレードしてください`}
      />
      <RewardModal
        open={showRewardModal}
        setOpen={setShowRewardModal}
        onMovieComplete={() => {
          submitForm(formData);
        }}
        scrollAfterReward={scrollAfterReward}
        adCount={props.adCount}
        adMovies={props.adMovies}
      />
      <EmailConfirm
        open={emailConfirmModalOpen}
        setOpen={setEmailConfirmModalOpen}
        title={`メールアドレスの確認をお願いします`}
        message={`ご登録頂いたメールアドレスに、登録確認URLを送信いたしましたのでURLにアクセス後、再度機能をご利用ください`}
      />
    </>
  );
};
export default UsersAisShow;
