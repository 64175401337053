import React, { useState } from "react";
import { Invoice } from "../../../interfaces/invoice";
import dayjs from "dayjs";
type Props = {
  onSubmit: (selectedInvoices) => void;
  invoices: Invoice[];
  selectedInvoices: Invoice[];
  setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>;
};
const AffiliateTable: React.FC<Props> = (props) => {
  const affiliateStatusName = (s: string) => {
    if (s == "unapplied") {
      return (
        <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
          未申請
        </span>
      );
    } else if (s == "applied") {
      return (
        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
          申請済
        </span>
      );
    }
  };
  const handleInvoiceSelection = (invoice: Invoice, isSelected: boolean) => {
    if (isSelected) {
      props.setSelectedInvoices((prev) => [...prev, invoice]);
    } else {
      props.setSelectedInvoices((prev) =>
        prev.filter((i) => i.id !== invoice.id)
      );
    }
  };

  const totalAmount = props.invoices.reduce(
    (acc, invoice) => acc + invoice.affiliateFee,
    0
  );

  const selectedTotalAmount = props.selectedInvoices.reduce(
    (acc, invoice) => acc + invoice.affiliateFee,
    0
  );

  const canSubmit = () => {
    return selectedTotalAmount > 6000;
  };

  const submitBtn = () => {
    if (canSubmit()) {
      return (
        <div>
          <button
            type="button"
            onClick={() => props.onSubmit(props.selectedInvoices)}
            className="rounded-full bg-gradient-to-r from-blue-500 to-purple-600 py-2 px-4 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
          >
            振込申請する
          </button>
          <p className="text-xs text-gray-400">※6,000円以上の報酬が必要です</p>
        </div>
      );
    } else {
      return (
        <div>
          <button className="rounded-full bg-gray-200 py-2 px-4 mb-4 text-md font-semibold text-gray-400 border shadow-sm hover:opacity-90">
            振込申請する
          </button>
          <p className="text-xs text-gray-400">※6,000円以上の報酬が必要です</p>
        </div>
      );
    }
  };

  return (
    <>
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="px-3 py-3" scope="col">
              #
            </th>
            <th className="px-3 py-3" scope="col">
              報酬
            </th>
            <th className="px-3 py-3" scope="col">
              発生日
            </th>
            <th className="px-3 py-3" scope="col">
              申請期限
            </th>
            <th className="px-3 py-3" scope="col">
              申請ステータス
            </th>
            <th className="px-3 py-3" scope="col">
              申請
            </th>
          </tr>
        </thead>
        <tbody className="text-xs">
          {props.invoices.map((invoice) => {
            if (invoice.affiliateFee == 0) {
              return <></>;
            }
            return (
              <tr className="bg-white border-b" key={invoice.id}>
                <td className="px-3 py-3 text-xs flex" scope="col">
                  {invoice.id}
                </td>
                <td className="px-3 py-3" scope="col">
                  {invoice.affiliateFee.toLocaleString("ja-JP") + "円"}
                </td>
                <td className="px-3 py-3" scope="col">
                  {dayjs(invoice.datetime).format("YYYY-MM-DD HH:mm")}
                </td>
                <td className="px-3 py-3" scope="col">
                  {dayjs(invoice.payRequestEndAt).format("YYYY-MM-DD HH:mm")}
                </td>
                <td className="px-3 py-3" scope="col">
                  {affiliateStatusName(invoice.affiliateStatus)}
                </td>
                <td className="px-3 py-3" scope="col">
                  {dayjs().isAfter(dayjs(invoice.payRequestEndAt)) &&
                  invoice.affiliateStatus == "unapplied" ? (
                    <>期限切れ</>
                  ) : (
                    <>
                      {invoice.affiliateStatus == "unapplied" && (
                        <input
                          type="checkbox"
                          name="selected_invoice_ids[]"
                          onChange={(e) =>
                            handleInvoiceSelection(invoice, e.target.checked)
                          }
                        />
                      )}
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th className="px-3 py-3" scope="col">
              合計
            </th>
            <th className="px-3 py-3" scope="col">
              {totalAmount.toLocaleString("ja-JP") + "円"}
            </th>
            <th></th>
            <th></th>
            <th>
              {selectedTotalAmount > 0 &&
                selectedTotalAmount.toLocaleString("ja-JP") + "円"}
            </th>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end items-end mt-6">{submitBtn()}</div>
    </>
  );
};

export default AffiliateTable;
