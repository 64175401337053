import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactLoading from "react-loading";
import {
  usersPostChangeFreeDallE,
  usersPostChangeOmniFree,
} from "@/libs/api/users/stripe";
import { User } from "@/interfaces/user";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

type Props = {
  title: string;
  message?: string;
  open: boolean;
  setOpen: (bool: boolean) => void;
  user: User;
  isDallE?: boolean;
  omni?: boolean;
  serviceType?: "text" | "image" | "whisper" | "video" | "voice";
};

const ChangePlanConfirm: React.FC<Props> = (props) => {
  console.log("props", props);
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClickChangeBtn = async () => {
    if (props.omni) {
      await usersPostChangeOmniFree(props.serviceType);
      location.href =
        "/users/plans/redirect_after_omni_plan_change?service_type=" +
        props.serviceType;
    } else {
      if (props.isDallE) {
        await usersPostChangeFreeDallE();
        location.href = "/users/plans/redirect_after_change_to_free_dall_e";
      } else {
        props.setOpen(false);
        location.href = "/users/surveys/new";
      }
    }
  };

  const trialEndAlert = () => {
    if (!props.user.isTrial) {
      return <></>;
    } else {
      return (
        <div className="rounded-md bg-yellow-50 p-4 mt-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-bold text-yellow-800">
                体験プランが終了します。
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  体験プランを終了してフリープランにダウングレードしますか？
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {loading ? (
                  <>
                    <div className="flex justify-center mt-3">
                      <ReactLoading color={`#009dff`} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="sm:flex sm:items-start">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className="h-8 w-8 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {props.title}
                          </Dialog.Title>

                          {props.message != "" && (
                            <div className="mt-2">
                              <p className="text-sm font-bold text-red-500">
                                {props.message}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-yellow-400 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-yellow-600 sm:ml-3 sm:w-auto"
                        onClick={handleClickChangeBtn}
                      >
                        <span className="underline decoration-red-500 decoration-4">
                          同意して
                        </span>
                        ダウングレードに進む
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => props.setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        キャンセル
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ChangePlanConfirm;
