import React from "react";
import {Ai} from "@/interfaces/ai";
import {AiCardImage} from "../AiCards/AiCardCommon";
import AiCardContent from "./AiCardContent";
import FavoriteButton from "../FavoriteButton";

type Props = {
    ai: Ai;
    favorites: Ai[];
    setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const Index: React.FC<Props> = (props) => {
    // TODO: Newアイコンの表示
    return (
        <div className="relative h-full w-[250px]">
            <a
                className="border p-5 bg-white flex flex-col rounded-lg h-full"
                href={`/users/ais/${props.ai.slug}`}
            >
                <AiCardImage ai={props.ai}/>
                <AiCardContent ai={props.ai}/>
            </a>
            <FavoriteButton
                ai={props.ai}
                favorites={props.favorites}
                setFavorites={props.setFavorites}
            />
        </div>
    );
};

export default Index;
