import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import ChangeToFreeConfirm from "../../partials/modals/ChangeToFreeConfirm";
type Props = {
  user: User;
};

const UsersPlan: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <a
        className="basis-full md:basis-1/3 rounded-lg bg-blue-600 text-white p-3 shadow text-center"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        有料プランの解約
      </a>
      <ChangeToFreeConfirm
        title={`フリープランにダウングレードします`}
        message={`AIマーケティング大学内のプレミアムプラン限定コミュニティの参加資格も失います。本当にダウングレードしますか？`}
        open={open}
        setOpen={setOpen}
        user={props.user}
      />
    </>
  );
};
export default UsersPlan;
