import React, { useState, useEffect } from "react";
import { User } from "../../../../interfaces/user";
import { PlanDallE } from "../../../../interfaces/plan_dall_e";
import { Coupon } from "../../../../interfaces/coupon";
type Props = {
  plans: PlanDallE[];
  user: User;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
};
import PlanTable from "../../../users/plan_dall_es/Table";
import PlanMenu from "../../../users/plan_dall_es/MenuSpan";
import PlanFaqs from "../../../users/plans/Faqs";

const UsersPlans: React.FC<Props> = (props) => {
  const [plans, setPlans] = useState(props.plans);
  const [interval, setInterval] = useState(null);
  const [intervalCount, setIntervalCount] = useState(null);

  const filterPlans = (interval, intervalCount) => {
    const filteredPlans = props.plans.filter(
      (plan) =>
        plan.interval === interval && plan.intervalCount === intervalCount
    );
    setInterval(interval);
    setIntervalCount(intervalCount);
    setPlans(filteredPlans);
  };

  useEffect(() => {
    if (props.user.planDallE == null) {
      filterPlans("year", 1);
    } else {
      filterPlans(
        props.user.planDallE?.interval,
        props.user.planDallE?.intervalCount
      );
    }
  }, [props.user.planDallE]);

  const intervalText = () => {
    if (interval == "year") {
      return "一年分一括払い";
    } else if (interval == "month" && intervalCount == 6) {
      return "6ヶ月分一括払い";
    }
  };

  const perIntervalText = () => {
    if (interval == "year") {
      return "の年間ごとのお支払いになります";
    } else if (interval == "month" && intervalCount == 6) {
      return "の6ヶ月ごとのお支払いになります";
    }
  };

  return (
    <>
      {!(interval == "month" && intervalCount == 1) && (
        <p className="mb-6 text-center">
          {intervalText()}
          になるので月払いプランより一回辺りのお値段がかなりお安い価格になっています。
        </p>
      )}
      <div className="flex justify-center mb-12">
        <PlanMenu user={props.user} filterPlans={filterPlans} />
      </div>
      <PlanTable
        plans={plans}
        user={props.user}
        stripeKey={props.stripeKey}
        ThreeDSecureSupported={props.ThreeDSecureSupported}
        coupon={props.coupon}
        perIntervalText={perIntervalText()}
      />
      <PlanFaqs />
    </>
  );
};
export default UsersPlans;
