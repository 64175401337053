interface CredentialResponse {
  credential?: string;
  select_by?:
    | "auto"
    | "user"
    | "user_1tap"
    | "user_2tap"
    | "btn"
    | "btn_confirm"
    | "brn_add_session"
    | "btn_confirm_add_session";
  clientId?: string;
}
import React, { useState, useEffect } from "react";
type Props = {
  clientId: string;
  handleRes: (res: CredentialResponse) => void;
};
const ConnectGoogleDrive: React.FC<Props> = (props) => {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

  useEffect(() => {
    console.log("useEffect called"); // ここに追加
    if (gsiScriptLoaded) return;

    const handleGoogleSignIn = (res: CredentialResponse) => {
      if (!res.clientId || !res.credential) {
        return;
      }

      // Get the authCode
      window.google.accounts.id.prompt((promptResponse) => {
        const authCode = promptResponse.getAuthResponse().code;
        console.log("Auth code:", authCode);
      });

      props.handleRes(res);
    };

    const initializeGsi = () => {
      console.log("initializeGsi called"); // ここに追加

      if (!window.google || gsiScriptLoaded) return;

      setGsiScriptLoaded(true);
      window.google.accounts.id.initialize({
        client_id: props.clientId,
        callback: handleGoogleSignIn,
        scope: "https://www.googleapis.com/auth/drive.file",
      });
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      window.google?.accounts.id.cancel();
      document.getElementById("google-client-script")?.remove();
    };
  }, [gsiScriptLoaded, props.clientId, props.handleRes]);
  if (gsiScriptLoaded == false) {
    return <></>;
  }
  return (
    <>
      <a
        className="g_id_signin inline-flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        rel="nofollow"
        href="#"
      >
        <svg
          className="w-5 h-5 mr-2"
          fill="none"
          viewBox="0 0 21 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_13183_10121)">
            <path
              d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
              fill="#3F83F8"
            ></path>
            <path
              d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
              fill="#34A853"
            ></path>
            <path
              d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
              fill="#FBBC04"
            ></path>
            <path
              d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
              fill="#EA4335"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_13183_10121">
              <rect
                fill="white"
                height="20"
                transform="translate(0.5)"
                width="20"
              ></rect>
            </clipPath>
          </defs>
        </svg>
        GoogleDriveと連携
      </a>
    </>
  );
};
export default ConnectGoogleDrive;
