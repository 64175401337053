import React, { useState } from "react";

import { Ai } from "@/interfaces/ai";
import { User } from "@/interfaces/user";
import AiLinkCard from "@/components/biz/partials/ais/AiLinkCard";

type Props = {
  subCategoryName: string;
  ais: Ai[];
  user?: User;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const SubCategoryGroup: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);

  return (
    <>
      <div className="">
        <p className="text-sm text-center font-bold pb-3">{props.subCategoryName}</p>

        {props.ais.length > 0 && (
          <>
            <div className="text-sm mb-12 sm:px-8 px-3">
              <div
                className="grid grid-cols-1 gap-2 sm:grid-cols-3"
                role="list"
              >
                {props.ais.map((ai) => {
                  return (
                    <AiLinkCard
                      key={`ai-${ai.slug}`}
                      ai={ai}
                      user={props.user}
                      favorites={favorites}
                      setFavorites={setFavorites}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default SubCategoryGroup;
