import React from "react";

const FooterBanner = () => {
  const images = [

    { src: "https://ordermaid.media/wp-content/uploads/2023/07/3.png", link: "https://s.lmes.jp/landing-qr/1661019655-yV8YWZVn?uLand=xp9Mmz" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/4.png", link: "/articles/biwkpmzczwqyuvav" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/5.png", link: "/articles" },
    // { src: "https://ordermaid.media/wp-content/uploads/2023/07/6.png", link: "https://discord.gg/er7EZNTvgh" },
  ];

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-3  md:grid-cols-2  gap-4 py-4">
        {images.map((image, index) => (
          <div key={index}>
            <a href={image.link} rel="noopener noreferrer">
              <img
                className="rounded-lg shadow"
                src={image.src}
                draggable="false"
              />
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default FooterBanner;
