import React from "react";
import { Ai } from "../interfaces/ai";

interface FavoritesContextData {
  favorites: Ai[];
  addFavorite: (ai: Ai) => void;
  removeFavorite: (ai: Ai) => void;
}

export const FavoritesContext = React.createContext<FavoritesContextData>({
  favorites: [],
  addFavorite: () => {},
  removeFavorite: () => {},
});
