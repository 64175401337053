import React, { useState, useEffect } from "react";
import PaginationButton from "./PaginationButton";
import PaginationPage from "./PaginationPage";
import { Meta } from "@/interfaces/meta";

type Props = {
  meta: Meta;
  selectPage: (page: number) => void;
  windowCount?: number;
  left?: number;
  right?: number;
};

const Pagination: React.FC<Props> = ({
  meta,
  selectPage,
  windowCount = 2,
  left = 2,
  right = 2
}) => {
  const [existNext, setExistNext] = useState(meta.nextPage !== null);
  const [existPrev, setExistPrev] = useState(meta.prevPage !== null);

  useEffect(() => {
    setExistNext(meta.nextPage !== null);
    setExistPrev(meta.prevPage !== null);
  }, [meta]);

  const generatePageNumbers = () => {
    const pageNumbers: (number | string)[] = [];
    const { currentPage, totalPages } = meta;
  
    for (let i = 1; i <= Math.min(left, totalPages); i++) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }
  
    if (currentPage > left + windowCount) {
      pageNumbers.push('...');
    }
  
    const start = Math.max(currentPage - windowCount, left + 1);
    const end = Math.min(currentPage + windowCount, totalPages - right);
    for (let i = start; i <= end; i++) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }
  
    if (currentPage + windowCount < totalPages - right) {
      pageNumbers.push('...');
    }
  
    for (let i = Math.max(totalPages - right + 1, 1); i <= totalPages; i++) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <nav className="pagination mt-10">
      <ul className="inline-flex -space-x-px">
        {existPrev && (
          <>
            <li className="first">
              <PaginationButton
                label="First"
                onClick={() => selectPage(1)}
              />
            </li>
            <li className="prev">
              <PaginationButton
                label="Previous"
                onClick={() => selectPage(meta.currentPage - 1)}
              />
            </li>
          </>
        )}
        {pageNumbers.map((page, index) => (
          <li key={`page-${index}`} className="page">
            <PaginationPage
              page={page}
              isCurrent={page === meta.currentPage}
              onClick={() => typeof page === "number" && selectPage(page)}
            />
          </li>
        ))}
        {existNext && (
          <>
            <li className="next">
              <PaginationButton
                label="Next"
                onClick={() => selectPage(meta.currentPage + 1)}
              />
            </li>
            <li className="last">
              <PaginationButton
                label="Last"
                onClick={() => selectPage(meta.totalPages)}
              />
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
