import React from "react";
import CircleBackground from "./CircleBackground";
import { Container } from "./Container";

const Discord = () => {
  return (
    <section
      id="get-free-shares-today"
      className="discord relative overflow-hidden py-20 sm:py-28"
    >
      <div className="absolute top-1/2 left-20 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground color="#fff" class="spin-slower" />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-md sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
            Discordコミュニティ
          </h2>
          <p className="mt-4 text-base text-gray-300">
            クラウドワークスAIに興味のある方々とつながりましょう。こちらのDiscordコミュニティでは、AIメイドの使い方や応用に関する情報交換や相談ができます。参加は無料ですので、ぜひお気軽にご参加ください。
          </p>
          <div className="mt-8 flex justify-center">
            <a href="#" className="text-white">
              ボタン
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Discord;
