import getClient from "../client";

export const adminUpdateImagePrompt = async (slug: string, prompt: string) => {
  return getClient().post(`/admins/image_ais/${slug}/update_prompt.json`, {
    prompt: prompt,
  });
};

export const adminCloneImageAi = async (slug: string) => {
  return getClient().post(`/admins/image_ais/${slug}/clone.json`);
};

export const adminCreateImageAi = async (params) => {
  return getClient().post(`/admins/image_ais.json`, params);
};

export const adminUpdateImageAi = async (slug: string, params) => {
  return getClient().put(`/admins/image_ais/${slug}.json`, params);
};
