import React from "react";
import AffiliateCode from "../../../users/affiliates/Code";
import { User } from "../../../../interfaces/user";
import { toast } from "react-toastify";

type Props = {
  user: User;
};

const UsersAffiliatesNew: React.FC<Props> = (props) => {
  const onCopy = () => {
    // 完全なアフィリエイトURLを生成
    const fullURL = `${window.location.protocol}//${window.location.host}/d/${props.user.slug}`;

    // URLをクリップボードにコピー
    navigator.clipboard
      .writeText(fullURL)
      .then(() => {
        toast.success("URLをコピーしました");
      })
      .catch((err) => {
        toast.error("エラー: URLをコピーできませんでした");
      });
  };
  return (
    <>
      <AffiliateCode slug={props.user.slug} onCopy={onCopy} />
    </>
  );
};
export default UsersAffiliatesNew;
