import axios from "axios";
import { toast } from "react-toastify";

const getClient = () => {
  const client = axios.create({
    baseURL: "/api/v1",
  });

  const onSuccess = (response) => response;

  const onError = (err) => {
    if (err.response && err.response.data.errors) {
      // サーバーからのエラーメッセージがある場合、それをトースト通知する
      const messages = err.response.data.errors;
      toast.error(messages.join(", ")); // エラーメッセージをカンマで結合して表示
    } else {
      // 一般的なエラーメッセージをトースト通知する
      toast.error("エラーが発生しました。お問い合わせください");
    }
    return Promise.reject(err);
  };

  client.interceptors.response.use(onSuccess, onError);

  client.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  };

  return client;
};

export const getRootClient = () => {
  const client = axios.create({
    baseURL: "/",
  });

  const onSuccess = (response) => response;

  const onError = (err) => {
    return Promise.reject(err);
  };

  client.interceptors.response.use(onSuccess, onError);

  client.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  };

  return client;
};

export default getClient;
