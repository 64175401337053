import getClient from "../client";
import {
  GetAisParams,
  PostAiChatGpt,
  PostGeneratedText,
  PaginateAisParams,
} from "../../../interfaces/requests/ai";
/**
 * サブカテゴリー一覧を取得
 */
export const usersGetAis = async (params: GetAisParams) => {
  return getClient().get(`/users/ais.json`, {
    params: params,
  });
};
export const usersPaginateAis = async (params: PaginateAisParams) => {
  const response = await getClient().get(`/users/ais/paginate.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};

/**
 * chatGPTとchat
 */
export const usersPostAiChatGpt = async (params: PostAiChatGpt) => {
  return getClient().post(`/users/ais/chat_gpt.json`, params);
};

/**
 * 生成コンテンツのメール送信
 */
export const usersPostNotifyGeneratedText = async (
  params: PostGeneratedText
) => {
  return getClient().post(`/users/ais/notify_generated_text.json`, params);
};

/**
 * 生成コンテンツの保存
 */
export const usersPostSaveGeneratedText = async (params: PostGeneratedText) => {
  return getClient().post(`/users/ais/save_generated_text.json`, params);
};

/*
  サジェスチョン
 */
export const suggestAi = async (query: string) => {
  const response = await getClient().get(`/users/ais/suggestion.json`, {
    params: {
      q: query,
    },
  });
  return response.data; // AxiosResponseのdata部分を返す
};
