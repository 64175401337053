import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import InputSwitch from "../../partials/forms/input/Switch";
import {
  toggleBulkEmailNotify,
  toggleStepMailNotify,
} from "../../../libs/api/user";
import { toast } from "react-toastify";
type Props = {
  user: User;
};

const UsersNotifySettings: React.FC<Props> = (props) => {
  const [isBulkEmailChecked, setIsBulkEmailChecked] = useState(
    props.user.bulkEmailNotify
  );

  const [isStepMailChecked, setIsStepMailChecked] = useState(
    props.user.stepMailNotify
  );

  // スイッチの状態をトグルする関数
  const toggleSwitchBulkEmail = async () => {
    let { data } = await toggleBulkEmailNotify();
    setIsBulkEmailChecked(data.bulk_email_notify);
    if (data.bulk_email_notify) {
      toast.success("お知らせ通知をONにしました。");
    } else {
      toast.success("お知らせ通知をOFFにしました。");
    }
  };

  // スイッチの状態をトグルする関数
  const toggleSwitchStepMail = async () => {
    let { data } = await toggleStepMailNotify();
    setIsStepMailChecked(data.step_mail_notify);
    if (data.step_mail_notify) {
      toast.success("クラウドワークスAI活用事例 をONにしました。");
    } else {
      toast.success("クラウドワークスAI活用事例 をOFFにしました。");
    }
  };

  return (
    <>
      <table className="border-collapse table-auto w-full text-sm mb-10 mt-10">
        <thead className="bg-white">
          <tr>
            <th className="border-b border-slate-100 p-4 text-slate-500 text-left">
              通知種別
            </th>
            <th className="border-b border-slate-100 p-4 text-slate-500 text-left">
              説明
            </th>
            <th className="border-b border-slate-100 p-4 text-slate-500 text-left">
              操作
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="border-b border-slate-100 p-4 text-slate-500">
              お知らせ
            </td>
            <td className="border-b border-slate-100 p-4 text-slate-500">
              新機能リリース・期間限定クーポンなどのお知らせを受け取ることができます。
            </td>
            <td className="border-b border-slate-100 p-4 text-slate-500">
              <InputSwitch
                isChecked={isBulkEmailChecked}
                setIsChecked={setIsBulkEmailChecked}
                toggleSwitch={toggleSwitchBulkEmail}
              />
            </td>
          </tr>
          <tr>
            <td className="border-b border-slate-100 p-4 text-slate-500">
              クラウドワークスAI活用事例
            </td>
            <td className="border-b border-slate-100 p-4 text-slate-500">
              私たちのAIを活用し、素晴らしい結果を出されているユーザー様の実例を皆さまにお届けします
            </td>
            <td className="border-b border-slate-100 p-4 text-slate-500">
              <InputSwitch
                isChecked={isStepMailChecked}
                setIsChecked={setIsStepMailChecked}
                toggleSwitch={toggleSwitchStepMail}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default UsersNotifySettings;
