import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BankAccount } from "../../../interfaces/bank_account";
type Props = {
  onSubmit: (data: any) => void;
  bankAccount: BankAccount;
};
const BankForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bankName: props.bankAccount?.bankName || "",
      branchName: props.bankAccount?.branchName || "",
      accountType: props.bankAccount?.accountType || "",
      accountNumber: props.bankAccount?.accountNumber || "",
      accountHolderKana: props.bankAccount?.accountHolderKana || "",
    },
  });

  const handleFormSubmit = (data: any) => {
    props.onSubmit(data);
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="grid grid-cols-1 gap-x-6 gap-y-4"
      >
        <div className="col-span-full">
          <label
            htmlFor="bankName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            銀行名
          </label>
          <div className="mt-2">
            <input
              {...register("bankName", { required: true })}
              type="text"
              name="bankName"
              id="bankName"
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {errors.bankName && (
            <p className="text-red-500 bg-red-200 mt-2 text-sm">
              銀行名は必須です。
            </p>
          )}
        </div>
        <div className="col-span-full">
          <label
            htmlFor="bankName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            支店名
          </label>
          <div className="mt-2">
            <input
              {...register("branchName", { required: true })}
              type="text"
              name="branchName"
              id="branchName"
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {errors.branchName && (
            <p className="text-red-500 bg-red-200 mt-2 text-sm">
              銀行名は必須です。
            </p>
          )}
        </div>
        <div className="col-span-full">
          <fieldset>
            <legend className="text-sm leading-6 text-gray-900">
              口座種別
            </legend>
            <div className="mt-2 space-y-1">
              <div className="flex items-center gap-x-3">
                <input
                  {...register("accountType", { required: true })}
                  id="savings"
                  name="accountType"
                  type="radio"
                  value={`savings`}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="savings"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  普通
                </label>
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  {...register("accountType", { required: true })}
                  id="current"
                  name="accountType"
                  type="radio"
                  value={`current`}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="current"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  当座
                </label>
              </div>
            </div>
            {errors.accountType && (
              <p className="text-red-500 bg-red-200 mt-2 text-sm">
                口座種別は必須です。
              </p>
            )}
          </fieldset>
        </div>
        <div className="col-span-full">
          <label
            htmlFor="accountNumber"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            口座番号
          </label>
          <div className="mt-2">
            <input
              {...register("accountNumber", { required: true })}
              type="text"
              name="accountNumber"
              id="accountNumber"
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {errors.accountNumber && (
            <p className="text-red-500 bg-red-200 mt-2 text-sm">
              口座番号は必須です。
            </p>
          )}
        </div>
        <div className="col-span-full">
          <label
            htmlFor="accountNumber"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            口座名義人名（カナ）
          </label>
          <div className="mt-2">
            <input
              {...register("accountHolderKana", { required: true })}
              type="text"
              name="accountHolderKana"
              id="accountHolderKana"
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {errors.accountHolderKana && (
            <p className="text-red-500 bg-red-200 mt-2 text-sm">
              口座名義人名（カナ）は必須です。
            </p>
          )}
        </div>
        <div className="mt-6 col-span-full">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            振込先情報を更新
          </button>
        </div>
      </form>
    </>
  );
};

export default BankForm;
