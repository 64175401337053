export const categories = [
  {
    imgSrc: "/img/biz/home/marketing.png",
    title: "マーケティング",
    description: "メールマガジンの作成、記事作成などマーケティング業務に関する効率化をサポートします",
    link: "/ai_menu/text/category/businessmarketing"
  },
  {
    imgSrc: "/img/biz/home/sales.png",
    title: "営業",
    description: "返信メール作成、商談内容の要約など営業業務に関する効率化をサポートします",
    link: "/ai_menu/text/category/sales"
  },
  {
    imgSrc: "/img/biz/home/business.png",
    title: "ビジネス全般",
    description: "アイデア出し、外国語の翻訳など汎用的な業務に関する効率化をサポートします",
    link: "/ai_menu/text/category/business"
  }
];
