import getClient from "../client";

/**
 * 画像生成のDEMO
 */
export const createDallEDemo = async (params: any) => {
  return getClient().post(`/users/dall_es/demo.json`, params);
};

/**
 * 画像生成
 */
export const createDallE = async (params: any) => {
  return getClient().post(`/users/dall_es.json`, params);
};
