import React from "react";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "どんな支払い方法に対応しているのか知りたい",
    answer:
      "支払い方法は、クレジットカードとデビットカードに対応しています。\nカードのブランドや利用状況によっては、支払いに使用できない可能性があります。\n利用可能額などの状況はご利用のカード発行会社へ直接お問い合わせください。",
  },
  {
    question: "キャンセルしたい・返金してほしい",
    answer: "サービスの特性上、ご契約後のキャンセル・返金は承っておりません。",
  },
  {
    question: "契約している有料プランを変更したい",
    answer:
      "有料プランはいつでも変更できす。\n\n      ・ 以下の場合、プラン変更時は差額のみ請求となります。\n          ・ 現在より契約期間が長くなる場合\n          ・ 現在より生成枚数が多くなる場合\n              ・ 例）画像生成プレミアム\n              4月1日：月100枚生成プランに加入\n              　請求金額：3,320円\n              4月15日：月300枚生成プランに変更\n              　請求金額：5,810円 = 7,470円 - 1,660円（3,320円の15日分日割）\n              　　7,470円 = 月300枚生成プラン料金\n              　　1,660円 = 月100枚生成プランの日割（15日分）\n              4月の請求金額合計：9,130円 = 3,320円 - 5,810円\n      \n      ・ 以下の場合、差額の返金等はされません\n          ・ 現在より契約期間が短くなる場合\n          ・ 現在より生成枚数が少なくなる場合\n              ・ どちらの場合も、現在の契約期間終了後に変更後の料金が請求されます",
  },
  {
    question: "購入した有料プランがなくなった・なぜかFreeプランになっている",
    answer:
      "有料プランを購入したアカウントとは、異なるアカウントにログインしている可能性があります。\nGmailを利用している場合、ログイン方法によってひとつのメールアドレスにふたつのアカウントが存在している可能性があります。\n\n現在、「Googleでログイン」からログインすると、同じメールアドレスにも関わらず、新規登録した際に設定したメールアドレスとパスワードの組み合わせでログインできるアカウントとは別の新しいアカウントが作成されてしまいます。\n一度、現在のアカウントからログアウトし、別の方法で再度ログインをお試しください。\n\n上記を試しても有料プランが見つからない場合、またはGmail以外のメールアドレスを利用している場合、別のメールアドレスで作成したアカウントで有料プランを購入している可能性があります。\n現在ログインしているメールアドレス以外の、心当たりがあるメールアドレスでログインをお試しください。\n\n現在契約中のプランは、契約プラン情報から確認できます。",
  },
  {
    question: "クーリングオフしたい・対象なのか知りたい",
    answer:
      "オーダーメイドAIは特定商取引法（特商法）に基づくクーリング・オフ制度の対象外です。\nサービスの特性上、ご契約後のキャンセル・返金はできません。",
  },
  {
    question: "生成した画像やテキストが商用利用できるか知りたい",
    answer:
      "当社は、オーダーメイドAIが生成したテキスト・画像の利用目的を制限していません。\n利用される場合は、ご自身の責任の上でご使用ください。\n利用規約の「免責」に定めるとおり、当社は本サービスに関して生じた損害について、一切の責任を負いません。",
  },
];
const UsersPlansFaqs: React.FC = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl mt-10 mb-40">
        <div className="mx-auto divide-y divide-gray-900/10">
          <h2 className="text-xl font-bold leading-10 tracking-tight text-gray-900">
            よくある質問と答え
          </h2>
          <dl className="mt-4 space-y-2 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-2">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600 whitespace-pre-wrap">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
        <div className="text-right mt-10">
          <a
            href="https://crowdworks-ai.notion.site/a370de76749b4e4b815b43479cc45591?pvs=4"
            target="_blank"
            className="text-sm text-gray-900"
          >
            もっと見る
          </a>
        </div>
      </div>
    </div>
  );
};
export default UsersPlansFaqs;
