import React from "react";
import { AiForm } from "../../../../interfaces/ai_form";
import {UseFormRegister} from "react-hook-form";

type Props = {
  aiForm: AiForm;
  register: UseFormRegister<any>;
  setUploadedFile: (file: UploadedFile) => void;
  errors: {
    [x: string]: any;
  };
};

export type UploadedFile = {
  inputName: string;
  file: File;
}

const InputFile: React.FC<Props> = (props) => {
  if (props.aiForm.inputName == "") {
    return null;
  }
  return (
    <div className={`col-span-${props.aiForm.col}`}>
      <label
        htmlFor="street-address"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.aiForm.label}
      </label>
      <input
        type="file"
        id={props.aiForm.inputName}
        name={props.aiForm.inputName}
        className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1"
        {...props.register(props.aiForm.inputName, {
          required: true,
          onChange: (e) => {
            if (e.target.files) {
              props.setUploadedFile({ inputName: props.aiForm.inputName, file: e.target.files[0] });
            }
          }
        })}
      />
      <p className="mt-1 text-xs text-gray-500">{props.aiForm.helpText}</p>
      {
        props.errors[`${props.aiForm.inputName}`]?.type === "required" && (
          <p className="font-semibold text-sm text-red-400">
            ファイルを指定してください
          </p>
        )
      }
    </div>
  );
};

export default InputFile;
