import getClient from "../client";

export const getCivitaiModels = async (params?) => {
  return getClient().get(`/admins/novita_ais/models.json`, {
    params: params,
  });
};

export const getCivitaiLoras = async (params?) => {
  return getClient().get(`/admins/novita_ais/loras.json`, {
    params: params,
  });
};
