import React, { useState } from "react";

import { Ai } from "../../../../../interfaces/ai";
import { User } from "../../../../../interfaces/user";
import SubCategoryGroup from "../../../ai_menu/text/sub_category/SubCategoryGroup";
import AiCardHorizotal from "../../../partials/ais/AiCardHorizotal";

type Props = {
  title: string;
  aiBySubcategories: {
    subCategoryName: string;
    ais: Ai[];
  }[];
  recommendedAis: Ai[];
  user: User;
  favorites: Ai[];
};

const AiMenuTextJobRole: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);

  return (
    <>
      <p className="text-sm text-left font-bold border-b py-2 pl-3 mb-10 bg-gray-100">
        {props.title}
      </p>
      {props.aiBySubcategories.map((aiGaiBySubcategoryroup, index) => (
        <SubCategoryGroup
          key={`ai-group-${index}`}
          subCategoryName={aiGaiBySubcategoryroup.subCategoryName}
          ais={aiGaiBySubcategoryroup.ais}
          user={props.user}
          favorites={favorites}
          setFavorites={setFavorites}
        />
      ))}
      <p className="mt-10 text-sm text-center font-bold">
        おすすめのAIメニュー
      </p>
      <div className="mt-3 p-3 bg-gray-100 sm:px-8 px-3">
        <div className="grid gap-2 md:grid-cols-5 grid-cols-2">
          {props.recommendedAis.map((ai) => {
            return (
              <AiCardHorizotal
                key={`ai-${ai.slug}`}
                ai={ai}
                user={props.user}
                favorites={favorites}
                setFavorites={setFavorites}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default AiMenuTextJobRole;
