import React from "react";
import CountdownTimer from "./trials/CountdownTimer";
import { User } from "../../../interfaces/user";
type Props = {
  user: User;
  link?: string;
  endAt: string;
};
const CountdownTrial: React.FC<Props> = (props) => {
  const linearGradientStyle = {
    background: "linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)",
  };

  return (
    <div
      className="mb-5 relative isolate flex flex-wrap justify-center items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 rounded-md"
      style={linearGradientStyle}
    >
      <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-white">
          <>
            <strong className="font-semibold">
              <span className="mx-2 font-bold underline text-yellow-300 decoration-3 text-lg">
                使い放題プラン14日無料キャンペーン！！
              </span>
              終了まで
              <span className="mx-2 font-bold decoration-3 text-lg">
                <CountdownTimer EndAt={props.endAt} />
              </span>
            </strong>
          </>
        </p>
      </div>
      {props.link && (
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2 mt-2 md:mt-0">
          <a
            href={props.link}
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            詳しくはこちら <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      )}
    </div>
  );
};
export default CountdownTrial;
