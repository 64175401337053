import React, { useState } from "react";
import { ImageAiForm } from "../../../../../interfaces/image_ai_form";
import InputText from "../forms/InputText";
import TextArea from "../forms/TextArea";
import InputSelect from "../forms/InputSelect";
import { useForm } from "react-hook-form";
import { User } from "../../../../../interfaces/user";
import { UserGenImg } from "../../../../../interfaces/user_gen_img";
import InputSwitch from "../../../partials/forms/input/Switch";

type Props = {
  submit: (data: any) => void;
  imageAiForms: ImageAiForm[];
  streaming: boolean;
  cancelStreaming: () => void;
  user?: User;
  remainGenerateCap: number;
  setSwitchModalOpen?: (v: boolean) => void;
  userGenImg?: UserGenImg;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const num_options: number[] = [];

  const imageAlt = () => {
    return (
      props.userGenImg &&
      props.userGenImg.imageAi.name +
        " - " +
        props.userGenImg.formLabelAndValues
          .map((item) => `${item.label}: ${item.value}`)
          .join(" / ") +
        " - AI画像 クラウドワークスAI（旧：オーダーメイドAI）"
    );
  };

  const [publish, setPublish] = useState(true);

  const imageSetting = () => {
    return (
      props.userGenImg &&
      props.userGenImg.formLabelAndValues
        .map((item) => `${item.label}: ${item.value}`)
        .join(" / ")
    );
  };

  const maxGenerateNum = () => {
    if (props.user && !props.user.isPaidDallE) {
      return 5;
    }
    if (props.remainGenerateCap >= 10) {
      return 10;
    } else if (props.remainGenerateCap <= 0) {
      return 5;
    } else {
      return props.remainGenerateCap;
    }
  };
  for (let i = 1; i <= maxGenerateNum(); i++) {
    num_options.push(i);
  }

  const size_options = [
    {
      name: "正方形(1:1)",
      val: "1024x1024",
    },
    {
      name: "縦長(9:16)",
      val: "1024x1792",
    },
    {
      name: "横長(16:9)",
      val: "1792x1024",
    },
  ];

  // 特定のキーに対応する値を検索する関数
  const findValueByKey = (key: string): string | undefined => {
    if (props.userGenImg) {
      console.log(
        "props.userGenImg.formNameAndValues",
        props.userGenImg.formNameAndValues
      );
      console.log("key", key);
      const item = props.userGenImg.formNameAndValues.find(
        (item) => item.name === key
      );
      return item?.value;
    }
  };

  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const inputTag = (aiForm, idx) => {
    console.log("aiForm", aiForm);
    let defaultValue = findValueByKey(aiForm.inputName);
    if (aiForm.kind == "input_text") {
      console.log("defaultValue", defaultValue);
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          defaultValue={defaultValue}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          defaultValue={defaultValue}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          defaultValue={defaultValue}
        />
      );
    } else if (aiForm.kind == "input_radio") {
    }
  };

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();
    if (result) {
      const data = getValues();
      if (publish) {
        data.publish_flag = "true";
      }
      props.submit(data);
    }
  };

  const toggleSwitch = () => {
    if (!props.user?.isPaidDallE && props.setSwitchModalOpen) {
      props.setSwitchModalOpen(true);
    } else {
      setPublish(!publish);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button className="w-full rounded-full bg-gradient-to-r from-red-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90">
          生成中です。30秒ほどお待ちください...
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="w-full rounded-full bg-gradient-to-r from-blue-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          生成する
        </button>
      );
    }
  };

  return (
    <>
      {props.userGenImg && (
        <div className="grid grid-cols-12 gap-3 sm:pb-0 border-b">
          <div className={`sm:col-span-3 lg:col-span-2 col-span-6`}>
            <img
              src={props.userGenImg?.creditImage.url}
              className={`w-full`}
              alt={imageAlt()}
            />
          </div>
          <div className={`sm:col-span-9 lg:col-span-10 col-span-6 py-2`}>
            <p className="text-gray-700 font-bold mr-3 lg:text-2xl text-lg">
              この画像はこちらのAIで生成されました
            </p>
            <p className="mt-1 text-xs text-gray-500">
              <strong>入力条件: </strong>
              {imageSetting()}
            </p>
          </div>
        </div>
      )}
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div className="sm:shadow-lg sm:rounded-lg">
          <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-6 gap-3">
              {props.imageAiForms.map((aiForm, idx) => {
                return inputTag(aiForm, idx);
              })}
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  サイズ
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`size`, {
                      required: true,
                    })}
                  >
                    {size_options.map((option, i) => {
                      return (
                        <option key={`kind-option-${i}`} value={option.val}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors[`size`]?.type === "required" && (
                  <p className="font-semibold text-sm text-red-400">
                    選択してください
                  </p>
                )}
              </div>
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  生成数
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`num`, {
                      required: true,
                    })}
                  >
                    {num_options.map((option) => {
                      return (
                        <option key={`kind-option-${option}`} value={option}>
                          {option}枚
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className={`col-span-2`}>
              <label className="block text-sm font-medium leading-6 text-gray-900 mb-1">
                生成した画像を公開
              </label>
              <InputSwitch
                isChecked={publish}
                setIsChecked={setPublish}
                toggleSwitch={toggleSwitch}
              />
            </div>
          </div>

          <div className="bg-[#f7f9f9] px-4 py-3 text-right sm:px-6">
            {props.user && !props.user.isPaid && (
              <div className="mb-3">
                <AdsenceCard
                  clientId={`ca-pub-2307777494961440`}
                  slotId={`6888306272`}
                />
              </div>
            )}
            {submitBtn()}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
