import React, { useState, useEffect, Fragment } from "react";
import MovieVimeo from "../movies/Vimeo";
import ProgressBar from "../movies/ProgressBar";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DojoRewardHeader from "../banners/DojoRewardHeader";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  end: boolean;
  setEnd: (b: boolean) => void;
  onEnd: () => void;
  canClose?: boolean;
  ctaText?: string;
  title?: string;
  link?: string;
};
const UserDetailModal: React.FC<Props> = (props) => {
  const [percent, setPercent] = useState(0);
  const [duration, setDuration] = useState(30);
  const [seconds, setSeconds] = useState(0);

  const restTime = Math.floor(duration - seconds);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (props.open) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (restTime <= 0) {
            clearInterval(interval);
            props.setEnd(true);
            return prevSeconds;
          }
          const newSeconds = prevSeconds + 1;
          setPercent(newSeconds / duration);
          return newSeconds;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [props.open, restTime, duration]);

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            props.canClose && props.setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="p-3 mt-3">
                    <DojoRewardHeader
                      title={`無料でAIの収益化を学ぶ`}
                      link={`https://cwdojo.app/redirect/4acde76e-01b1-45d5-af96-6ed300f167e5`}
                    />
                  </div>
                  {props.end ? (
                    <div className="absolute right-0 top-0 pr-4 pt-4">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={() => props.onEnd()}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  ) : (
                    restTime != 0 && (
                      <div className="absolute right-0 top-0 hidden pr-2 sm:block">
                        <span className="text-xs text-gray-600">
                          残り{restTime}秒
                        </span>
                      </div>
                    )
                  )}

                  {props.end ? (
                    <div className="p-8 text-center">
                      <h3 className="text-3xl font-bold mb-2 mt-3">
                        {props.title}
                      </h3>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-6"
                        onClick={() => {
                          window.open(props.link, "_blank");
                        }}
                      >
                        {props.ctaText}
                      </button>
                    </div>
                  ) : (
                    <div className="p-3 pb-0 flex justify-center">
                      {/** ここにバナー表示 */}
                      <img src="/img/CWAI_reward_dojo_banner.png" />
                    </div>
                  )}

                  {duration != 0 && !props.end && (
                    <>
                      <div className="p-3">
                        <ProgressBar percent={percent} duration={duration} />
                      </div>

                      <div className="border-t border-gray-100 p-3 flex justify-center">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            window.open(props.link, "_blank");
                          }}
                        >
                          {props.ctaText}
                        </button>
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default UserDetailModal;
