import React, { useContext } from "react";
type Props = {
  percent: number;
  duration: number;
};
const MovieProgressBar: React.FC<Props> = (props) => {
  const minutes = Math.floor(props.duration / 60);
  const seconds = Math.floor(props.duration % 60);

  return (
    <div className="relative w-full">
      <div className="absolute right-0 top-0 -mt-4 text-xs text-gray-600">
        {minutes}:{seconds}
      </div>
      <div className="w-full h-2 bg-gray-200">
        <div
          style={{ width: `${props.percent * 100}%` }}
          className="h-full bg-green-500"
        ></div>
      </div>
    </div>
  );
};
export default MovieProgressBar;
