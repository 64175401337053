import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { User } from "../../../interfaces/user";
import { AdMovie } from "../../../interfaces/ad_movie";
import PremiumReward from "../../partials/banners/PremiumReward";
import VimeoModal from "./Vimeo";
import DojoModal from "@/components/partials/modals/Dojo";

type Props = {
  user?: User;
  open: boolean;
  adCount: number;
  setOpen: (b: boolean) => void;
  onMovieComplete?: () => void;
  scrollAfterReward?: number;
  adMovies: AdMovie[];
};

const RewardModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [adStart, setAdStart] = useState(false);
  const [openVimeo, setOpenVimeo] = useState(false);
  const [openDojo, setOpenDojo] = useState(false);
  const [end, setEnd] = useState(false);
  const [currentAdCount, setCurrentAdCount] = useState(0);
  const [currentAdMovie, setCurrentAdMovie] = useState<AdMovie>(
    props.adMovies[0] || {
      id: "",
      vimeoId: "",
      kind: "",
      ctaText: "",
      title: "",
      link: "",
    }
  );

  const handleClick = async () => {
    setCurrentAdCount((old) => old + 1);
  };

  useEffect(() => {
    if (currentAdCount != 0) {
      if (currentAdCount <= props.adCount) {
        props.adMovies[currentAdCount - 1] &&
          setCurrentAdMovie(props.adMovies[currentAdCount - 1]);
        setLoading(true);
        if (currentAdCount == 2) {
          setTimeout(() => {
            setOpenDojo(true);
          }, 1000);
        } else {
          setTimeout(() => {
            setOpenVimeo(true);
            setEnd(false);
          }, 1000);
        }
      } else {
        props.setOpen(false);
        setAdStart(false);
        setLoading(false);
        setCurrentAdCount(0);
        if (props.onMovieComplete) {
          props.onMovieComplete();
        }
      }
    }
  }, [currentAdCount]);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const restAds = () => {
    return props.adCount - currentAdCount;
  };

  const NotLoadingBody = () => (
    <>
      <div className="bg-white px-4 pb-4 sm:pb-4">
        <div className="sm:flex sm:items-center">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
            <i className="fa-solid fa-film"></i>
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              動画再生後に生成が開始されます。
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                動画を閲覧後に生成が開始されます。
                <br />
                以下の視聴開始ボタンをクリックして開始してください。
                <br />
                <br />
                ※生成開始されない場合は広告ブロックを無効にしてください。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 flex justify-end sm:px-6">
        <button
          type="button"
          className="inline-flex justify-center mr-2 rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm sm:w-auto"
          onClick={() => props.setOpen(false)}
        >
          キャンセル
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-90 sm:w-auto"
          onClick={handleClick}
        >
          視聴を開始
        </button>
      </div>
    </>
  );

  const LoadingBody = () => (
    <div className="bg-white px-4 pb-4 sm:pb-4">
      <div className="sm:flex sm:items-center sm:justify-center">
        <div className="flex justify-center" aria-label="読み込み中">
          <div className="animate-spin h-10 w-10 border-4 border-blue-500 rounded-full border-t-transparent"></div>
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            <div>あと{restAds()}回の広告視聴で生成開始</div>
          </Dialog.Title>
          <div className="text-sm py-4">
            ※生成開始されない場合は広告ブロックを無効にしてください。
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            return false;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="p-3">
                    <PremiumReward link={`/users/plans`} />
                  </div>
                  {loading && <LoadingBody />}
                  {!loading && !adStart && <NotLoadingBody />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <VimeoModal
        vimeoId={currentAdMovie.vimeoId}
        open={openVimeo}
        setOpen={setOpenVimeo}
        canClose={false}
        onVimeoEnd={() => {
          setOpenVimeo(false);
          setCurrentAdCount((old) => old + 1);
        }}
        kind={currentAdMovie.kind}
        ctaText={currentAdMovie.ctaText}
        title={currentAdMovie.title}
        link={currentAdMovie.link}
        end={end}
        setEnd={setEnd}
      />
      <DojoModal
        open={openDojo}
        setOpen={setOpenDojo}
        canClose={false}
        onEnd={() => {
          setOpenDojo(false);
          setCurrentAdCount((old) => old + 1);
        }}
        ctaText={`ノウハウ動画と6大特典を受け取る`}
        link={`https://cwdojo.app/redirect/4acde76e-01b1-45d5-af96-6ed300f167e5`}
        end={end}
        setEnd={setEnd}
      />
    </>
  );
};
export default RewardModal;
