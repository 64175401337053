import getClient from "../client";

/**
 * サブスクリプション一覧を取得
 */
export const usersStripes = async () => {
  return getClient().get(`/users/stripes.json`);
};

/**
 * サブスクリプション変更処理
 */
export const usersPostChangePlan = async (planId: string) => {
  return getClient().post(`/users/stripes/change_plan.json`, {
    planId: planId,
  });
};

/**
 * サブスクリプション変更処理
 */
export const usersPostChangePlanDallE = async (planId: string) => {
  return getClient().post(`/users/stripes/change_plan_dall_e.json`, {
    planDallEId: planId,
  });
};

/**
 * サブスクリプション変更処理
 * 音声書き起こし以降のすべてのプラン対応
 */
export const usersPostChangeOmniPlan = async (
  planId: string,
  serviceType: string
) => {
  return getClient().post(`/users/stripes/change_omni_plan.json`, {
    planId: planId,
    serviceType: serviceType,
  });
};

/**
 * サブスクリプション変更処理
 */
export const usersPostChangePlanWithIntent = async (
  planId: string,
  paymentIntentId: string
) => {
  return getClient().post(`/users/stripes/change_plan.json`, {
    planId: planId,
    paymentIntentId: paymentIntentId,
  });
};

/**
 * サブスクリプション解除処理
 */
export const usersPostChangeFree = async () => {
  return getClient().post(`/users/stripes/change_to_free.json`);
};

/**
 * サブスクリプション解除処理DallE
 */
export const usersPostChangeFreeDallE = async () => {
  return getClient().post(`/users/stripes/change_to_free_dall_e.json`);
};

/**
 * サブスクリプション解除処理
 */
export const usersPostChangeOmniFree = async (serviceType: string) => {
  return getClient().post(`/users/stripes/omni_change_to_free.json`, {
    serviceType: serviceType,
  });
};

/**
 * 3dセキュア対応用
 */
export const userPostPaymentIntentClientSecret = async () => {
  return getClient().post(`/users/stripes/payment_intent_client_secret.json`);
};
