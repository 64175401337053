import React from "react";
import { AxiosResponse } from "axios";
import Loading from "../../Loading";
import getClient from "../../libs/api/client";

type Props = {
  id: number,
  role: string
};

type ApiResponseData = {
  role: string
};

const Role: React.FC<Props> = (props) => {
  const [role, setRole] = React.useState(props.role);
  const [nowLoading, setNowLoading] = React.useState(false);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNowLoading(true);

    const client = getClient();
    client
      .put(`biz/users/users/${props.id}/role`, {
        role: e.target.value,
      })
      .then((response: AxiosResponse<ApiResponseData>) => {
        setRole(response.data.role);
        setNowLoading(false);
      })
      .catch((error) => {
        setNowLoading(false);
      });
  }

  return(
    <>
      <Loading flag={nowLoading} />
      <select value={role} onChange={(event) => onChange(event)}>
        <option value="member">メンバー</option>
        <option value="admin">管理者</option>
      </select>
    </>
  )
}

export default Role;