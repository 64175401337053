import React, {useEffect} from "react";
import {Provider, useDispatch, useSelector} from 'react-redux';
import RecentAiMenu from "./RecentAiMenu";
import Ranking from "./Ranking";
import RecommendedAiMenu from "./RecommendedAiMenu";
import {Ai} from "@/interfaces/ai";
import {MainCategory} from "@/interfaces/main_category";
import {User} from "@/interfaces/user";
import Categories from "@/components/biz/pages/home/Categories";
import store, {RootState} from "@/components/biz/store/store";
import {fetchFavorites} from "@/components/biz/store/favoritesSlice";

type Props = {
  generalAis: Ai[];
  salesAis: Ai[];
  marketingAis: Ai[];
  mainCategories: MainCategory[];
  relatedGeneratedAis: Ai[];
  recommendedAis: Ai[];
  user: User;
};

const UsersIndex: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const status = useSelector((state: RootState) => state.favorites.status);
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchFavorites());
    }
  }, [status, dispatch]);
  return (
      <div className="main-content container mx-auto bg-gray-100">
        <div className="pt-10">
          <p className="text-xl text-center font-bold ">
            こんにちは、{props.user.name}さん！
          </p>
          <div className="pt-10">
            <Categories/>
          </div>
          <RecentAiMenu relatedGeneratedAis={props.relatedGeneratedAis}/>
          <Ranking generalAis={props.generalAis}
                   salesAis={props.salesAis}
                   marketingAis={props.marketingAis}/>
          <RecommendedAiMenu ais={props.recommendedAis}/>
        </div>
      </div>
  )
};
const WrappedUsersIndex: React.FC<Props> = (props) => (
  <Provider store={store}>
    <UsersIndex {...props} />
  </Provider>
);

export default WrappedUsersIndex;
