import getClient from "../client";
import { PostInputFieldParams } from "../../../interfaces/requests/input_field";

/**
 * サブカテゴリー一覧を取得
 */
export const adminBulkPostInputFields = async (
  params: PostInputFieldParams
) => {
  return getClient().post(`/admins/input_fields/bulk_create.json`, params);
};
