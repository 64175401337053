import React, { useState } from "react";

type Maid = {
  imageUrl: string | undefined;
  url: string | undefined;
  title: string;
};

const categorys = [
  {
    title: "プロンプトメーカー",
    description: "AI画像生成やChatGPTの難しいプロンプトを自動で生成します。",
    imageUrl: "/img/cateicon/cateicon_3.png",
    maid: "promptMaids",
  },
  {
    title: "広告のキャッチコピーを作る",
    description: "商品の特徴を入力するとキャッチコピーを自動で生成します。",
    imageUrl: "/img/cateicon/cateicon_7.png",
    maid: "adMaids",
  },
  {
    title: "ブログを書く",
    description: "5000文字の長文ブログも一撃で生成可能です。",
    imageUrl: "/img/cateicon/cateicon_1.png",
    maid: "blogMaids",
  },
  {
    title: "副業占いサポート",
    description: "ユーザーの情報や悩みを入力すると占いの鑑定文を生成できます。",
    imageUrl: "/img/cateicon/cateicon_2.png",
    maid: "uranaiMaids",
  },
  {
    title: "小説執筆サポート",
    description: "小説のプロットから内容まで自動生成できます。",
    imageUrl: "/img/cateicon/cateicon.png",
    maid: "novelMaids",
  },
  {
    title: "マンガ制作サポート",
    description:
      "アイディア出しやセリフ作成などあなたのマンガ制作をサポートします。",
    imageUrl: "/img/cateicon/cateicon_4.png",
    maid: "mangaMaids",
  },
  {
    title: "YouTube制作サポート",
    description:
      "タイトルやサムネ文言などYouTube制作に関するサポートを行います。",
    imageUrl: "/img/cateicon/cateicon_8.png",
    maid: "youtubeMaids",
  },
];

const promptMaids = [
  {
    title: "ChatGPTのプロンプトを作成",
    url: "/users/ais/adxvrragilzecpsv",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "StableDiffusionの呪文（リアルな人物用）",
    url: "/users/ais/ttjmcwzwjhhjavwd",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "【AI画像生成】Canvaのプロンプト",
    url: "/users/ais/pjkhpqcodwscugez",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "ミッドジャーニーの呪文（なんでも）",
    url: "/users/ais/coqhyzvtqzccawyu",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "にじジャーニーの呪文（人物イラスト用）",
    url: "/users/ais/nxdssqpntvxqewun",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "にじジャーニーの呪文（なんでも）",
    url: "/users/ais/ycldulyuxjqohpma",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "StableDiffusionの呪文（なんでも）",
    url: "/users/ais/wuvjrhczodwolzoi",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
];

const blogMaids = [
  {
    title: "ブログ記事執筆（1000~2000文字）HTML形式",
    url: "/users/ais/fqxuhagqijjpvqaw",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "ブログ記事執筆（4000~5000文字）Markdown形式",
    url: "/users/ais/rcuukhribzrgukbx",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "なりきりブログ（約1,000文字）",
    url: "/users/ais/edbqkudummpgmmul",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "なりきりブログ（約1,000文字）【絵文字ありver】",
    url: "/users/ais/yzofxzophgxfswaq",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "記事の見出し",
    url: "/users/ais/vjehrlnxkazghcdq",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "記事の導入文",
    url: "/users/ais/glpnsrzvbxhmjuyx",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "記事の本文（見出しごと）",
    url: "/users/ais/kumyidwpjzfwgmmh",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "記事のまとめ",
    url: "/users/ais/ylfckftzbovzqwsl",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "ブログ記事アイディア",
    url: "/users/ais/rnmomtyoztrbfujl",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "SEOに効果的なキーワード",
    url: "/users/ais/bqccabikpvkndmls",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "SEOに効果的な見出し作成",
    url: "/users/ais/zrqttetpsvfyxtmg",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "検索ボリューム",
    url: "/users/ais/kensaku-volume",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "文章をリライト",
    url: "/users/ais/dpwpwvkqoeocbaih",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "短文を長文に",
    url: "/users/ais/toubun",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "要点を膨らませる",
    url: "/users/ais/youten",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "記事タイトル",
    url: "/users/ais/mauewytxdzkirrmz",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "メタディスクリプションの作成",
    url: "/users/ais/crlrmziwrbnrexfk",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "箇条書きでサービスを説明",
    url: "/users/ais/hxjxdsftakmqyoes",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "キーワードから文章生成",
    url: "/users/ais/sexkachqlrdfcwif",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "インタビュー記事の作成",
    url: "/users/ais/adxvrragilzecpsv",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
  {
    title: "対談記事の作成",
    url: "/users/ais/ugwnymywpikczsey",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
  },
];

const novelMaids = [
  {
    title: "ラノベ風タイトル作成",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/hoge",
  },
  {
    title: "小説の書き出し",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/osmnhuvubywyvvhq",
  },
  {
    title: "本の帯に載せるコピー",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/wcivltwinyyqilpg",
  },
  {
    title: "小説のあらすじ",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/ezzzcbvadulaontq",
  },
  {
    title: "小説の本文の続きを書く",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/gxhorewuilvfciyz",
  },
  {
    title: "小説のプロットを作成する",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/bcbforyubtbrsjpn",
  },
  {
    title: "小説のタイトル作成",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/rakgxwdknyxsanvc",
  },
  {
    title: "小説のキャッチコピー",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/zdovjnzcbaijjtpb",
  },
  {
    title: "小説に登場する固有名詞",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/cqcsuhmgxfntbakz",
  },
  {
    title: "登場人物のプロフィールを作成",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/rpkzzexkogcuakxc",
  },
  {
    title: "小説メイド",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/adxvrragilzecpsv",
  },
  {
    title: "小説のアイディア",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/hvottwnrrhqgtdaz",
  },
  {
    title: "主人公の名前とプロフィール",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/bckdrvnnetzyzfyw",
  },
  {
    title: "小説に必要な登場人物の役割",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/xvozvwkbhoanhtco",
  },
  {
    title: "登場人物の役割から名前とプロフィール生成",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/ulngiyocimhkmfei",
  },
  {
    title: "大人小説のプロット作成",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/xlthfvkwihfahggw",
  },
  {
    title: "小説の本文の続きを書く（大人）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/piywkhveojibmqyh",
  },
];

const uranaiMaids = [
  {
    title: "【血液型別】今日の運勢（HTML出力）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/hyxjsgnxxhpykjqz",
  },
  {
    title: "【血液型別】今日の運勢",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/tpikvycpmruzlmdg",
  },
  {
    title: "今日のラッキーアイテム",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/plonyirzhpmgiacv",
  },
  {
    title: "【12星座別】今日の運勢（HTML出力）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/rsmjbnyqpoolnxqw",
  },
  {
    title: "【12星座別】今日の運勢",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/hlkcnujwhfjefagr",
  },
  {
    title: "タロット占い",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/cywmcwiymfjcinfx",
  },
  {
    title: "【12星座別】気になる男性の溺愛サイン",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/jsfqwgympjufgjsz",
  },
  {
    title: "【生まれた日別】好きになる人の特徴",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/locjwytnitjottrr",
  },
  {
    title: "【2023年下半期の運勢】あなたの運勢は？",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/weisdkgmbgdalmde",
  },
  {
    title: "あなたを加護している龍神様",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/tsxiftdruqrivhet",
  },
  {
    title: "【金運】財を掴むための特別な時期の特徴とその顕現",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/lvhbbroiabkyhdjc",
  },
  {
    title: "【結婚運】運命的な愛を導く重要な出来事の特徴とその訪れる時期",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/mvbzdmfqpulgwhis",
  },
  {
    title: "【恋愛運】一生の愛を導く重要な行動の特徴とそのタイミング",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/srjsnsriloismhen",
  },
  {
    title: "【仕事運】大きな飛躍をもたらす重要な変革の特徴とその到来の時期",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/zstbjrsxsylwyrii",
  },
  {
    title: "【恋愛相性】二人の絆を強化する出来事の特徴とその兆し",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/yagsbiypyjkymnwk",
  },
  {
    title: "【復縁】奇跡的な絆の再生をもたらす転機の特徴と相手の本音",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/dadtljhclannhlkp",
  },
  {
    title: "【不倫】宿命の兆しとその魂の選択による重要な道標",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/guxhqeemudypnvdy",
  },
  {
    title: "【相手が存在する】恋愛系占い鑑定文",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/pcknmzqbieedudce",
  },
  {
    title: "【相手が存在しない】恋愛系占い鑑定文",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/evhpnxsryrvcdzmv",
  },
  {
    title: "スピ系Twitter集客用ツイート",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/xyogtnvpwsncbqhp",
  },
  {
    title: "【スピリチュアル系】語り台本（5分動画用）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/yt-supi-5",
  },
  {
    title: "【スピリチュアル系】語り台本（10分動画用）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/yt-supoi",
  },
  {
    title: "スピリチュアル系のブログ執筆",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/blog-supi",
  },
];

const mangaMaids = [
  {
    title: "漫画の設定を考える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/qjaqzrhgjhtijitq",
  },
  {
    title: "漫画のタイトルを考える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/cxeckdomxabhnlpd",
  },
  {
    title: "セリフを別のセリフに言い換える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/eqfanaoymnaknnvu",
  },
  {
    title: "固有名詞を考える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/glzeldofioxjkikx",
  },
  {
    title: "キャラクターの名前を考える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/msatelpjknjnzggn",
  },
  {
    title: "客観的な感想や気づいた点を聞きたい",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/vqywbfdarkeeiuup",
  },
  {
    title: "読者の感想を聞きたい",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/uxrmosyjzjjhkvvd",
  },
];

const adMaids = [
  {
    title: "キャッチコピー（短め）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/bpmfvucptqjrvhid",
  },
  {
    title: "キャッチコピー（長め）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/lchcoouahoibhcwe",
  },
  {
    title: "キャッチコピーを10文字前後に要約",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/kafyjlbklkzumqos",
  },
  {
    title: "キャッチコピー（比喩）",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/vpranpxqmqovexqo",
  },
  {
    title: "マイクロコピー",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/xfmcrtsnfxdmpgag",
  },
  {
    title: "ベネフィットを考える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/scvkjatsfruxlaqe",
  },
  {
    title: "強烈なキャッチコピー",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/poyyqfocnonubcxm",
  },
  {
    title: "別の言い回しを考える",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/mbmozvqwcpojchar",
  },
  {
    title: "広告の意図を汲み取る",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/ygmnosnacyufumnz",
  },
  {
    title: "Google広告のタイトル",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/google-title",
  },
  {
    title: "Google広告の説明文",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/google-setumei",
  },
  {
    title: "イベントの参加を促すキャッチコピー",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/wqqskipzarowbqyp",
  },
];

const youtubeMaids = [
  {
    title: "YouTubeのサムネ文言",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/mhxyuffjhzvwztre",
  },
  {
    title: "Youtubeのタイトル作成",
    imageUrl: "/img/lp/eLbizjlU_400x400.jpg",
    url: "/users/ais/uyooxosvjqhubmos",
  },
];

export default function Category() {
  const [modalContent, setModalContent] = useState(null);
  const [selectedMaid, setSelectedMaid] = useState<Maid[]>([]);

  function openModal(category: any) {
    if (category.maid === "promptMaids") {
      setSelectedMaid(promptMaids);
    } else if (category.maid === "blogMaids") {
      setSelectedMaid(blogMaids);
    } else if (category.maid === "novelMaids") {
      setSelectedMaid(novelMaids);
    } else if (category.maid === "uranaiMaids") {
      setSelectedMaid(uranaiMaids);
    } else if (category.maid === "mangaMaids") {
      setSelectedMaid(mangaMaids);
    } else if (category.maid === "adMaids") {
      setSelectedMaid(adMaids);
    } else if (category.maid === "youtubeMaids") {
      setSelectedMaid(youtubeMaids);
    }
    setModalContent(category);
  }

  function closeModal() {
    setModalContent(null);
  }

  return (
    <>
      <h1 className="text-xl font-semibold text-gray-900 mt-5 mb-3">
        どのAIツールをお探しですか？
      </h1>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {categorys.map((person) => (
          <li
            key={person.title}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow border border-gray-50 hover:opacity-80 cursor-pointer transition duration-500 ease-in-out"
            onClick={() => openModal(person)}
          >
            <div className="flex w-full items-start justify-between space-x-6 p-4">
              <img
                className="h-[100px] w-[100px] flex-shrink-0 rounded-md bg-gray-300"
                src={person.imageUrl}
                alt={person.title}
              />
              <div className="flex-1 pt-3">
                <div className="flex items-center space-x-3">
                  <h3 className="text-lg font-bold text-gray-900">
                    {person.title}
                  </h3>
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  {person.description}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {modalContent && (
        <div
          className="fixed top-0 left-0 w-full flex items-center justify-center h-full bg-black bg-opacity-70 z-50"
          onClick={closeModal}
        >
          <>
            <div className="m-2 w-full flex items-center justify-center">
              <div
                className="w-full h-[600px] sm:w-[450px] sm:h-[800px] bg-[#202123] rounded-lg p-2 sm:p-4 flex flex-col"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="bg-[#202123] pb-3 text-white text-center font-bold sticky top-0">
                  AIツールを選択
                </div>
                <ul className="overflow-auto flex-grow">
                  {selectedMaid.map((maid) => (
                    <a href={maid.url}>
                      <li
                        key={maid.title}
                        className="mb-3 col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow border border-gray-50 hover:opacity-80 cursor-pointer"
                        onClick={() => openModal(maid)}
                      >
                        <div className="flex w-full items-center justify-between space-x-3 p-4">
                          <img
                            className="h-[80px] w-[80px] flex-shrink-0 rounded-md bg-gray-300"
                            src={maid.imageUrl} // Here, I added `mr-2` to reduce the right margin
                            alt={maid.title}
                          />
                          <div className="flex-1">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-md font-bold text-gray-900">
                                {maid.title}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </li>
                    </a>
                  ))}
                </ul>
                <div className="bg-[#202123] pt-3 text-white text-center font-bold sticky bottom-0">
                  <button onClick={closeModal}>閉じる</button>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
}
