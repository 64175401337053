import React, { useContext } from "react";
import FaceswapMovieUploadForm from "../../../users/ais/faceswap/MovieUploadForm";
import FaceswapMovieFaceDetect from "../../../users/ais/faceswap/MovieFaceDetect";
type Props = {};
const UsersPagesFaceswapsNew: React.FC<Props> = (props) => {
  return (
    <>
      <div className="mb-3">
        <FaceswapMovieUploadForm />
      </div>
      <div className="">
        <FaceswapMovieFaceDetect />
      </div>
    </>
  );
};

export default UsersPagesFaceswapsNew;
