import React, { useState } from "react";
import VimeoModal from "../../../partials/modals/Vimeo";
type Props = {
  vimeoId: string;
  kind: string;
  ctaText?: string;
  title?: string;
  link?: string;
};
const VimeoDemoBtn: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [end, setEnd] = useState(false);

  return (
    <>
      <button
        type="button"
        className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => setOpen(true)}
      >
        demo再生
      </button>
      <VimeoModal
        vimeoId={props.vimeoId}
        open={open}
        setOpen={setOpen}
        canClose={true}
        onVimeoEnd={() => {
          setOpen(false);
        }}
        kind={props.kind}
        ctaText={props.ctaText}
        title={props.title}
        link={props.link}
      />
    </>
  );
};
export default VimeoDemoBtn;
