import React from "react";
import {
  HomeIcon,
  DocumentTextIcon,
  StarIcon,
  UsersIcon,
  PaperAirplaneIcon,
  FolderIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/outline";

export interface SubmenuItem {
  name: string;
  slug: string;
  isActive?: boolean;
}

export interface SidebarItem {
  icon: React.FC;
  title: string;
  url: string;
  items: SubmenuItem[];
  isActive?: boolean;
}

let sidebarItems: SidebarItem[] = [];
let submenuItems: SubmenuItem[] = [];
export const initializeSidebar = (dynamicValues: any[]): void => {
  submenuItems = dynamicValues.map((value) => {
    return {
      name: value.name,
      slug: value.slug,
      url: `/ai_menu/text/category/${value.slug}`,
    };
  });
  sidebarItems = [
    {
      icon: HomeIcon,
      title: "HOME",
      url: "/",
      items: [],
    },
    {
      icon: DocumentTextIcon,
      title: "テキスト生成",
      url: "",
      items: submenuItems,
    },
    {
      icon: MicrophoneIcon,
      title: "文字起こし",
      url: "/ai_menu/whisper",
      items: [],
    },
    {
      icon: StarIcon,
      title: "お気に入り",
      url: "/ai_menu/favorites",
      items: [],
    },
    {
      icon: UsersIcon,
      title: "ユーザー管理",
      url: "/users/users",
      items: [],
    },
    {
      icon: FolderIcon,
      title: "データ管理",
      url: "/rag_files",
      items: [],
    },
    {
      icon: PaperAirplaneIcon,
      title: "お問い合わせ",
      url: "https://forbiz.crowdworks-ai.com/1-3",
      items: [],
    },
  ];
};
export const getSidebarItems = (): SidebarItem[] => sidebarItems;
