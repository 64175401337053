import React from "react";
import {Ai} from "@/interfaces/ai";

type Props = {
  ai: Ai;
  children?: React.ReactNode;
};
export const AiCardImage: React.FC<Props> = ({ai}) => {
  return (
    <div className="flex-none">
      <div className="flex items-center justify-center">
        <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center text-xl">
          <img
            alt=""
            className="object-cover w-16 h-16 flex-shrink-0 rounded-md bg-gray-300"
            src={ai.image.thumb.url}
          />
        </div>
      </div>
    </div>
  );
};

export const AiCardTitle: React.FC<Props> = ({ai}) => {
  return (
    <h3 className="text-sm font-bold line-clamp-2">{ai.name}</h3>
  );
}

export const AiCardIntro: React.FC<Props> = ({ai}) => {
  return (
    <p className="pt-2 text-xs text-gray-500 line-clamp-3 min-h-[50px] w-auto">
      {ai.intro}
    </p>
  );
}
