import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

type Props = {
  open: boolean;
};
const DiscordConnect: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(props.open);

  const handleDiscordConnect = () => {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");
    if (csrfToken) {
      const form = document.createElement("form");
      form.method = "post";
      form.action = "/users/auth/discord";
      const csrfInput = document.createElement("input");
      csrfInput.type = "hidden";
      csrfInput.name = "authenticity_token";
      csrfInput.value = csrfToken;
      form.appendChild(csrfInput);
      document.body.appendChild(form);
      form.submit();
    }
  };
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Discord限定コミュニティの参加をお願いします
                      </Dialog.Title>
                      <div className="mt-4">
                        <h4 className="text-md font-bold text-gray-500 text-left max-w-sm mx-auto mb-2">
                          連携手順
                        </h4>
                        <p className="text-sm text-gray-500 text-left max-w-sm mx-auto">
                          1. 下の「Discordに参加する」ボタンをクリック
                          <br />
                          2. Discordの認証ページにリダイレクトされます
                          <br />
                          3.
                          Discordアカウントでログイン（既にログイン済みの場合はスキップ）
                          <br />
                          4. 権限の確認と許可
                          <br />
                          5. 連携完了！Discordサーバーに自動的に招待されます
                        </p>
                        <div className="mt-5 sm:mt-6 flex justify-center">
                          <button
                            className="w-full inline-flex items-center justify-center text-white bg-[#161CBB] hover:bg-[#161CBB]/90 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
                            onClick={handleDiscordConnect}
                          >
                            Discordと連携して限定コミュニティに参加
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DiscordConnect;
