import React from "react";
// import ReactDOM from 'react-dom'
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
// import { useInView } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import { Container } from "./Container";
const reviews = [
  {
    title: "なんでも自由に生成",
    body: "すきな指示でなんでも自由に生成できます",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo1.png",
  },
  {
    title: "楽曲系に使えそうなエモい美少女サムネ",
    body: "YouTubeやTikTokなど動画のサムネイルや動画素材に使えそうなエモい美少女アニメイラストを作成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo2.png",
  },
  {
    title: "ちびキャラ",
    body: "かわいいちびキャラを生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo3.png",
  },
  {
    title: "D-ID用アバターメーカー",
    body: "ショート動画などで使える地蔵や大仏のアバターを作成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo4.png",
  },
  {
    title: "ちびキャラLINEスタンプメーカー",
    body: "LINEスタンプ用のイラストを作ります",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo5.png",
  },
  {
    title: "AI美女メーカー2",
    body: "リアルなAI美女を生成します。髪型や服装など細かく指定できます",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo6.png",
  },
  {
    title: "Vtuberの立ち絵メーカー",
    body: "Vtuberの立ち絵を生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo7.png",
  },
  {
    title: "風景",
    body: "リアルな風景のイラストを生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo8.png",
  },
  {
    title: "淡くて美しいキャラクターイラスト",
    body: "キラキラで美しいキャラクターイラストを描きます",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo9.png",
  },
  {
    title: "恋愛系アニメ風漫画イラスト",
    body: "恋愛系漫画風イラストを生成します！恋愛系ショート動画の素材にぴったりです。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo10.png",
  },
  {
    title: "LINEスタンプメーカー2",
    body: "同じキャラクターを複数体生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo11.png",
  },
  {
    title: "可愛さの中に少し格好良さがあるイラスト",
    body: "可愛さの中に少し格好良さがあるイラストを生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo12.png",
  },
  {
    title: "80年代風シティポップ調のイラスト",
    body: "エモい雰囲気のイラストを生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo13.jpg",
  },
  {
    title: "AI美女メーカー",
    body: "美女のイラストを生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo14.png",
  },
  {
    title: "ゆるかわスケッチイラスト",
    body: "ゆるかわイラストを描きます",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo15.png",
  },
  {
    title: "マッハ1000メーカー",
    body: "なんでもマッハ1000にします",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo16.png",
  },
  {
    title: "AI〇〇娘メーカー",
    body: "オリジナルの可愛い〇〇娘を作ります。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo17.png",
  },
  {
    title: "AIが考える〇〇メーカー",
    body: "AIが考える〇〇のイラストを作成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo18.png",
  },
  {
    title: "ゆるキャラメーカー",
    body: "オリジナルのゆるキャラを作成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo19.png",
  },
  {
    title: "ご利益壁紙メーカー",
    body: "運気アップスマホ壁紙を生成してプレゼント企画などにつかえます",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo20.png",
  },
  {
    title: "AI美男メーカー",
    body: "リアルな男性を生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo21.png",
  },
  {
    title: "ナショナル・ジオグラフィック",
    body: "ナショナル・ジオグラフィックスタイルの写真を作成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo22.png",
  },
  {
    title: "子供用の塗り絵",
    body: "子供用の塗り絵のようなイラストを生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo23.png",
  },
  {
    title: "AI猫メーカー",
    body: "かわいい猫のイラストを作成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo24.png",
  },
  {
    title: "顔面どアップイラストアイコン",
    body: "SNSのアイコンなどに使える顔面ドアップアニメイラストを生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo25.png",
  },
  {
    title: "少年漫画風イラスト（モノクロ）",
    body: "モノクロの少年漫画風イラストを生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo26.png",
  },
  {
    title: "画風MIXメーカー",
    body: "あなたの指定したテーマで二つの画風をミックスした面白いイラストを生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo27.png",
  },
  {
    title: "ファンタジー風景",
    body: "ファンタジー風景を生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo28.png",
  },
  {
    title: "半透明シルエット",
    body: "半透明シルエットを生成します",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo29.png",
  },
  {
    title: "少女漫画風イラスト（モノクロ）",
    body: "モノクロの少女漫画風イラストを生成します。",
    image: "https://ordermaid.media/wp-content/uploads/2024/06/gazo30.png",
  },
];

function Maid2({ title, body, image, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = [
      "0s",
      "0.1s",
      "0.2s",
      "0.3s",
      "0.4s",
      "0.5s",
    ];
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ];
  }, []);

  return (
    <figure
      className={clsx(
        "rounded-3xl bg-white px-4 py-6 shadow-md shadow-gray-900/5",
        className
      )}
      style={{
        animationName: "fade-in",
        animationDelay,
        animationDuration: "0.5s",
        animationTimingFunction: "ease-in-out",
        animationFillMode: "forwards",
      }}
      {...props}
    >
      <div className="bg-white flex items-center space-x-4 rounded-lg transition transform duration-500">
        <div>
          <img
            src={image}
            className="rounded-xl h-[80px] w-[80px] min-w-[80px] object-cover"
            width="100%"
            alt=""
          />
        </div>
        <div>
          <h2 className="mb-1 text-base font-semibold leading-6 before:content-[''] after:content-['']">
            {title}
          </h2>
          <p className="text-sm text-gray-600">{body}</p>
        </div>
      </div>
    </figure>
  );
}

function splitArray(array: any[], numParts: number): any[][] {
  let result: any[][] = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function MaidColumn2({
  className,
  reviews,
  reviewClassName = () => undefined, // デフォルト値を設定
  msPerPixel = 0,
}: {
  className?: string;
  reviews: any[];
  reviewClassName?: (index: number) => string | undefined;
  msPerPixel?: number;
}) {
  let columnRef = useRef<HTMLDivElement>(null);
  let [columnHeight, setColumnHeight] = useState<number>(0);
  let duration = `${columnHeight * msPerPixel}ms`;
  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      if (columnRef.current) {
        setColumnHeight(columnRef.current.offsetHeight);
      }
    });
    if (columnRef.current) {
      resizeObserver.observe(columnRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration } as React.CSSProperties}
    >
      {reviews.concat(reviews).map(
        (
          review: JSX.IntrinsicAttributes & {
            [x: string]: any;
            title: any;
            body: any;
            category: any;
            image: any;
            className: any;
          },
          reviewIndex: number
        ) => (
          <Maid2
            key={reviewIndex}
            aria-hidden={reviewIndex >= reviews.length}
            {...review}
            className={
              typeof reviewClassName === "function"
                ? reviewClassName(reviewIndex % reviews.length) // 非nullアサーションを削除
                : reviewClassName ?? undefined
            }
          />
        )
      )}
    </div>
  );
}

function MaidGrid2() {
  const [containerRef, isInView] = useInView({
    threshold: 0.4,
    rootMargin: "0px 0px -20% 0px",
    triggerOnce: true,
  });
  let columns = splitArray(reviews, 3);
  columns = [columns[0], columns[1], splitArray(columns[2], 2)];

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <MaidColumn2
            reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
            reviewClassName={(reviewIndex: number) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2][0].length &&
                  "md:hidden",
                reviewIndex >= columns[0].length && "lg:hidden"
              )
            }
            msPerPixel={10}
            className={undefined}
          />
          <MaidColumn2
            reviews={[...columns[1], ...columns[2][1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex: number) =>
              clsx(reviewIndex >= columns[1].length && "lg:hidden")
            }
            msPerPixel={15}
          />
          <MaidColumn2
            reviews={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-900" />
    </div>
  );
}

const Maids2 = () => {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="pt-20 pb-16 sm:pt-56 sm:pb-24 md:pt-36"
    >
      <Container className={undefined}>
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-[26px] font-bold tracking-wider text-white sm:text-6xl">
            100種類以上の画像生成AI
          </p>
        </div>
        <p className="text_grade font-bold tracking-wide mx-auto mt-6 max-w-full text-center text-sm sm:text-2xl sm:leading-10 leading-8 text-white">
          用途に応じて、100種類以上の画像生成AIが使えます。
          <br />
          イラスト素材、写真素材、SNSアイコンなど
          <br className="sm:hidden" />
          様々な場面で活用できます。
        </p>
        <MaidGrid2 />
      </Container>
    </section>
  );
};

export default Maids2;
