import React, { useState } from "react";
import { ImageAiForm } from "../../../../interfaces/image_ai_form";
import InputText from "../forms/InputText";
import TextArea from "../forms/TextArea";
import InputSelect from "../forms/InputSelect";
import { useForm } from "react-hook-form";
import { User } from "../../../../interfaces/user";
import AdsenceCard from "../../../../components/partials/adsence/Card";
import InputSwitch from "../../../partials/forms/input/Switch";

type Props = {
  submit: (data: any) => void;
  imageAiForms: ImageAiForm[];
  streaming: boolean;
  cancelStreaming: () => void;
  user?: User;
  remainGenerateCap: number;
  setSwitchModalOpen: (v: boolean) => void;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const num_options: number[] = [];

  const [publish, setPublish] = useState(true);

  const maxGenerateNum = () => {
    if (props.user && !props.user.isPaidDallE) {
      return 5;
    }
    if (props.remainGenerateCap >= 10) {
      return 10;
    } else if (props.remainGenerateCap <= 0) {
      return 5;
    } else {
      return props.remainGenerateCap;
    }
  };
  for (let i = 1; i <= maxGenerateNum(); i++) {
    num_options.push(i);
  }

  const size_options = [
    {
      name: "正方形(1:1)",
      val: "1024x1024",
    },
    {
      name: "縦長(9:16)",
      val: "1024x1792",
    },
    {
      name: "横長(16:9)",
      val: "1792x1024",
    },
  ];

  const [dirty, setDirty] = useState(false);
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const inputTag = (aiForm, idx) => {
    if (aiForm.kind == "input_text") {
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setDirty={setDirty}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setDirty={setDirty}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setDirty={setDirty}
        />
      );
    } else if (aiForm.kind == "input_radio") {
    }
  };

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();
    if (result) {
      const data = getValues();
      if (publish) {
        data.publish_flag = "true";
      }
      props.submit(data);
      setDirty(false);
    }
  };

  const toggleSwitch = () => {
    if (!props.user?.isPaidDallE) {
      props.setSwitchModalOpen(true);
    } else {
      setPublish(!publish);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button className="w-full rounded-full bg-gradient-to-r from-red-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90">
          生成中です。30秒ほどお待ちください...
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="w-full rounded-full bg-gradient-to-r from-blue-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          生成する
        </button>
      );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div className="sm:shadow-lg sm:rounded-lg">
          <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-6 gap-3">
              {props.imageAiForms.map((aiForm, idx) => {
                return inputTag(aiForm, idx);
              })}
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  サイズ
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`size`, {
                      required: true,
                    })}
                    onBlur={() => setDirty(true)}
                  >
                    {size_options.map((option, i) => {
                      return (
                        <option key={`kind-option-${i}`} value={option.val}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors[`size`]?.type === "required" && (
                  <p className="font-semibold text-sm text-red-400">
                    選択してください
                  </p>
                )}
              </div>
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  生成数
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`num`, {
                      required: true,
                    })}
                    onBlur={() => setDirty(true)}
                  >
                    {num_options.map((option) => {
                      return (
                        <option key={`kind-option-${option}`} value={option}>
                          {option}枚
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className={`col-span-2`}>
              <label className="block text-sm font-medium leading-6 text-gray-900 mb-1">
                生成した画像を公開
              </label>
              <InputSwitch
                isChecked={publish}
                setIsChecked={setPublish}
                toggleSwitch={toggleSwitch}
              />
            </div>
          </div>

          <div className="bg-[#f7f9f9] px-4 py-3 text-right sm:px-6">
            {props.user && !props.user.isPaid && (
              <div className="mb-3">
                <AdsenceCard
                  clientId={`ca-pub-2307777494961440`}
                  slotId={`6888306272`}
                />
              </div>
            )}
            {submitBtn()}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
