import getClient from "../client";

export const toggleUserFavorite = async (slug: string) => {
  return getClient().post(`/users/user_favorite_ais/toggle.json`, {
    slug: slug,
  });
};

export const destroyUserFavorite = async (slug: string) => {
  return getClient().delete(`/users/user_favorite_ais/${slug}.json`);
};
