import React from "react";
import {categories} from "./CategoryData";

interface Category {
  imgSrc: string;
  title: string;
  description: string;
  link: string;
}

const CategoryCard: React.FC<Category> = ({imgSrc, title, description, link}) => {
  return (
    <a title={title} href={link}>
      <div
        className="card p-4 border rounded-lg shadow hover:shadow-lg transition-shadow relative bg-white flex flex-col h-full">
        <img src={imgSrc} alt={title} className="w-full"/>
        <div className="p-6 flex flex-col flex-grow h-full">
          <h3 className="text-lg font-semibold mb-3">{title}</h3>
          <p className="text-gray-500">{description}</p>
        </div>
      </div>
    </a>
  );
};

const Categories: React.FC = () => {
  return (
    <section>
      <div className="p-8" style={{background: "linear-gradient(to bottom, #1D4ED8 40%, transparent 30%)"}}>
        <h2 className="text-lg font-semibold mb-4 text-white">業種から探す</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <div key={index}>
              <CategoryCard
                imgSrc={category.imgSrc}
                title={category.title}
                description={category.description}
                link={category.link}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;
