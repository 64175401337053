import React, { useState } from "react";

import { Ai } from "../../../interfaces/ai";
import { User } from "../../../interfaces/user";
import AiCard from "../../users/index/AiCard";
type Props = {
  ais: [Ai];
  user: User;
};

const UsersIndex: React.FC<Props> = (props) => {
  return (
    <>
      {props.ais.length > 0 && (
        <>
          <div className="mt-5">
            <div
              className="gap-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
              role="list"
            >
              {props.ais.map((ai) => {
                return (
                  <AiCard key={`ai-${ai.slug}`} ai={ai} user={props.user} />
                );
              })}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="text-center">＼ 他にも200種類以上のAIツール ／</div>
            <a
              className="w-full font-bold max-w-md rounded-lg bg-pink-500 text-white p-3 shadow text-center"
              href="/users/ais"
            >
              全てのメイドを見る
            </a>
          </div>
        </>
      )}
    </>
  );
};
export default UsersIndex;
