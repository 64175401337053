import React, { useState } from "react";

import { UserGenImg } from "../../../interfaces/user_gen_img";
import { ImageAi } from "../../../interfaces/image_ai";
import ImageAiTweetBtn from "../../users/ogp_skins/ImageAiTweetBtn";
import { saveAs } from "file-saver";

type Props = {
  image: UserGenImg;
  imageAi: ImageAi;
  setGuideModalOpen: (v: boolean) => void;
  historyMode?: boolean;
};

const ImageCard: React.FC<Props> = (props) => {
  // 画像ごとのダウンロード状態を管理するステート
  const [downloadingStatus, setDownloadingStatus] = useState<{
    [url: string]: boolean;
  }>({});

  const downloadImage = async (url) => {
    setDownloadingStatus((prev) => ({ ...prev, [url]: true })); // ダウンロード開始

    try {
      const encodedUrl = encodeURIComponent(url);
      await saveAs(`/api/v1/users/proxies?url=${encodedUrl}`);
    } catch (error) {
      console.error("Error during image download", error);
    } finally {
      setTimeout(() => {
        setDownloadingStatus((prev) => ({ ...prev, [url]: false }));
      }, 5000);
    }
  };

  let displayImageUrl, downloadImageUrl;
  if (props.historyMode && props.historyMode == true) {
    displayImageUrl = props.image.image.thumb.url;
    downloadImageUrl = props.image.image.url;
  } else {
    displayImageUrl = props.image.source;
    downloadImageUrl = props.image.source;
  }

  return (
    <>
      <div
        className="col-span-4 xl:col-span-3 shadow sm:overflow-hidden sm:roudned-md border p-3"
        key={`image-${props.image.source}`}
      >
        <img src={displayImageUrl} alt={``} className="mr-auto ml-auto" />
        <div className="w-full flex items-center pt-2 text-gray-600 font-bold">
          {/* ダウンロードボタン */}
          {!downloadingStatus[displayImageUrl] ? (
            <span className="relative group">
              <button
                className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2"
                onClick={() => downloadImage(downloadImageUrl)}
              >
                <i className="fa-solid fa-download cursor-pointer"></i>
                <span className="hidden lg:inline-block ml-1">
                  ダウンロード
                </span>
              </button>
            </span>
          ) : (
            <span className="relative group">
              <button className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2">
                <i className="fa-solid fa-download cursor-pointer"></i>
                <span className="hidden lg:inline-block ml-1">
                  ダウンロード中...
                </span>
              </button>
            </span>
          )}
          <span className="relative group">
            <a
              className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2"
              href={props.image.image.url}
              target="_blank"
            >
              <i className="fa-solid fa-arrow-up-right-from-square cursor-pointer"></i>
              <span className="hidden lg:inline-block ml-1">URLを開く</span>
            </a>
          </span>
        </div>
        <div className="w-full flex items-center mt-2 justify-around text-gray-600 font-bold">
          <ImageAiTweetBtn userGenImg={props.image} imageAi={props.imageAi} />
          <button
            onClick={() => props.setGuideModalOpen(true)}
            className="flex items-center justify-center rounded-full border border-gray-200 bg-gray-50 text-gray-600"
          >
            <div className="flex h-6 w-6 items-center justify-center text-xs">
              ?
            </div>
          </button>
        </div>
      </div>
    </>
  );
};
export default ImageCard;
