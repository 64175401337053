import React, { useState } from "react";

import { RefreshCcw, MoveRight, Plus } from "lucide-react";

import { FaceswapIcon } from "../../../../interfaces/faceswaps/icon";
import FaceIcon from "./FaceIcon";

type Props = {};
const FaceswapMovieFaceDetect: React.FC<Props> = (props) => {
  const [faceIcons, setFaceIcons] = useState<FaceswapIcon[]>([
    {
      imageUrl: "/demo/cropped_image_0.jpg",
      landmarks: [[309, 136, 110, 144]],
    },
  ]);
  return (
    <>
      <div className="flex flex-col lg:flex-row items-stretch justify-center">
        {/* 左半分(動画見えるところ) */}
        <div className="p-8 lg:w-2/5 w-full bg-blue-900 text-white">
          <div className="flex items-center justify-between mb-6">
            <p className="text-sm">tikitok.mp4</p>
            <button className="bg-transparent hover:bg-blue-500 text-white hover:bg-blue-800 font-semibold hover:text-white py-2 px-4 border border-white rounded flex items-center justify-center">
              <RefreshCcw className="mr-2 w-4 h-4" />
              <span className="text-xs">動画を変更</span>
            </button>
          </div>
          <div className="flex items-center justify-center h-full">
            <video
              src="/demo/tiktok.mp4"
              controls
              autoPlay
              muted
              className="w-2/4 mb-8"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        {/* 右半分(faceswap) */}
        <div className="p-8 lg:w-3/5 w-full bg-gray-800 text-white">
          <div className="flex items-top justify-center h-full">
            <div className="w-2/4">
              <p className="text-sm mb-4">検出した顔写真</p>
              <div className="pr-8">
                {faceIcons.map((icon, i) => {
                  return (
                    <div className="flex items-center justify-between">
                      <FaceIcon
                        key={`face_icon-${i}`}
                        imageUrl={icon.imageUrl}
                      />
                      <MoveRight className="w-4 h-4" />
                      <div
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                        }}
                        className="border-2 border-dashed border-gray-200 flex items-center justify-center cursor-pointer"
                      >
                        <Plus className="w-4 h-4" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-2/4">
              <p className="text-sm">ライブラリから選択</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          className="rounded-full bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          動画を変換
        </button>
      </div>
    </>
  );
};

export default FaceswapMovieFaceDetect;
