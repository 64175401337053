import getClient from "./client";
import { GetAisParams, PaginateAisParams } from "../../interfaces/requests/ai";
export const getImageAis = async (params: GetAisParams) => {
  const response = await getClient().get(`/image_ais.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};

export const paginateImageAis = async (params: PaginateAisParams) => {
  const response = await getClient().get(`/image_ais/paginate.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};
