import React, { useState } from "react";
import { Message } from "../../../../../interfaces/message";
import { useForm } from "react-hook-form";
type Props = {
  addMessage: (message: Message) => void;
};
const UserAiChatMessageForm: React.FC<Props> = (props) => {
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = getValues();
    console.log("data", data);
    props.addMessage({
      body: data.orderText,
      type: "userSent",
    });
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
  };

  const textareaStyle = {
    maxHeight: "200px",
    height: "24px",
  };

  return (
    <div className="sm:ml-32 fixed bottom-0 left-0 w-full md:border-t-0 md:border-transparent bg-vert-light-gradient bg-white md:!bg-transparent pt-2">
      <form
        className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 md:max-w-xl lg:mx-auto lg:max-w-2xl xl:max-w-3xl"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col w-full py-[10px] flex-grow md:py-4 md:pl-4 relative border border-gray-200 bg-white rounded-xl shadow-xl">
          <textarea
            id="prompt-textarea"
            data-id="root"
            onKeyDown={onKeyDown}
            placeholder="追加指示を書いてください（例）続きを書いて"
            className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-10 focus:outline-none md:pr-12 pl-3 md:pl-0"
            style={textareaStyle}
            {...register("orderText", { required: true })}
          ></textarea>
          <button
            type="submit"
            className="absolute p-1 rounded-md md:bottom-3 md:p-2 md:right-3 right-2 disabled:text-gray-400 enabled:bg-brand-purple text-gray-300 bottom-1.5 transition-colors disabled:opacity-40"
          >
            <span className="" data-state="closed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
                className="h-4 w-4 m-1 md:m-0"
                strokeWidth="2"
              >
                <path
                  d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </button>
        </div>
        {errors[`orderText`]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500">入力してください</p>
        )}
      </form>
    </div>
  );
};

export default UserAiChatMessageForm;
