document.addEventListener("DOMContentLoaded", function() {
  // ここにDOMが読み込まれた後に実行したいJavaScriptコードを書く
  var closeButton = document.querySelector(".close_flash_message");
  if(closeButton!=null){
    closeButton.addEventListener("click", function() {
      var flashMessage = document.querySelector(".flash_message");
      flashMessage.remove();
    });
  }
});

export const checkDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      return 'mobile';
  } else {
      return 'desktop';
  }
}

export const isSp = () => {
  return checkDeviceType() == "mobile"
}
