import getClient from "../client";
import { GetLeafCategoriesParams } from "../../../interfaces/requests/leaf_category";

/**
 * サブカテゴリー一覧を取得
 */
export const vendorsGetLeafCategories = async (
  params: GetLeafCategoriesParams
) => {
  return getClient().get(`/vendors/leaf_categories.json`, {
    params: params,
  });
};
