import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import getClient from "@/components/biz/libs/api/client";
import {toast} from "react-toastify";

interface FavoriteState {
  favorites: string[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: FavoriteState = {
  favorites: [],
  status: 'idle',
};

export const fetchFavorites = createAsyncThunk(
  'favorites/fetchFavorites',
  async () => {
    const client = getClient();
    const response = await client.get<string[]>('/users/user_favorite_ais/');
    return response.data.map(ai => ai.slug);
  }
);

export const toggleFavoriteAPI = createAsyncThunk(
  'favorites/toggleFavorite',
  async (slug: string) => {
    const client = getClient();
    const response = await client.post('/users/user_favorite_ais/toggle.json', {slug: slug});
    return response.data;
  }
);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    toggleFavoriteLocal: (state: FavoriteState, action: PayloadAction<string>) => {
      const index = state.favorites.indexOf(action.payload);
      if (index === -1) {
        state.favorites.push(action.payload);
      } else {
        state.favorites.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state: FavoriteState) => {
        state.status = 'loading';
      })
      .addCase(fetchFavorites.fulfilled, (state: FavoriteState, action) => {
        const favorites = action.payload as string[];
        state.status = 'succeeded';
        state.favorites = favorites;
      })
      .addCase(fetchFavorites.rejected, (state: FavoriteState) => {
        state.status = 'failed';
      })
      .addCase(toggleFavoriteAPI.fulfilled, (state: FavoriteState, action) => {
        const slug = action.payload.slug as string;
        if (state.favorites.includes(slug)) {
          state.favorites = state.favorites.filter((fav) => fav !== slug);
        } else {
          state.favorites.push(slug);
          toast.success("お気に入りに追加しました");
        }
      });
  },
});

export const {toggleFavoriteLocal} = favoritesSlice.actions;
export default favoritesSlice.reducer;
