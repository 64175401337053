import React, { useContext } from "react";

type Props = {
  imageUrl: string;
};

const FaceswapFaceIcon: React.FC<Props> = (props) => {
  const { imageUrl } = props;

  return (
    <div
      style={{
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        overflow: "hidden",
        position: "relative",
      }}
      className="border-2 border-white"
    >
      <img
        src={imageUrl}
        alt="Face"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "100%",
          minHeight: "100%",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default FaceswapFaceIcon;
