import React, {useState} from 'react';
import {FaChevronDown, FaChevronUp} from 'react-icons/fa';
import {SubmenuItem} from "@/components/biz/partials/sidebar/Items";

interface DropdownMenuProps {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    title: string;
    items: SubmenuItem[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({icon, title, items}) => {
    const [isOpen, setIsOpen] = useState(true);
    const IconComponent = icon;
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="">
            <div
                className="flex justify-between items-center cursor-pointer rounded-lg hover:bg-gray-100 hover:text-cwaipurple-600 p-1"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
                role="button"
                aria-controls="menu-list"
            >
                <div className="flex items-center space-x-4">
                    <IconComponent className="w-6 h-6" />
                    <span className="text-md font-semibold">{title}</span>
                </div>
                <div className="pr-2">
                    {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                </div>
            </div>
            {isOpen && items.length > 0 && (
                <ul id="menu-list" role="menu" className="pt-2 pl-10 space-y-2">
                    {items.map((item, index) => {
                        const activeClasses = item.isActive ? "bg-gray-100 text-cwaipurple-600" : "";
                        return (
                          <li key={index} role="menuitem" tabIndex={0}>
                              <div
                                className={`flex font-medium rounded-lg hover:bg-gray-100 hover:text-cwaipurple-600 ${activeClasses}`}
                              >
                                  <a href={item.url} className="px-2 py-1.5 w-full">
                                      <span className="text-md font-semibold">{item.name}</span>
                                  </a>
                              </div>
                          </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default DropdownMenu;
