import React from "react";
type Props = {
  message: string;
};
const UserAiChatMessgesUser: React.FC<Props> = (props) => {
  return (
    <>
      <div className="chat-message">
        <div className="flex items-end justify-end w-full">
          <div className="flex flex-col space-y-2 mx-2 order-1 items-end w-full">
            <div className="-mb-4 w-full">
              <span className="px-4 py-2 rounded-lg w-full inline-block rounded-tr-none bg-green-300 text-gray-800 whitespace-pre-wrap break-words">
                {props.message}
              </span>
            </div>
          </div>

          <div className="inline-flex items-center justify-center text-white bg-gray-400 h-10 w-10 rounded-full focus:ring-4 focus:ring-gray-300 order-2">
            <i className="fa-solid fa-user w-6 h-6" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAiChatMessgesUser;
