import getClient from "../client";

export const vendorUpdatePrompt = async (slug: string, prompt: string) => {
  return getClient().post(`/vendors/ais/${slug}/update_prompt.json`, {
    prompt: prompt,
  });
};

export const vendorCloneAi = async (slug: string) => {
  return getClient().post(`/vendors/ais/${slug}/clone.json`);
};

export const vendorCreateAi = async (params) => {
  return getClient().post(`/vendors/ais.json`, params);
};

export const vendorUpdateAi = async (slug: string, params) => {
  return getClient().put(`/vendors/ais/${slug}.json`, params);
};
