import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
type Props = {
  count: number;
  setCount: (v: number) => void;
  limit: number;
  resetAt?: string;
  label?: string;
  isFree?: boolean;
  unit?: string;
};

const NavBar: React.FC<Props> = (props) => {
  const [count, setCount] = useState(props.count);
  const calcPercent = () => {
    return (count / props.limit) * 100;
  };

  useEffect(() => {
    setCount(props.count); // props.countの変更を監視し、countを更新
  }, [props.count]);

  const handleIncrementGenerateCount = (event) => {
    console.log("handleIncrementGenerateCount", event);
    setCount(event.detail);
  };

  useEffect(() => {
    window.addEventListener(
      "incrementGenerateCount",
      handleIncrementGenerateCount
    );
    return () => {
      window.removeEventListener(
        "incrementGenerateCount",
        handleIncrementGenerateCount
      );
    };
  }, []);

  const unit = () => {
    return props.unit ? props.unit : "回";
  };

  return (
    <>
      <div className="flex justify-between mb-1">
        <span className="font-medium text-gray-700">
          <span className="font-bold text-sm">
            {props.label ? props.label : "生成上限"}
            {props.isFree && <>(※回復なし)</>}
          </span>
          {props.resetAt && !props.isFree && (
            <span className="text-xs text-gray-400 ml-1">
              ※{dayjs(props.resetAt).format("MM月DD日 HH:mm")}
              にリセット
            </span>
          )}
        </span>
        <span className="text-xs font-medium text-gray-700">
          {props.limit}
          {unit()}
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full">
        {count > 0 ? (
          <div
            className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{
              width: `${calcPercent()}%`,
              background: "linear-gradient(270deg, #0acffe 0%, #495aff 100%)",
            }}
          >
            {calcPercent() > 5 ? (
              <>
                {count}
                {unit()}
              </>
            ) : (
              count
            )}
          </div>
        ) : (
          <div className="text-xs font-medium text-center p-0.5 leading-none rounded-full">
            {count}
            {unit()}
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;
