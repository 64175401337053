import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { NovitaModel } from "../../../interfaces/novita";
import { useInView } from "react-intersection-observer";

import {
  getCivitaiModels,
  getCivitaiLoras,
} from "../../../libs/api/admins/novita_ai";
type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
  onSelectImage?: (model: NovitaModel) => void;
  lora?: boolean;
};

const CivitaiModal: React.FC<Props> = (props) => {
  const cancelButtonRef = useRef(null);
  const [civitaiModels, setCivitaiModels] = useState<NovitaModel[]>([]);
  const [nextCursor, setNextCursor] = useState<string>("");

  useEffect(() => {
    if (props.open) {
      if (props.lora) {
        getCivitaiLoras().then((res) => {
          console.log("res.data.models", res.data.models);
          setCivitaiModels(res.data.models);
          setNextCursor(res.data.nextCursor);
        });
      } else {
        getCivitaiModels().then((res) => {
          setCivitaiModels(res.data.models);
          setNextCursor(res.data.nextCursor);
        });
      }
    }
  }, [props.open]);

  const { ref, inView } = useInView({
    threshold: 0, // 0は要素が少しでも表示されるとトリガーされる
  });

  useEffect(() => {
    if (inView) {
      //console.log("inView", inView);
      if (props.lora) {
        getCivitaiLoras({ nextCursor: nextCursor }).then((res) => {
          // 既存のモデルに新しいモデルを追加
          setCivitaiModels((prevModels) => [...prevModels, ...res.data.models]);
          setNextCursor(res.data.nextCursor);
        });
      } else {
        getCivitaiModels({ nextCursor: nextCursor }).then((res) => {
          // 既存のモデルに新しいモデルを追加
          setCivitaiModels((prevModels) => [...prevModels, ...res.data.models]);
          setNextCursor(res.data.nextCursor);
        });
      }
    }
  }, [inView]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="flex items-center justify-between p-3 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Civital モデル一覧
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="default-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="sm:flex sm:items-start p-3">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="mt-2 grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 gap-4">
                      {civitaiModels.map((model) => {
                        return (
                          <div
                            className="border shadow-md rounded-xl break-words cursor-pointer"
                            key={model.id}
                            onClick={() =>
                              props.onSelectImage && props.onSelectImage(model)
                            }
                          >
                            <img
                              src={model.coverUrl}
                              className="col-span-4 flex-shrink-0 rounded-md bg-gray-300"
                            />
                            <div className="p-2">
                              <h3 className="text-xs font-semibold text-gray-900">
                                {model.sdName}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div ref={ref}></div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-col p-3">
                  <button
                    type="button"
                    className="bg-gray-100 text-gray-600 font-bold py-3 w-full rounded-full hover:opacity-80"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    閉じる
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CivitaiModal;
