import React from "react";

type Props = {
  content: string | number;
  className?: string;
};

const PaginationLabel: React.FC<Props> = ({ content, className }) => (
  <p className={`px-3 py-2 ml-0 leading-tight ${className}`}>
    {content}
  </p>
);

export default PaginationLabel;
