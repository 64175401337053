import React, { useState } from "react";
import { usersPostChangeFree } from "../../../../libs/api/users/stripe";
import DowngradeFree from "../../../partials/buttons/DowngradeToFree";
const SurveyComplete: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const onClickBtn = async () => {
    setLoading(true);
    await usersPostChangeFree();
    location.href = "/users/plans/redirect_after_change_to_free";
  };

  return (
    <>
      <DowngradeFree onClickBtn={onClickBtn} loading={loading} />
    </>
  );
};
export default SurveyComplete;
