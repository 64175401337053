import React, { useState, useEffect, useMemo } from "react";
import { BeautyAi } from "../../../../interfaces/beauty_ai";
import { BeautyAiForm } from "../../../../interfaces/beauty_ai_form";
import { MainCategory } from "../../../../interfaces/main_category";
import { adminsGetSubCategories } from "../../../../libs/api/admins/sub_category";
import { adminsGetLeafCategories } from "../../../../libs/api/admins/leaf_category";
import {
  adminCreateBeautyAi,
  adminUpdateBeautyAi,
} from "../../../../libs/api/admins/beauty_ai";
import { adminBulkPostImageAiForms } from "../../../../libs/api/admins/image_ai_form";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import FormRow from "../../../admins/ais/form/FormRow";
import CivitaiModel from "../../../partials/modals/CivitaiModel";
import { saveAs } from "file-saver";
import { NovitaModel } from "../../../../interfaces/novita";

type Props = {
  beautyAi: BeautyAi;
  beautyAiForms: BeautyAiForm[];
  mainCategories: MainCategory[];
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  const [subCategories, setSubCategories] = useState([]);
  const [leafCategories, setLeafCategories] = useState([]);
  const [editImage, setEditImage] = useState(props.beautyAi.image?.url == null);
  const [aiForms, setAiForms] = useState<BeautyAiForm[]>(props.beautyAiForms);
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [selectedModel, setSelectedModel] = useState<NovitaModel>();
  const [civitaiModelModalOpen, setCivitaiModelModalOpen] =
    useState<boolean>(false);
  const [civitaiLoraModalOpen, setCivitaiLoraModalOpen] =
    useState<boolean>(false);

  // 画像ごとのダウンロード状態を管理するステート
  const [downloadingStatus, setDownloadingStatus] = useState<{
    [url: string]: boolean;
  }>({});

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.beautyAi.name,
      intro: props.beautyAi.intro,
      image: "",
      mainCategorySlug: props.beautyAi.mainCategorySlug,
      subCategorySlug: props.beautyAi.subCategorySlug,
      leafCategorySlug: props.beautyAi.leafCategorySlug,
      civitaiModelName: props.beautyAi.civitaiModelName,
      prompt: props.beautyAi.prompt,
      negativePrompt: props.beautyAi.negativePrompt,
      orderIdx: props.beautyAi.orderIdx,
      status: props.beautyAi.status,
      slug: props.beautyAi.slug,
    },
  });

  useEffect(() => {
    if (props.beautyAi.mainCategorySlug != null) {
      adminsGetSubCategories({
        mainCategorySlug: props.beautyAi.mainCategorySlug,
      }).then(({ data }) => {
        setSubCategories(data);
      });
    }
  }, [props.beautyAi.mainCategorySlug]);

  useEffect(() => {
    if (props.beautyAi.subCategorySlug != null) {
      adminsGetLeafCategories({
        subCategorySlug: props.beautyAi.subCategorySlug,
      }).then(({ data }) => {
        setLeafCategories(data);
      });
    }
  }, [props.beautyAi.subCategorySlug]);

  const onSubmit = async (data) => {
    console.log("data", data);
    const formData = new FormData();
    formData.append("beauty_ai[name]", data.name || "");
    formData.append("beauty_ai[intro]", data.intro || "");
    if (data.image != undefined && data.image) {
      formData.append("beauty_ai[image]", data.image);
    }

    formData.append("beauty_ai[mainCategorySlug]", data.mainCategorySlug || "");
    formData.append("beauty_ai[subCategorySlug]", data.subCategorySlug || "");
    formData.append("beauty_ai[leafCategorySlug]", data.leafCategorySlug || "");
    formData.append("beauty_ai[prompt]", data.prompt || "");
    formData.append("beauty_ai[negative_prompt]", data.negative_prompt || "");
    formData.append("beauty_ai[orderIdx]", data.orderIdx || "");
    formData.append("beauty_ai[status]", data.status || "");

    if (data.slug != null) {
      formData.append("beauty_ai[slug]", data.slug || "");
    }
    try {
      if (props.beautyAi?.id != null) {
        await adminUpdateBeautyAi(props.beautyAi.slug, formData);
        toastSuccess("更新しました");
      } else {
        await adminCreateBeautyAi(formData);
        toastSuccess("作成しました");
      }
    } catch (error) {
      let errorMessage = error.response.data.errors.join(", ");
      toast.error(`更新に失敗しました。${errorMessage}`);
      console.error(error);
    }
  };
  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleFileChange = (event) => {
    setValue("image", event.target.files[0]);
  };

  const onChangeMainCategory = async (v: string) => {
    setSubCategories([]);
    setLeafCategories([]);
    let { data } = await adminsGetSubCategories({
      mainCategorySlug: v,
      kind: "beauty",
    });
    setSubCategories(data);
  };

  const onChangeSubCategory = async (v: string) => {
    setLeafCategories([]);
    let { data } = await adminsGetLeafCategories({
      subCategorySlug: v,
    });
    setLeafCategories(data);
  };

  const onSubmitPromptSettingForm = async (data: any) => {};

  const removeAiForm = (aiForm: BeautyAiForm, i: number) => {
    setAiForms((prevForms) => {
      return prevForms.filter((prevForm: BeautyAiForm, j: number) => {
        if (i != j) {
          return prevForm;
        }
      });
    });
    setDeletedIds([...deletedIds, aiForm.id]);
  };

  const updateAiForm = (aiForm: BeautyAiForm, i: number) => {
    setAiForms((prevForms) => {
      return prevForms.map((prevForm: BeautyAiForm, j: number) => {
        if (i == j) {
          return aiForm;
        } else {
          return prevForm;
        }
      });
    });
  };

  const moveUp = (index) => {
    console.log("moveUp", index);
    if (index === 0) return;
    const newData = [...aiForms];
    console.log("newData", newData);
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    console.log("newData", newData);
    setAiForms(newData);
  };

  const moveDown = (index) => {
    if (index === aiForms.length - 1) return;
    const newData = [...aiForms];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setAiForms(newData);
  };

  const newAiForm: BeautyAiForm = {
    id: "",
    kind: `input_text`,
    inputName: `name${aiForms.length}`,
    label: "",
    options: [{ name: "", value: "" }],
    helpText: "",
    col: "6",
  };

  const addAiForms = () => {
    setAiForms([...aiForms, newAiForm]);
  };

  const submit = async () => {
    toastSuccess("更新しました");
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  const downloadImage = async (url) => {
    setDownloadingStatus((prev) => ({ ...prev, [url]: true })); // ダウンロード開始

    try {
      const encodedUrl = encodeURIComponent(url);
      await saveAs(`/api/v1/users/proxies?url=${encodedUrl}`);
    } catch (error) {
      console.error("Error during image download", error);
    } finally {
      setTimeout(() => {
        setDownloadingStatus((prev) => ({ ...prev, [url]: false }));
      }, 5000);
    }
  };

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md border">
        <form className="simple_form edit_ai" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6">
            <div>
              <div className="mb-4 string required ai_name">
                <label
                  className="block string required text-sm font-medium text-gray-600"
                  htmlFor="ai_name"
                >
                  名称
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
                  type="text"
                  id="ai_name"
                  {...register("name", { required: true })}
                />
                {errors.name?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            <div>
              {editImage ? (
                <>
                  <div className="mb-4 file required ai_image">
                    <label
                      className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                      htmlFor="ai_image"
                    >
                      アイコン画像
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        *必須
                      </span>
                    </label>
                    <div className="grid grid-cols-12">
                      <input
                        className="col-span-10 w-full text-gray-500 border rounded file required"
                        type="file"
                        name="ai[image]"
                        id="ai_image"
                        onChange={handleFileChange}
                      />
                      <button
                        type="button"
                        className="ml-2 w-24 rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setEditImage(false)}
                      >
                        キャンセル
                      </button>
                    </div>
                    {errors.image?.type === "required" && (
                      <p className="font-semibold text-sm text-red-500">
                        入力してください
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label
                    className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                    htmlFor="ai_image"
                  >
                    アイコン画像
                  </label>
                  <div className="flex mt-1 items-center mb-4">
                    <img
                      src={props.beautyAi.image.url}
                      className="w-20 mr-2 flex-shrink-0 rounded-full bg-gray-300"
                    />
                    <button
                      type="button"
                      className="ml-2 w-24 rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setEditImage(true)}
                    >
                      変更
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className="mb-4 text optional ai_intro">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_intro"
                >
                  説明文
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_intro"
                  {...register("intro")}
                ></textarea>
              </div>
            </div>
            <div>
              <div className="mb-4 select required ai_main_category">
                <label
                  className="block select required text-sm font-medium text-gray-600"
                  htmlFor="ai_main_category_id"
                >
                  大カテゴリ
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
                  id="ai_main_category_id"
                  {...register("mainCategorySlug", { required: true })}
                  onChange={(e) => onChangeMainCategory(e.target.value)}
                >
                  {props.mainCategories.map((c, i) => {
                    return (
                      <option key={`main-category-${i}`} value={c.slug}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
                {errors.mainCategorySlug?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            {subCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_sub_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_sub_category_id"
                  >
                    中カテゴリ
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_sub_category_id"
                    {...register("subCategorySlug")}
                    onChange={(e) => onChangeSubCategory(e.target.value)}
                  >
                    {subCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`sub-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            {leafCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_leaf_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_leaf_category_id"
                  ></label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_leaf_category_id"
                    {...register("leafCategorySlug")}
                  >
                    {leafCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`leaf-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            <div>
              <div className="mb-4 string required ai_name">
                <label
                  className="block string required text-sm font-medium text-gray-600"
                  htmlFor="ai_name"
                >
                  モデル
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <div className="flex mt-1">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 string required"
                    type="text"
                    disabled={true}
                    id="ai_civitai_model_name"
                    {...register("civitaiModelName", { required: true })}
                  />
                  <button
                    type="button"
                    className="ml-2 w-24 rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setCivitaiModelModalOpen(true)}
                  >
                    変更する
                  </button>
                </div>
                {errors.name?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            <div>
              <div className="mb-4 text optional ai_prompt">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_prompt"
                >
                  プロンプト
                </label>
                <div className="p-3 bg-gray-100 border mb-2 rounded-md">
                  <h2 className="text-sm">置換ルール</h2>
                  <div className="flex flex-wrap">
                    {props.beautyAiForms.map((aiForm, i) => {
                      return (
                        <div
                          className="text-xs mt-1 text-gray-600 mr-2"
                          key={`ai-form-${i}`}
                        >
                          {aiForm.label}: %{aiForm.inputName}%
                        </div>
                      );
                    })}
                  </div>
                </div>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_prompt"
                  {...register("prompt")}
                ></textarea>
              </div>
            </div>

            <div>
              <div className="mb-4 text optional ai_prompt">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_prompt"
                >
                  ネガティブプロンプト
                </label>
                <div className="p-3 bg-gray-100 border mb-2 rounded-md">
                  <h2 className="text-sm">置換ルール</h2>
                  <div className="flex flex-wrap">
                    {props.beautyAiForms.map((aiForm, i) => {
                      return (
                        <div
                          className="text-xs mt-1 text-gray-600 mr-2"
                          key={`ai-form-${i}`}
                        >
                          {aiForm.label}: %{aiForm.inputName}%
                        </div>
                      );
                    })}
                  </div>
                </div>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_negative_prompt"
                  {...register("negativePrompt")}
                ></textarea>
              </div>
            </div>

            <div>
              <div className="mb-4 integer optional ai_order_idx">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_order_idx"
                >
                  並び順
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_order_idx"
                  {...register(`orderIdx`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 enum optional ai_status">
                <label
                  className="block enum optional text-sm font-medium text-gray-600"
                  htmlFor="ai_status"
                >
                  公開ステータス
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   enum optional form-control"
                  id="ai_status"
                  {...register(`status`)}
                >
                  <option value="draft">下書き</option>
                  <option value="published">公開済み</option>
                </select>
              </div>
            </div>

            <div>
              <div className="mb-4 string optional ai_slug">
                <label
                  className="block string optional text-sm font-medium text-gray-600"
                  htmlFor="ai_slug"
                >
                  スラグ
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
                  type="text"
                  id="ai_slug"
                  {...register(`slug`)}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <input
              type="submit"
              name="commit"
              value="送信する"
              className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            />
          </div>
        </form>
      </div>
      {props.beautyAi.id != null && (
        <>
          <div className="shadow sm:overflow-hidden sm:rounded-md border mt-10">
            <div className="space-y-10 bg-white px-4 py-5 sm:p-6">
              <h2 className="text-xl font-bold">Lora設定</h2>
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="rounded-md bg-white py-2 px-3 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => setCivitaiLoraModalOpen(true)}
                >
                  + 追加
                </button>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={submit}
              >
                保存する
              </button>
            </div>
          </div>
          <div className="shadow sm:overflow-hidden sm:rounded-md border mt-10">
            <div className="space-y-10 bg-white px-4 py-5 sm:p-6">
              <h2 className="text-xl font-bold">Form設定</h2>
              {aiForms.map((aiForm, i) => {
                return (
                  <div key={`ai-form-${aiForm.id}`} className="">
                    <FormRow
                      aiForm={aiForm}
                      updateAiForm={(aiForm) => updateAiForm(aiForm, i)}
                      removeAiForm={() => removeAiForm(aiForm, i)}
                      index={i}
                      length={aiForms.length}
                      moveUp={moveUp}
                      moveDown={moveDown}
                    />
                  </div>
                );
              })}
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="rounded-md bg-white py-2 px-3 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={addAiForms}
                >
                  + 追加
                </button>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={submit}
              >
                保存する
              </button>
            </div>
          </div>

          <div className="py-3 mt-5">
            <h2 className="text-xl font-bold">Preview</h2>
          </div>
          <div className="shadow sm:overflow-hidden sm:roudned-md border">
            {/*
            <PromptSettingForm
              submit={(datas) => onSubmitPromptSettingForm(datas)}
              imageAiForms={imageAiForms}
              streaming={streaming}
              cancelStreaming={cancelStreaming}
              remainGenerateCap={100}
            />
            */}
          </div>

          <div className="col-span-12">
            <>
              <div className="py-3">
                <h2 className="text-xl font-bold">出力結果</h2>
              </div>
            </>
          </div>
        </>
      )}
      <CivitaiModel
        open={civitaiModelModalOpen}
        setOpen={setCivitaiModelModalOpen}
        onSelectImage={(model) => {
          setSelectedModel(model);
          //todo ここで civitaiModelName の値を更新
          setValue("civitaiModelName", model.sdNameInApi, {
            shouldValidate: true,
          });
          // Close the modal
          setCivitaiModelModalOpen(false);
        }}
      />
      <CivitaiModel
        open={civitaiLoraModalOpen}
        setOpen={setCivitaiLoraModalOpen}
        onSelectImage={(model) => {
          //ここでlorasに追加
          console.log("model", model);
          setCivitaiModelModalOpen(false);
        }}
        lora={true}
      />
    </>
  );
};
export default AdminsAisEdit;
