import getClient from "./client";
import { GetAisParams, PaginateAisParams } from "../../interfaces/requests/ai";
export const getAis = async (params: GetAisParams) => {
  const response = await getClient().get(`/ais.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};

export const paginateAis = async (params: PaginateAisParams) => {
  const response = await getClient().get(`/ais/paginate.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};
