import React, { useState } from "react";

export default function Nanisuru() {
  return (
    <>
      <h1 className="text-xl sm:text-md font-semibold text-gray-900 mt-8 mb-3">
        本日はどの作業をお手伝いしますか？
      </h1>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 my-4">
        <a
          href="/users/image_ais"
          className="grid grid-cols-12 gap-4 p-4 border shadow-md rounded-xl"
        >
          <img
            className="col-span-4 flex-shrink-0 rounded-md bg-gray-300"
            src="https://ordermaid.media/wp-content/uploads/2024/01/download-2024-01-14T074241.297.png"
            alt="AI画像生成したい"
          />
          <div className="col-span-8">
            <div className="flex items-center space-x-0">
              <h3 className="text-lg font-semibold text-gray-900">
                AI画像生成したい
              </h3>
            </div>
            <p className="mt-1 sm:mt-3 text-[10px] sm:text-sm text-gray-500">
              日本語でキーワードを入力するだけで高クオリティの画像が一瞬で生成できます。
              <br className="hidden sm:block" />
              操作は感覚的で超カンタン。無料で使えるので一度体験してみてください。
            </p>
          </div>
        </a>

        <a
          href="/users/ais"
          className="grid grid-cols-12 gap-4 p-4 border shadow-md rounded-xl"
        >
          <img
            className="col-span-4 flex-shrink-0 rounded-md bg-gray-300"
            src="https://ordermaid.media/wp-content/uploads/2024/01/download-2024-01-14T075622.057.png"
            alt="AIテキスト生成したい"
          />
          <div className="col-span-8">
            <div className="flex items-center space-x-0">
              <h3 className="text-lg font-semibold text-gray-900">
                AIテキスト生成したい
              </h3>
            </div>
            <p className="mt-1 sm:mt-3 text-[10px] sm:text-sm text-gray-500">
              テキストAIワーカーがあなたのブログや動画台本を爆速で生成！
              <br className="hidden sm:block" />
              従来のコンテンツ制作にかかっていた時間を大幅に短縮します。
            </p>
          </div>
        </a>
      </div>
    </>
  );
}
