import getClient from "../client";

export const adminUpdateBeautyAiPrompt = async (
  slug: string,
  prompt: string,
  negative_prompt: string
) => {
  return getClient().post(`/admins/beauty_ais/${slug}/update_prompt.json`, {
    prompt: prompt,
    negative_prompt: negative_prompt,
  });
};

export const adminCloneBeautyAi = async (slug: string) => {
  return getClient().post(`/admins/beauty_ais/${slug}/clone.json`);
};

export const adminCreateBeautyAi = async (params) => {
  return getClient().post(`/admins/beauty_ais.json`, params);
};

export const adminUpdateBeautyAi = async (slug: string, params) => {
  return getClient().put(`/admins/beauty_ais/${slug}.json`, params);
};
