import React, { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { isSp } from "../../../libs/util";
type Props = {
  onEndVideo?: (e: any) => void;
  vimeoId: string;
  setPercent: (n: number) => void;
  setDuration: (n: number) => void;
  setSeconds: (n: number) => void;
};
const MovieVimeo: React.FC<Props> = (props) => {
  return (
    <Vimeo
      video={props.vimeoId}
      autoplay
      muted={isSp()}
      onEnd={(e) => {
        props.onEndVideo && props.onEndVideo(e);
      }}
      controls={false}
      onTimeUpdate={(e) => {
        props.setPercent(e.percent);
        props.setDuration(e.duration);
        props.setSeconds(e.seconds);
      }}
    />
  );
};
export default MovieVimeo;
