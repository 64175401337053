import React from "react";
import AdminsAiCloneLink from "@/components/pages/vendors/ais/CloneLink";

type Props = {
  editPath: string;
  deletePath: string;
  slug: string;
};

const Actions: React.FC<Props> = (props) => {

  const deleteConfirm = (event: React.MouseEvent<HTMLElement>) => {
    const result = window.confirm('削除しますか？');
    if (!result) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <div>
        <a
          className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" 
          href={props.editPath}
        >
          編集
        </a>
        <AdminsAiCloneLink slug={props.slug}></AdminsAiCloneLink>
      </div>
      <div>
        <a
          className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" 
          onClick={deleteConfirm} rel="nofollow" data-method="delete" href={props.deletePath}
        >
          削除
        </a>
      </div>
    </>
  );
};

export default Actions;
