import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { User } from "../../../interfaces/user";
import { signOut } from "../../../libs/api/user";
import Rails from "@rails/ujs";

type Props = {
  user: User;
  open: boolean;
  setOpen: (b: boolean) => void;
};

const DeviceAlertModal: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.open) {
      Rails.delegate(
        document.body,
        "a[data-method]",
        "click",
        Rails.handleMethod
      );
    }
  }, [props.open]);

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            return false;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {props.user.email}{" "}
                        のアカウントで他の端末での操作を検知しました。
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          <strong>1アカウント1端末まで</strong>
                          のご利用をお願いしています。
                          <br />
                          以下のボタンをクリックしてログアウトしてください
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-col">
                    <button
                      className="bg-gradient-to-r text-center from-blue-500 to-purple-600 font-bold text-white py-3 w-full rounded-full mb-4 hover:opacity-80"
                      onClick={async () => {
                        await signOut();
                        window.location.reload();
                      }}
                    >
                      ログアウト
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default DeviceAlertModal;
