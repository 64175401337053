import React, { useState } from "react";
import { Invoice } from "../../../../interfaces/invoice";
import { User } from "../../../../interfaces/user";
import { toast } from "react-toastify";
import AffiliateTable from "../../../users/affiliates/Table";
import EmptyBankAccountAlert from "../../../partials/modals/EmptyBankAccountAlert";
import { fixAffiliateFee } from "../../../../libs/api/users/affiliate";
type Props = {
  invoices: Invoice[];
  user: User;
};

const UsersAffiliatesIndex: React.FC<Props> = (props) => {
  const [showEmptyBankModal, setShowEmptyBankModal] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>(props.invoices);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [hasBankAccount, setHasBankAccount] = useState(
    props.user.bankAccount != null
  );
  const onSubmit = async (selectedInvoices) => {
    if (hasBankAccount) {
      const response = await fixAffiliateFee({
        invoice_ids: selectedInvoices.map((invoice) => invoice.id),
      });
      const updatedInvoices = response.data;

      // 現在のinvoicesを更新
      const newInvoices = invoices.map((invoice) => {
        const updatedInvoice = updatedInvoices.find(
          (ui) => ui.id === invoice.id
        );
        if (updatedInvoice) {
          return updatedInvoice;
        }
        return invoice;
      });

      setInvoices(newInvoices);
      toast.success("申請しました");
      setSelectedInvoices([]);
    } else {
      setShowEmptyBankModal(true);
    }
  };
  return (
    <>
      <AffiliateTable
        invoices={invoices}
        onSubmit={onSubmit}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
      />
      <EmptyBankAccountAlert
        open={showEmptyBankModal}
        setOpen={setShowEmptyBankModal}
        bankAccount={props.user.bankAccount}
        onSubmit={() => {
          setShowEmptyBankModal(false);
          setHasBankAccount(true);
        }}
      />
    </>
  );
};
export default UsersAffiliatesIndex;
