import React, { useEffect, useState } from "react";
import { UserSavedText } from "../../../interfaces/user_saved_text";
import { useForm } from "react-hook-form";
import {
  updateUserSavedText,
  destroyUserSavedText,
} from "../../../libs/api/users/user_saved_text";
import { toast } from "react-toastify";

type Props = {
  savedText: UserSavedText;
  delSavedTexts: (slug: string) => void;
};
const ListSavedText: React.FC<Props> = (props) => {
  const [savedText, setSavedText] = useState(props.savedText);
  const [editMode, setEditMode] = useState(false);

  const handleAnchorClick = (e) => {
    console.log("handleAnchorClick");
    e.preventDefault();
    location.href = `/users/user_saved_texts/${savedText.slug}`;
  };

  const handleEditClick = (e) => {
    console.log("handleEditClick");
    e.preventDefault();
    e.stopPropagation();
    setEditMode(true);
  };

  const handleDelClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (confirm("削除します、よろしいですか？")) {
      await destroyUserSavedText(props.savedText.slug);
      toast.success("削除しました");
      props.delSavedTexts(savedText.slug);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.savedText.name,
    },
  });

  const onSubmit = async (params) => {
    let { data } = await updateUserSavedText(props.savedText.slug, params);
    toast.success("更新しました");
    setEditMode(false);
    setSavedText(data);
  };

  return (
    <li>
      {editMode ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-2 flex rounded-md">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  className="block text-xs w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder={props.savedText.name}
                  {...register("name", { required: true })}
                />
              </div>
              <button
                type="submit"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <i className="fa-solid fa-floppy-disk"></i>
              </button>
              <button
                type="button"
                className="ml-2 text-xs"
                onClick={() => setEditMode(false)}
              >
                <i className="fa-solid fa-x"></i>
              </button>
            </div>
            {errors.name?.type === "required" && (
              <p className="px-2 font-semibold text-xs text-red-500">
                入力してください
              </p>
            )}
          </form>
        </>
      ) : (
        <>
          <a
            href="#"
            onClick={handleAnchorClick}
            className="flex items-between w-full px-2 py-1 text-md text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 text-sm"
          >
            <div className="w-full">
              <h3>{savedText.name}</h3>
              <p className="text-xs text-gray-400">{props.savedText.aiName}</p>
            </div>
            <div className="w-12">
              <div className="opacity-0 group-hover:opacity-100 h-full items-center flex justify-center">
                <button onClick={handleEditClick}>
                  <i className="fas fa-edit text-md text-gray-400 z-50 mr-2" />
                </button>
                <button onClick={handleDelClick}>
                  <i className="fas fa-trash text-md text-gray-400 z-40" />
                </button>
              </div>
            </div>
          </a>
        </>
      )}
    </li>
  );
};

export default ListSavedText;
