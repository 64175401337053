import React, { useRef, useMemo, useEffect, useState } from "react";
import Header from "@/components/biz/users/ais/chat/Header";
import AiMessage from "@/components/biz/users/ais/chat/messages/Ai";
import PromptSettingForm from "@/components/biz/users/ais/chat/PromptSettingForm";
import GenerateLog from "@/components/biz/users/ais/GenerateLog";
import { GenerateLogItemProps } from "@/components/biz/users/ais/GenerateLogItem";

import { Ai } from "@/interfaces/ai";
import { UsersAi, FormNameAndValue } from "@/interfaces/users_ai";
import { User } from "@/interfaces/user";
import { AiForm } from "@/interfaces/ai_form";

import { createConsumer } from "@rails/actioncable";
import { toggleUserFavorite } from "@/libs/api/users/user_favorite_ai";
import SaveGeneratedText from "@/components/biz/partials/modals/SaveGeneratedText";
import { usersTextGenerateHistories, usersOthersTextGenerateHistories } from "@/libs/api/users/users_ai";
import { usersTextGenerateDetail } from "@/libs/api/biz/users/users_ai";
import { BizGenerationResult } from "@/interfaces/biz/users_ai";
import { UploadedFile } from "@/components/users/ais/forms/InputFile";
import { uploadFile, UploadFileResponse } from "@/libs/api/biz/users/users_ai";
import { AxiosResponse } from "axios";
import Loading from "../../../Loading";
import { toast } from "react-toastify";
import { FieldValues } from "react-hook-form";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  cableEndpoint: string;
  user: User;
  favorites: Ai[];
  adCount: number;
  // generateLogのPropsを渡す
  generateLogProps: GenerateLogItemProps[];
};

const UsersAisShow: React.FC<Props> = (props) => {
  const [subscription, setSubscription] = useState<any>(null);
  const [message, setMessage] = useState("");

  const [generationResult, setGenerationResult] = useState<BizGenerationResult>(null);

  const [streaming, setStreaming] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);
  const [editorBody, setEditorBody] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [retrievedReferences, setRetrievedReferences] = useState<Array<String>>([]);
  const [generateLog, setGenerateLog] = useState<
    { date: string; usersAis: UsersAi[] }[]
  >([]);
  const [othersGenerateLog, setOthersGenerateLog] = useState<
    { date: string; usersAis: UsersAi[] }[]
  >([]);
  const [formNameAndValues, setFormNameAndValues] = useState<
    FormNameAndValue[]
  >([]);

  const freeSpeedLimitRef = useRef<HTMLDivElement>(null);

  const [currentGenerateCount, setCurrentGenerateCount] = useState(
    props.user.currentGenerateCount
  );
  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const [channelToken, setChannelToken] = useState(generateRandomString(20));
  const [formToken, setFormToken] = useState("");

  const [nowLoading, setNowLoading] = useState(false);

  const addFavorite = async (ai: Ai) => {
    setFavorites((prevFavorites) => [...prevFavorites, ai]);
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("addFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
  };

  const removeFavorite = async (ai: Ai) => {
    setFavorites((prevFavorites) =>
      prevFavorites.filter((faAi) => faAi.id !== ai.id)
    );
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("removeFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
  };

  const messageBottomRef = useRef(null);

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription != null) {
      subscription.perform("stop", {
        token: formToken,
      });
    }
  };

  const cable = useMemo(() => createConsumer(), []);

  // channelTokenが変更されたときにサブスクリプションを作成
  useEffect(() => {
    const sub = cable.subscriptions.create(
      { channel: "Biz::PrivateChatChannel", token: channelToken },
      {
        connected: () => {
          // コネクションが確立したことをここで処理
          console.log("ws connected");
        },
        received: (res) => {
          // メッセージ受信時の処理
          if (res.error) {
            alert(res.error);
            setMessage(
              (prev) => prev + "申し訳ございません、問題が発生しました"
            );
            if (res?.errorCode == "upgrade") {
              location.reload();
            }
          } else if (res.status) {
            switch(res.status) {
              case "finish":
                setStreaming(false);

                if (res?.generationResult.retrievedReferences != null ) {
                  setRetrievedReferences(res?.generationResult.retrievedReferences);
                }

                setGenerationResult(res.generationResult.data);

                usersTextGenerateHistories(props.ai.slug).then((res) => {
                  setGenerateLog(res.data);
                });
                break;
              case "reset":
                setMessage("");
                break;
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data);
            }
          }
        }
      },
    );
    setSubscription(sub);

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [channelToken, cable]);

  /**
   * @param {FieldValues} data
   * @param {string} _token フォームトークン
   * @param {boolean} privateFlag 非公開フラグ
   */
  const submitForm = (data: FieldValues, _token: string, privateFlag: boolean) => {
    setShowMessage(true);

    // 生成結果表示コンポーネントは generation result がセットされていないと何も表示されない仕様になっているため
    // いったん ID なしの仮の generation result をセットしておく
    //
    // 生成完了後にはサーバーから返ってくる generation result を改めてセットする
    setGenerationResult({
      id: null,
      userId: Number(props.user.id),
      private: privateFlag
    });

    setCurrentGenerateCount(currentGenerateCount + 1);
    const event = new CustomEvent("incrementGenerateCount", {
      detail: currentGenerateCount + 1,
    });
    window.dispatchEvent(event);

    setStreaming(true);

    if (subscription) {
      subscription.perform("stream_message", {
        ai_slug: props.ai.slug,
        form_data: data,
        private: privateFlag,
        token: _token,
        user_token: props.user.token,
      });
    }
    setMessage("");
  };

  const onPromptSettingFormSubmit = async (data: FieldValues, uploadedFiles: UploadedFile[], privateFlag: boolean) => {
    // フォームトークンを入力ファイルの保存ディレクトリ名として使用するので、衝突の可能性を排除するためUUIDを使うように変更する
    // ただし secure context でない開発環境においては randomUUID が使えないため、従来通りのランダム文字列生成関数を使用する
    //
    // 将来的には開発環境も secure context になるようにしたい & channel token もUUIDを使うようにしたい
    const _token = crypto.randomUUID === undefined ? generateRandomString(20) : crypto.randomUUID();
    setFormToken(_token);

    if (uploadedFiles.length >= 1) {
      setNowLoading(true);

      const promises: Promise<AxiosResponse<UploadFileResponse>>[] = uploadedFiles.map((uploadedFile) => {
        return uploadFile(props.ai.slug, uploadedFile.inputName, uploadedFile.file, _token)
      });

      // ファイルが全てアップロード完了するのを待ってから submitForm を実行する
      Promise.all(promises).then((responses) => {
        responses.forEach((response) => {
          switch(response.data.status) {
            case "error":
              toast.error(response.data.message);
              break;
            case "ok":
              // アップロードされたファイルを判別するため FormData に inputName = fileIdentifier を追加しておく
              data[response.data.inputName] = response.data.fileIdentifier;
              submitForm(data, _token, privateFlag);
              break;
            default:
              alert("不明なエラーが発生しました");
              break;
          }

          setNowLoading(false);
        });
      });
    }
    else {
      submitForm(data, _token, privateFlag);
    }
  };

  const addEditorBody = (s) => {
    //console.log("addEditorBody", editorBody + "\n" + s);
    const formattedString = (editorBody + "\n" + s).replace(/\n/g, "<br />");
    setEditorBody(formattedString);
  };

  const onClickSideTitle = async (usersAi: UsersAi) => {
    let { data } = await usersTextGenerateDetail(props.ai.slug, usersAi.id);
    setMessage(data.generatedText);
    setGenerationResult(data.bizGenerationResult);
    setFormNameAndValues(data.formNameAndValues);
    setRetrievedReferences(data.retrievedReferences);
    setShowMessage(true);
    setFormToken(data.formToken);
  };

  useEffect(() => {
    usersTextGenerateHistories(props.ai.slug).then((res) => {
      setGenerateLog(res.data);
    });
    usersOthersTextGenerateHistories(props.ai.slug).then((res) => {
      setOthersGenerateLog(res.data);
    });
  }, []);

  // useEffect を用いて subscription のクリーンアップを行う
  useEffect(() => {
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [subscription]);

  return (
    <>
      <Loading flag={nowLoading} />
      <div className="h-full">
        <div
          className="flex-1 flex flex-col h-full"
          style={{ minHeight: `calc(100vh - 56px - 84px)` }}
        >
          <Header
            ai={props.ai}
            favorites={favorites}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
          />

          <div className="flex h-full grow">
            <div className="flex grow">
              {/* 入力フォーム */}
              <div className="flex basis-6/12 flex-col py-4 md:px-4 pt-10 bg-gray-100">
                <div className="items-start space-x-2 mb-4 sm:mb-0">
                  <div className="flex flex-col leading-tight">
                    <p className="mt-1 text-gray-900">
                      以下の情報を記入したあとに、生成ボタンを押してください。
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <PromptSettingForm
                    submit={(data, uploadedFiles, privateFlag) => onPromptSettingFormSubmit(data, uploadedFiles, privateFlag)}
                    aiForms={props.aiForms}
                    streaming={streaming}
                    cancelStreaming={cancelStreaming}
                    user={props.user}
                    formNameAndValues={formNameAndValues}
                  />
                </div>
              </div>

              {/* 生成結果 */}
              <div className="p-4 basis-6/12">
                <div ref={freeSpeedLimitRef} />
                <div className="flex flex-col py-5 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch h-full">
                  {showMessage && (
                    <AiMessage
                      ai={props.ai}
                      message={message}
                      generationResult={generationResult}
                      retrievedReferences={retrievedReferences}
                      addEditorBody={addEditorBody}
                      showCounter={true}
                      showAction={true}
                      user={props.user}
                      streaming={streaming}
                      openSave={openSave}
                      openUpgrade={openUpgrade}
                      setOpenSave={setOpenSave}
                      setOpenUpgrade={setOpenUpgrade}
                      setMessage={setMessage}
                      formToken={formToken}
                    />
                  )}
                  <div ref={messageBottomRef}></div>
                </div>
              </div>
            </div>

            {/* 生成履歴 */}
            <div className="flex grow min-w-[250  px] max-w-[250px] shrink-0">
              <GenerateLog
                generateLog={generateLog}
                othersGenerateLog={othersGenerateLog}
                onClickSideTitle={onClickSideTitle}
              />
            </div>
          </div>
        </div>

        <SaveGeneratedText
          text={editorBody}
          open={openSave}
          setOpen={setOpenSave}
          ai={props.ai}
        />
      </div>
    </>
  );
};
export default UsersAisShow;
