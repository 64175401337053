import React, { useState } from "react";

import { UserGenImg } from "../../../../interfaces/user_gen_img";
import ImageCard from "../../../users/user_gen_imgs/ImageCard";
import AffiliateGuide from "../../../../components/partials/modals/AffiliateGuide";

type Props = {
  images: UserGenImg[];
};

const UsersImageAisIndex: React.FC<Props> = (props) => {
  const [guideModalOpen, setGuideModalOpen] = useState(false);

  return (
    <>
      <div className="lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-4 grid gap-3">
        {props.images.map((image, i) => (
          <ImageCard
            image={image}
            imageAi={image.imageAi}
            setGuideModalOpen={setGuideModalOpen}
            historyMode={true}
            key={`image-card-${i}`}
          />
        ))}
      </div>
      <AffiliateGuide
        open={guideModalOpen}
        setOpen={setGuideModalOpen}
        images={["/img/image_ai_afi.png"]}
      />
    </>
  );
};
export default UsersImageAisIndex;
