import React, { useState, useEffect } from "react";
import { User } from "../../../interfaces/user";
import { UserPlan } from "../../../interfaces/user_plan";

type Props = {
  filterPlans: (interval: string, intervalCount: number) => void;
  user: User;
  userPlan: UserPlan;
  serviceType: string;
};

const UsersPlansTable: React.FC<Props> = (props) => {
  const [interval, setInterval] = useState(props.userPlan?.plan?.interval);
  const [intervalCount, setIntervalCount] = useState(
    props.userPlan?.plan?.intervalCount
  );
  const [planType, setPlanType] = useState("annual");

  useEffect(() => {
    let type = "annual";
    if (interval === "year") {
      type = "annual";
    } else if (interval === "month") {
      if (intervalCount === 6) {
        type = "halfYear";
      } else if (intervalCount === 1) {
        type = "month";
      }
    }
    setPlanType(type);
  }, [interval, intervalCount]);

  const handlePlanChange = (int: string, intCount: number) => {
    setInterval(int);
    setIntervalCount(intCount);
    props.filterPlans(int, intCount);
  };

  return (
    <>
      <div
        className="flex space-x-1 rounded-lg bg-slate-100 px-8 py-2"
        role="tablist"
        aria-orientation="horizontal"
      >
        <PlanButton
          isSelected={planType === "annual"}
          onClick={() => handlePlanChange("year", 1)}
          discountText="40%OFF"
          planText="年間払い"
          serviceType={props.serviceType}
        />
        <PlanButton
          isSelected={planType === "halfYear"}
          onClick={() => handlePlanChange("month", 6)}
          discountText="20%OFF"
          planText="半年払い"
          serviceType={props.serviceType}
        />
        <PlanButton
          isSelected={planType === "month"}
          onClick={() => handlePlanChange("month", 1)}
          planText="月払い"
          serviceType={props.serviceType}
        />
      </div>
    </>
  );
};

const PlanButton = ({
  isSelected,
  onClick,
  discountText = "",
  planText,
  serviceType,
}) => {
  const getDiscountBgColor = () => {
    if (serviceType === "whisper") {
      return "bg-green-500";
    } else if (serviceType === "video") {
      return "bg-rose-500";
    } else if (serviceType === "voice") {
      return "bg-cyan-500";
    } else {
      return "bg-blue-500";
    }
  };

  return (
    <button
      className={`flex items-center rounded-md py-[0.4375rem] pr-2 pl-2 text-sm font-semibold lg:pr-3 ${
        isSelected ? "bg-white shadow" : ""
      }`}
      role="tab"
      type="button"
      aria-selected={isSelected}
      onClick={onClick}
    >
      {discountText && (
        <p
          className={`rounded-sm pr-1 px-1 py-0.5 text-xs font-semibold text-white ${getDiscountBgColor()}`}
        >
          {discountText}
        </p>
      )}
      <span
        className={`ml-2 ${isSelected ? "text-slate-900" : "text-slate-600"}`}
      >
        {planText}
      </span>
    </button>
  );
};

export default UsersPlansTable;
