import React from "react";
import PaginationButton from "./PaginationButton";

type Props = {
  page: number | string;
  isCurrent: boolean;
  onClick: () => void;
};

const PaginationPage: React.FC<Props> = ({ page, isCurrent, onClick }) => {
  if (typeof page === "string") {
    return <p className="px-3 py-2 ml-0 leading-tight text-gray-300 bg-white border border-gray-300">...</p>;
  }

  return isCurrent ? (
    <p className="px-3 py-2 ml-0 leading-tight text-gray-300 bg-white border border-gray-300">{page}</p>
  ) : (
    <PaginationButton label={page.toString()} onClick={onClick} />
  );
};

export default PaginationPage;
