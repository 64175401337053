import getClient from "../client";
import { GetSubCategoriesParams } from "../../../interfaces/requests/sub_category";

/**
 * サブカテゴリー一覧を取得
 */
export const vendorsGetSubCategories = async (
  params: GetSubCategoriesParams
) => {
  return getClient().get(`/vendors/sub_categories.json`, {
    params: params,
  });
};
