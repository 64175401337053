import React from "react";
import {Ai} from "@/interfaces/ai";
import {AiCardImage, AiCardTitle, AiCardIntro} from "./AiCardCommon";
import FavoriteButton from "@/components/biz/partials/favorite/FavoriteButton";
import {New} from "@/components/biz/partials/New";

type Props = {
  ai: Ai;
  showBadge?: boolean;
  showFavorite?: boolean;
};

const StandardCard: React.FC<Props> = ({ai, showBadge = false, showFavorite = false}) => {
  return (
    <div className="relative h-[188px] max-w-[100%]">
      <a className="border p-5 bg-white flex flex-col rounded-lg h-full" href={`/users/ais/${ai.slug}`}>
        <AiCardImage ai={ai}/>
        <div className="flex items-center pt-1">
          <div className="pr-1">
            {showBadge && <New publishedAt={ai.publishedAt}/>}
          </div>
          <AiCardTitle ai={ai}/>
        </div>
        <AiCardIntro ai={ai}/>
      </a>
      {showFavorite && <FavoriteButton slug={ai.slug}/>}
    </div>
  );
};

export const StandardCardFull: React.FC<Props> = ({ai}) => {
  return <StandardCard ai={ai} showFavorite={true} showBadge={true}/>;
};

export const StandardCardWithFavorite: React.FC<Props> = ({ai}) => {
  return <StandardCard ai={ai} showFavorite={true}/>;
};

export const StandardCardWithBadge: React.FC<Props> = ({ai}) => {
  return <StandardCard ai={ai} showBadge={true}/>;
};

export const StandardCardBasic: React.FC<Props> = ({ai}) => {
  return <StandardCard ai={ai}/>;
};
