import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import UserDetailModal from "../../../components/partials/modals/UserDetail";
type Props = {
  user: User;
};

const UsersIndex: React.FC<Props> = (props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="flex flex-row justify-center mt-10">
        <a
          className="basis-full md:basis-1/3 rounded-lg bg-blue-600 text-white p-3 shadow text-center"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
        >
          登録情報を変更
        </a>
      </div>
      {show && (
        <UserDetailModal user={props.user} title={`登録情報を変更する`} />
      )}
    </>
  );
};
export default UsersIndex;
