import React from "react";

import { UserGenImg } from "../../../interfaces/user_gen_img";
import { Search } from "lucide-react";

type Props = {
  image: UserGenImg;
  showCredit: boolean;
  onClick: () => void;
};

const Image: React.FC<Props> = (props) => {
  const imageAlt = () => {
    return (
      props.image.imageAi.name +
      " - " +
      props.image.formLabelAndValues
        .map((item) => `${item.label}: ${item.value}`)
        .join(" / ") +
      " - AI画像 クラウドワークスAI"
    );
  };
  const imageUrl = () => {
    if (props.showCredit) {
      return props.image.creditImage.thumb.url;
    } else {
      return props.image.image.thumb.url;
    }
  };
  return (
    <>
      <div
        className="relative rounded rounded-md shadow hover:shadow-lg"
        key={props.image.id}
      >
        <img
          src={imageUrl()}
          alt={imageAlt()}
          className={`w-full rounded rounded-md`}
        />
        <div
          className="absolute top-0 left-0 w-full h-full transition-all opacity-0 hover:opacity-100 z-10 bg-black/[0.5] cursor-zoom-in flex justify-center items-center text-white"
          onClick={props.onClick}
        >
          <div className="text-center">
            <Search className="w-10 h-10 mx-auto" />
            <p className="text-xs">クリックで拡大</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Image;
