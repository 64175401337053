import getClient from "./client";
import { PaginateUserGenImgsParams } from "../../interfaces/requests/user_gen_img";

export const paginateUserGenImgs = async (
  params: PaginateUserGenImgsParams
) => {
  const response = await getClient().get(`/user_gen_imgs/paginate.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};
