import React, { useRef, useMemo, useEffect, useState } from "react";
import { InputField } from "../../../interfaces/input_field";
import { InputOption } from "../../../interfaces/input_option";
import { toast } from "react-toastify";
import BulkAddSelectOptionModal from "../../partials/modals/BulkAddSelectOption";
type Props = {
  inputField: InputField;
  updateInputField: (inputField: InputField) => void;
  removeInputField: () => void;
  index: number;
  length: number;
  moveDown: (index: number) => void;
  moveUp: (index: number) => void;
};

const AdminsInputFieldsRow: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<InputOption[]>(
    props.inputField?.options
  );
  const [optionValue, setOptionValue] = useState("");
  const [optionName, setOptionName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const removeOption = (name: string) => {
    setOptions((prevOptions) => {
      return prevOptions.filter((prevOption) => {
        if (prevOption.name != name) {
          return prevOption;
        }
      });
    });

    props.updateInputField({
      ...props.inputField,
      options: options.filter((option) => {
        if (option.name != name) {
          return option;
        }
      }),
    });
  };
  const kindOptions = [
    { label: "テキスト", value: "input_text" },
    { label: "テキストエリア", value: "text_area" },
    { label: "セレクト", value: "input_select" },
    { label: "ラジオボタン", value: "input_radio" },
    { label: "ファイル", value: "input_file" }
  ];
  const colOptions = [...Array(12)].map((_, i) => i + 1);

  const onChangeKind = (v: string) => {
    props.updateInputField({ ...props.inputField, kind: v });
  };

  const onChangeInputName = (v: string) => {
    props.updateInputField({ ...props.inputField, inputName: v });
  };

  const onChangeLabelName = (v: string) => {
    props.updateInputField({ ...props.inputField, label: v });
  };

  const onChangeHelpText = (v: string) => {
    props.updateInputField({ ...props.inputField, helpText: v });
  };

  const onChangeCol = (v: string) => {
    props.updateInputField({ ...props.inputField, col: v });
  };

  const addOption = () => {
    if (optionValue != "" && optionName != "") {
      setOptions([...options, { name: optionName, value: optionValue }]);
      setOptionValue("");
      setOptionName("");
      props.updateInputField({
        ...props.inputField,
        options: [...options, { name: optionName, value: optionValue }],
      });
    }
  };

  const convertStringToOptions = (inputStr) => {
    // 文字列を行に分割して、それぞれの行を処理する
    const optionsList = inputStr.trim().split("\n");
    const options: InputOption[] = [];

    // 残りの行を解析してオブジェクトに変換
    for (const option of optionsList) {
      const [name, value] = option.split(":");
      options.push({ name: name, value: value });
    }

    return options;
  };

  const bulkAddOption = (str) => {
    console.log("str", str);
    const options = convertStringToOptions(str);
    setOptions((prevOptions) => [...prevOptions, ...options]);
    // 親コンポーネントの状態を更新
    props.updateInputField({
      ...props.inputField,
      options: [...props.inputField.options, ...options],
    });
  };

  const showValueJsonForm = () => {
    return (
      props.inputField.kind == "input_select" ||
      props.inputField.kind == "input_radio"
    );
  };

  const copyOptionsStrToClipboard = () => {
    // オプションを "名前:値" の形式の文字列に変換
    const optionsString = options
      .map((option) => `${option.name}:${option.value}`)
      .join("\n");
    // クリップボードにコピー
    navigator.clipboard
      .writeText(optionsString)
      .then(() => {
        toast.success("クリップボードにコピーされました");
        // 成功した場合の処理 (必要に応じて)
      })
      .catch((err) => {
        console.error("クリップボードへのコピーに失敗しました", err);
        toast.error("クリップボードへのコピーに失敗しました");
        // エラー処理 (必要に応じて)
      });
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 items-top">
        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            種別
          </label>
          <div className="mt-1">
            <select
              id="location"
              name="location"
              className="block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => onChangeKind(e.target.value)}
              defaultValue={props.inputField.kind}
            >
              {kindOptions.map((option) => {
                return (
                  <option
                    key={`kind-option-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            ラベル名
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="label-name"
              id="label-name"
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => onChangeLabelName(e.target.value)}
              defaultValue={props.inputField.label}
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="input-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            input name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="input-name"
              id="input-name"
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => onChangeInputName(e.target.value)}
              defaultValue={props.inputField.inputName}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="help"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            補足テキスト
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="help"
              id="help"
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => onChangeHelpText(e.target.value)}
              defaultValue={props.inputField.helpText}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="col"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            幅(1~12)
          </label>
          <div className="mt-1">
            <select
              id="col"
              name="col"
              className="block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => onChangeCol(e.target.value)}
              defaultValue={props.inputField.col}
            >
              {colOptions.map((option) => {
                return (
                  <option key={`kind-option-${option}`} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="sm:col-span-1">
          <div className="inline-flex rounded-md" role="group">
            {props.index > 0 && (
              <button
                type="button"
                className="px-2 py-1 text-xs font-medium text-gray-900 bg-white hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                onClick={() => props.moveUp(props.index)}
              >
                <i className="fa-solid fa-arrow-up"></i>
              </button>
            )}
            {props.index < props.length - 1 && (
              <button
                type="button"
                className="px-2 py-1 text-xs font-medium text-gray-900 bg-white hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                onClick={() => props.moveDown(props.index)}
              >
                <i className="fa-solid fa-arrow-down"></i>
              </button>
            )}
            <button
              type="button"
              className="px-2 py-1 text-xs font-medium text-gray-900 bg-white hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
              onClick={() => props.removeInputField()}
            >
              <i className="fas fa-trash" />
            </button>
          </div>
        </div>
      </div>
      {showValueJsonForm() && (
        <div className="mt-2 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12 items-center">
          <div className="sm:col-span-12">
            <div className="mt-2 grid grid-cols-12 -space-x-px rounded-md shadow-sm">
              <div className="col-span-2">
                <input
                  type="text"
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="例)女性"
                  onChange={(e) => setOptionName(e.target.value)}
                  value={optionName}
                />
              </div>
              <div className="md:col-span-8">
                <input
                  type="text"
                  className="block w-full rounded-none border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="例)女性のetc..."
                  onChange={(e) => setOptionValue(e.target.value)}
                  value={optionValue}
                />
              </div>
              <button
                type="button"
                className="relative lg:col-span-1 md:col-span-2 -md px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => addOption()}
              >
                追加
              </button>
              <button
                type="button"
                className="relative lg:col-span-1 md:col-span-2 rounded-r -md px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => setModalOpen(true)}
              >
                一括
              </button>
            </div>
          </div>
          {options != null && (
            <div className="sm:col-span-12 content-end">
              {options.map((option) => {
                return (
                  <div
                    className="text-xs inline-flex mr-2 items-center font-bold leading-sm px-3 py-1 bg-blue-200 text-blue-700 rounded-full mb-1"
                    key={`option-value-${option.name}`}
                  >
                    <span>{option.name}</span>
                    <span
                      className="ml-1 p-1 cursor-pointer"
                      onClick={() => removeOption(option.name)}
                    >
                      <i className="fa-solid fa-xmark" />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          <div className="sm:col-span-12">
            <button
              type="button"
              className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => copyOptionsStrToClipboard()}
            >
              一括コピー
            </button>
          </div>
        </div>
      )}

      <BulkAddSelectOptionModal
        open={modalOpen}
        setOpen={setModalOpen}
        onSubmit={bulkAddOption}
      />
    </>
  );
};
export default AdminsInputFieldsRow;
