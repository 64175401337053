import React from "react";
import {Ai} from "@/interfaces/ai";
import {AiCardImage, AiCardTitle, AiCardIntro} from "./AiCardCommon";
import FavoriteButton from "@/components/biz/partials/favorite/FavoriteButton";
import {New} from "@/components/biz/partials/New";

type Props = {
  ai: Ai;
  showBadge?: boolean;
  showFavorite?: boolean;
};

const WideCard: React.FC<Props> = ({ai, showBadge = false, showFavorite = false}) => {
  return (
    <div className={`relative w-full h-[104px]`}>
      <a className="border px-5 py-0 bg-white flex items-center rounded-lg h-full" href={`/users/ais/${ai.slug}`}>
        <div className="flex-shrink-0">
          <AiCardImage ai={ai}/>
        </div>
        <div className="ml-4">
          <div className="flex items-center pt-1">
            {showBadge && <New publishedAt={ai.publishedAt}/>}
            <AiCardTitle ai={ai}/>
          </div>
          <AiCardIntro ai={ai}/>
        </div>
      </a>
      {showFavorite && <FavoriteButton slug={ai.slug}/>}
    </div>
  );
};

export const WideCardFull: React.FC<Props> = ({ai}) => {
  return <WideCard ai={ai} showFavorite={true} showBadge={true}/>;
};

export const WideCardWithFavorite: React.FC<Props> = ({ai}) => {
  return <WideCard ai={ai} showFavorite={true}/>;
};

export const WideCardWithBadge: React.FC<Props> = ({ai}) => {
  return <WideCard ai={ai} showBadge={true}/>;
};

export const WideCardBasic: React.FC<Props> = ({ai}) => {
  return <WideCard ai={ai}/>;
};
