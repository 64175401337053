import getClient from "../client";

export const vendorUpdateImagePrompt = async (slug: string, prompt: string) => {
  return getClient().post(`/vendors/image_ais/${slug}/update_prompt.json`, {
    prompt: prompt,
  });
};

export const vendorCloneImageAi = async (slug: string) => {
  return getClient().post(`/vendors/image_ais/${slug}/clone.json`);
};

export const vendorCreateImageAi = async (params) => {
  return getClient().post(`/vendors/image_ais.json`, params);
};

export const vendorUpdateImageAi = async (slug: string, params) => {
  return getClient().put(`/vendors/image_ais/${slug}.json`, params);
};
