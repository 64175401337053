import React, { useState, useEffect } from "react";
import { User } from "../../../interfaces/user";

type Props = {
  filterPlans: (interval: string, intervalCount: number) => void;
  user: User;
};

const UsersPlansTable: React.FC<Props> = (props) => {
  const [interval, setInterval] = useState(props.user.planDallE?.interval);
  const [intervalCount, setIntervalCount] = useState(
    props.user.planDallE?.intervalCount
  );
  const [planType, setPlanType] = useState("annual");

  useEffect(() => {
    let type = "annual";
    if (interval === "year") {
      type = "annual";
    } else if (interval === "month") {
      if (intervalCount === 6) {
        type = "halfYear";
      } else if (intervalCount === 1) {
        type = "month";
      }
    }
    setPlanType(type);
  }, [interval, intervalCount]);

  const hideMonth = () => {
    if (props.user.planDallE == null) {
      return false;
    } else {
      if (props.user.planDallE?.interval == "year") {
        return true;
      }
      if (props.user.planDallE?.intervalCount == 6) {
        return true;
      }
    }
  };
  const hideHalf = () => {
    if (props.user.planDallE?.interval == "year") {
      return true;
    }
  };

  const handlePlanChange = (int: string, intCount: number) => {
    setInterval(int);
    setIntervalCount(intCount);
    props.filterPlans(int, intCount);
  };

  return (
    <>
      <div
        className="flex space-x-1 rounded-lg bg-slate-100 px-8 py-2"
        role="tablist"
        aria-orientation="horizontal"
      >
        <PlanButton
          isSelected={planType === "annual"}
          onClick={() => handlePlanChange("year", 1)}
          discountText="40%OFF"
          planText="年間払い"
        />
        <PlanButton
          isSelected={planType === "halfYear"}
          onClick={() => handlePlanChange("month", 6)}
          discountText="20%OFF"
          planText="半年払い"
        />
        <PlanButton
          isSelected={planType === "month"}
          onClick={() => handlePlanChange("month", 1)}
          planText="月払い"
        />
      </div>
    </>
  );
};

const PlanButton = ({ isSelected, onClick, discountText = "", planText }) => (
  <button
    className={`flex items-center rounded-md py-[0.4375rem] pr-2 pl-2 text-sm font-semibold lg:pr-3 ${
      isSelected ? "bg-white shadow" : ""
    }`}
    role="tab"
    type="button"
    aria-selected={isSelected}
    onClick={onClick}
  >
    {discountText && (
      <p className="rounded-sm bg-blue-500 pr-1 px-1 py-0.5 text-xs font-semibold text-white">
        {discountText}
      </p>
    )}
    <span
      className={`ml-2 ${isSelected ? "text-slate-900" : "text-slate-600"}`}
    >
      {planText}
    </span>
  </button>
);

export default UsersPlansTable;
