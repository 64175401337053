import React, { useState } from "react";

import { Ai } from "../../../../interfaces/ai";
import { User } from "../../../../interfaces/user";
import { toast } from "react-toastify";
import { toggleUserFavorite } from "../../../../libs/api/users/user_favorite_ai";

type Props = {
  ai: Ai;
  user?: User;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const UsersAi: React.FC<Props> = (props) => {
  const handleClickCard = (e) => {
    e.preventDefault();
    location.href = `/users/ais/${props.ai.slug}`;
  };

  const isFavorite = () => {
    return props.favorites.map((f) => f.id).includes(props.ai.id);
  };

  const addFavorite = async (ai: Ai) => {
    props.setFavorites((prevFavorites: Ai[]) => [...prevFavorites, ai]);
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("addFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りに追加しました");
  };

  const removeFavorite = async (ai: Ai) => {
    props.setFavorites((prevFavorites: Ai[]) =>
      prevFavorites.filter((faAi) => faAi.id !== ai.id)
    );
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("removeFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りから解除いたしました");
  };

  return (
    <>
      <div className="border p-3 flex items-top align-items-start">
        <div className="flex items-center justify-center">
          <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center mr-4 ml-2 text-xl">
            <i
              className={`${props.ai.mainCategoryFontawesomeClass} text-2xl`}
            ></i>
          </div>
        </div>
        <div className="mr-4 w-full">
          <h3 className="text-sm font-bold">{props.ai.name}</h3>
          <p className="text-xs text-gray-500">{props.ai.intro}</p>
        </div>
        <div className="w-48">
          {}
          {isFavorite() ? (
            <>
              <button
                type="button"
                className="rounded bg-gray-200 px-2 py-1.5 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 w-full hover:bg-gray-50 mb-1"
                onClick={() => removeFavorite(props.ai)}
              >
                解除
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="rounded bg-white px-2 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full hover:bg-gray-50 mb-1"
                onClick={() => addFavorite(props.ai)}
              >
                お気に入り
              </button>
            </>
          )}

          <button
            onClick={(e) => handleClickCard(e)}
            type="button"
            className="rounded bg-primary px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
          >
            詳しく見る
          </button>
        </div>
      </div>
    </>
  );
};
export default UsersAi;
