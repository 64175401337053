import React from "react";
import { useForm } from "react-hook-form";
import { RefundRequest } from "../../../interfaces/refund_request";
type Props = {
  onSubmit: (data: any) => void;
  refundRequest: RefundRequest;
};

const RefundForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      body: props.refundRequest?.body || "",
    },
  });

  const handleFormSubmit = (data: any) => {
    props.onSubmit(data);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="grid grid-cols-1 gap-x-6 gap-y-4"
      >
        <div className="col-span-full">
          <label
            htmlFor="body"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            返金理由
          </label>
          <div className="mt-2">
            <textarea
              {...register("body", { required: true })}
              name="body"
              id="body"
              rows={3}
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            ></textarea>
          </div>
          {errors.body && (
            <p className="text-red-500 bg-red-200 mt-2 text-sm">
              返金理由は必須です。
            </p>
          )}
        </div>
        <div className="mt-3 col-span-full">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {props.refundRequest?.id != null ? (
              <>返金リクエストを更新</>
            ) : (
              <>返金リクエストを送信</>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default RefundForm;
