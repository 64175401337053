import React, { useRef, useMemo, useEffect, useState } from "react";
import Image from "../../../users/user_gen_imgs/Image";
import { UserGenImg } from "../../../../interfaces/user_gen_img";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { paginateUserGenImgs } from "../../../../libs/api/user_gen_img";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

interface MetaData {
  currentPage?: number;
  nextPage?: number;
  prevPage?: number;
  totalPage?: number;
  totalCount?: number;
}
interface UserGenImgsResponse {
  userGenImgs: UserGenImg[];
  meta: MetaData;
}

type Props = {
  userGenImgs: UserGenImg[];
};

const AdminUserGenImgsWrapper: React.FC<Props> = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AdminUserGenImgs {...props} />
    </QueryClientProvider>
  );
};

const AdminUserGenImgs: React.FC<Props> = (props) => {
  const { ref, inView } = useInView({
    threshold: 0, // 0は要素が少しでも表示されるとトリガーされる
  });

  const getUserGenImgsQuery = useInfiniteQuery<UserGenImgsResponse>(
    ["user_gen_imgs"],
    ({ pageParam }) => {
      return paginateUserGenImgs({
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: {
        userGenImgs: UserGenImg[];
        meta: MetaData;
      }) => {
        //console.log("lastPage?.meta?", lastPage?.meta);
        if (lastPage?.meta?.nextPage == null) {
          //console.log("lastPage?.meta?", lastPage?.meta);
          return undefined;
        } else {
          //console.log("lastPage?.meta?", lastPage?.meta);
          return lastPage?.meta?.nextPage;
        }
      },
      initialData: {
        pages: [{ userGenImgs: props.userGenImgs, meta: {} }],
        pageParams: [1],
      },
    }
  );

  useEffect(() => {
    if (
      inView &&
      !getUserGenImgsQuery.isFetching &&
      getUserGenImgsQuery.hasNextPage
    ) {
      getUserGenImgsQuery.fetchNextPage();
    }
  }, [inView, getUserGenImgsQuery]);

  const currentUserGenImgs =
    getUserGenImgsQuery.data &&
    getUserGenImgsQuery.data.pages.flatMap((page) => page.userGenImgs);

  return (
    <>
      <h2 className="text-gray-700 font-bold mr-3 text-lg mt-3 mb-2">
        AIが生成した画像一覧
      </h2>

      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 5 }}>
        <Masonry gutter="5px">
          {currentUserGenImgs &&
            currentUserGenImgs.map((userGenImg) => {
              return (
                <Image
                  image={userGenImg}
                  showCredit={false}
                  onClick={function (): void {
                    window.open(
                      `/admins/user_gen_imgs/${userGenImg.id}/redirect_user_detail`,
                      "_blank"
                    );
                  }}
                />
              );
            })}
          <div ref={ref}>
            {getUserGenImgsQuery.isFetchingNextPage && <p>Loading more...</p>}
          </div>
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};
export default AdminUserGenImgsWrapper;
