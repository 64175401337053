import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { UserGenImg } from "../../../interfaces/user_gen_img";
import { User } from "../../../interfaces/user";
import { ImageAi } from "../../../interfaces/image_ai";
import RegisterAlert from "../../../components/partials/modals/RegisterAlert";
import { saveAs } from "file-saver";

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
  img?: UserGenImg;
  imageAi: ImageAi;
  user?: User;
  showAiDetail?: boolean;
};

const UserGenImgDetailModal: React.FC<Props> = (props) => {
  const initialFocusRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const imageUrl = () => {
    if (props.img) {
      return props.img.creditImage.url;
    }
  };

  const onClickGenerateSameCond = () => {
    if (props.user && props.img) {
      location.href = `/users/image_ais/${props.imageAi.slug}?id=${props.img.id}`;
    } else {
      setModalOpen(true);
    }
  };

  const isFree = () => {
    if (props.user) {
      if (props.user.isPaidDallE) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const downloadImage = async (url) => {
    try {
      const encodedUrl = encodeURIComponent(url);
      await saveAs(`/api/v1/users/proxies?url=${encodedUrl}`);
    } catch (error) {
      console.error("Error during image download", error);
    }
  };

  const onClickDownload = async () => {
    if (props.user) {
      //@todo
      await downloadImage(imageUrl());
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={props.setOpen}
          initialFocus={initialFocusRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-full max-h-max m-8">
                  <div className="flex items-center justify-between md:px-6 p-3 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      画像詳細
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                      data-modal-hide="default-modal"
                      onClick={() => props.setOpen(false)}
                    >
                      <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start md:p-6 p-3">
                    {props.img && (
                      <>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <img
                              src={imageUrl()}
                              className={`w-full rounded rounded-md`}
                            />
                          </div>
                          <div className="grid content-between">
                            <div>
                              <h4 className="text-md font-semibold text-gray-900 mb-4">
                                フォームの入力値
                              </h4>
                              <table
                                className={`w-full text-sm text-left text-gray-500 mb-4`}
                              >
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                  <tr>
                                    <th className={`px-1 py-1`}>項目</th>
                                    <th className={`px-1 py-1`}>入力値</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.img.formLabelAndValues.map(
                                    (row, i) => {
                                      return (
                                        <tr
                                          className={`bg-white border-b text-xs`}
                                          key={`label-and-val-${i}`}
                                        >
                                          <td className={`px-1 py-3`}>
                                            {row.label}
                                          </td>
                                          <td className={`px-1 py-3`}>
                                            {row.value}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>

                              <div className="relative flex items-start space-x-2 mb-4 sm:mb-0">
                                <button
                                  type="button"
                                  className="w-full rounded-full bg-gradient-to-r from-blue-500 to-purple-600 px-6 py-3 text-md font-semibold text-white shadow-sm hover:opacity-90"
                                  onClick={onClickGenerateSameCond}
                                >
                                  同じ条件で画像を生成する
                                </button>
                              </div>
                            </div>
                            <div>
                              <div className="grid grid-cols-2 gap-4">
                                <button
                                  type="button"
                                  className="bg-gray-100 text-gray-600 border text-xs py-2 w-full rounded-full hover:opacity-80"
                                  onClick={onClickDownload}
                                >
                                  <i className="fa-solid fa-download cursor-pointer"></i>
                                  <span className="ml-2">ダウンロード</span>
                                </button>
                                <a
                                  className="bg-gray-100 text-gray-600 border text-xs py-2 w-full rounded-full hover:opacity-80 text-center"
                                  href={imageUrl()}
                                  target="_blank"
                                >
                                  <i className="fa-solid fa-arrow-up-right-from-square cursor-pointer"></i>
                                  <span className="ml-2">画像URLを開く</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div ref={initialFocusRef} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {props.img && (
        <RegisterAlert
          open={modalOpen}
          setOpen={setModalOpen}
          redirectUrl={`/users/image_ais/${props.imageAi.slug}?id=${props.img.id}`}
        />
      )}
    </>
  );
};
export default UserGenImgDetailModal;
