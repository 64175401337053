import React from "react";
import { Ai } from "@/interfaces/ai";

type Props = {
    ai: Ai;
};

const AiCardContent: React.FC<Props> = ({ ai }) => {
    return (
        <div className="flex-auto pt-3">
            <h3 className="text-sm font-bold line-clamp-2 break-all">{ai.name}</h3>
            <p className="pt-2 text-xs text-gray-500 line-clamp-3 break-all">
                {ai.intro}
            </p>
        </div>
    );
};

export default AiCardContent;
