import React from "react";

const colorMappings = {
  gray: "bg-gray-100 text-gray-700",
  red: "bg-red-100 text-red-700",
  yellow: "bg-yellow-100 text-yellow-700",
  green: "bg-green-100 text-green-700",
  blue: "bg-blue-100 text-blue-700",
  indigo: "bg-indigo-100 text-indigo-700",
  purple: "bg-purple-100 text-purple-700",
  pink: "bg-pink-100 text-pink-700",
};

type BadgeProps = {
  category: string;
  color: keyof typeof colorMappings;
};

const Badge: React.FC<BadgeProps> = ({category, color}) => {
  return (
    <span className={`inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium ${colorMappings[color]}`}>
      {category}
    </span>
  );
};
export const NewBadge: React.FC = () => (
  <Badge category="NEW" color="pink"/>
);
