import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
type Props = {
  link: string;
};
const PremiumTrial: React.FC<Props> = (props) => {
  const linearGradientStyle = {
    background: "linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)",
  };

  return (
    <div
      className="relative isolate flex flex-wrap justify-center items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-3 sm:px-3.5 rounded-md"
      style={linearGradientStyle}
    >
      <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-white">
          <>
            <strong className="font-semibold text-md">
              <span className="font-bold text-yellow-200 underline decoration-3 text-xl">
                たった99円/日
              </span>
              で
              <span className="font-bold text-yellow-200 decoration-3 text-xl">
                広告表示なし
              </span>
            </strong>
          </>
        </p>
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2 mt-2 md:mt-0">
        <a
          href={props.link}
          target="_blank"
          className="flex-none rounded-full bg-white px-5 py-2 text-sm font-semibold text-[#222] shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          チェック <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  );
};
export default PremiumTrial;
