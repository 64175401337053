import React from "react";

type Props = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

const PaginationButton: React.FC<Props> = ({ label, onClick, disabled, className }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${className}`}
  >
    {label}
  </button>
);

export default PaginationButton;
