import React, { useMemo, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { User } from "../../../../interfaces/user";
import UpgradeAlert from "../../../../components/partials/modals/UpgradeAlert";
import { createDallEDemo } from "../../../../libs/api/users/dall_e";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

type Props = {
  user: User;
};

import PromptSettingForm from "../../../users/ais/image/DemoForm";

const AdminsAisForm: React.FC<Props> = (props) => {
  const [streaming, setStreaming] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [images, setImages] = useState<string[]>([]);

  // 画像ごとのダウンロード状態を管理するステート
  const [downloadingStatus, setDownloadingStatus] = useState<{
    [url: string]: boolean;
  }>({});

  const [totalImageCount, setTotalImageCount] = useState(
    props.user?.currentImageGenerateCount
  );

  const onSubmitPromptSettingForm = async (data: any) => {
    if (totalImageCount >= props.user.imageGenerateLimit) {
      setModalOpen(true);
      return;
    }

    setStreaming(true);
    let newTotalImageCount = totalImageCount;

    // 明示的に配列の型を指定
    let promises: Promise<AxiosResponse<any, any>>[] = [];

    for (let i = 0; i < data.num; i++) {
      // 各APIリクエストのプロミスを配列に追加
      promises.push(createDallEDemo(data));
    }

    try {
      // すべてのプロミスを並行して実行
      let results = await Promise.all(promises);

      // 各リクエストの結果を処理
      for (const res of results) {
        if (res.data.is_error) {
          toast.error(res.data.message);
          break;
        } else {
          // images配列に新しいURLを追加
          setImages((prevImages) => [res.data.image_url, ...prevImages]);

          newTotalImageCount++;
          setTotalImageCount(newTotalImageCount);

          let event = new CustomEvent("incrementGenerateCount", {
            detail: newTotalImageCount,
          });
          window.dispatchEvent(event);

          if (newTotalImageCount >= props.user.imageGenerateLimit) {
            setModalOpen(true);
            break;
          }
        }
      }
    } catch (error) {
      // エラーハンドリング
      console.error("Error during image generation:", error);
      toast.error("画像の生成中にエラーが発生しました");
    }
    toast.success("画像を生成しました");

    setStreaming(false);
  };

  const downloadImage = async (url) => {
    setDownloadingStatus((prev) => ({ ...prev, [url]: true })); // ダウンロード開始

    try {
      const encodedUrl = encodeURIComponent(url);
      await saveAs(`/api/v1/users/proxies?url=${encodedUrl}`);
    } catch (error) {
      console.error("Error during image download", error);
    } finally {
      setTimeout(() => {
        setDownloadingStatus((prev) => ({ ...prev, [url]: false }));
      }, 5000);
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <div className="shadow sm:overflow-hidden sm:roudned-md border">
            <PromptSettingForm
              submit={(datas) => onSubmitPromptSettingForm(datas)}
              streaming={streaming}
            />
          </div>
        </div>
        <div className="col-span-12">
          <>
            <div className="py-3">
              <h2 className="text-xl font-bold">出力結果</h2>
            </div>
            <div className="lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-4 grid gap-3">
              {images.map((image, i) => (
                <div
                  className="col-span-4 shadow sm:overflow-hidden sm:roudned-md border p-3"
                  key={`image-${image}`}
                >
                  <img src={image} alt={`画像 ${i}`} className="" />
                  <div className="w-full flex items-center pt-2 text-gray-600 font-bold">
                    {/* ダウンロードボタン */}
                    {!downloadingStatus[image] ? (
                      <span className="relative group">
                        <button
                          className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2"
                          onClick={() => downloadImage(image)}
                        >
                          <i className="fa-solid fa-download cursor-pointer"></i>
                          <span className="hidden lg:inline-block ml-1">
                            ダウンロード
                          </span>
                        </button>
                      </span>
                    ) : (
                      <span className="relative group">
                        <button className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2">
                          <i className="fa-solid fa-download cursor-pointer"></i>
                          <span className="hidden lg:inline-block ml-1">
                            ダウンロード中...
                          </span>
                        </button>
                      </span>
                    )}
                    <span className="relative group">
                      <a
                        className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2"
                        href={image}
                        target="_blank"
                      >
                        <i className="fa-solid fa-arrow-up-right-from-square cursor-pointer"></i>
                        <span className="hidden lg:inline-block ml-1">
                          URLを開く
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        </div>
      </div>
      <UpgradeAlert
        open={modalOpen}
        setOpen={setModalOpen}
        title={`画像生成上限に達しました`}
        message={`現状のプランでは画像生成が「${props.user.imageGenerateLimit}枚」までとなっております。以下のボタンからオプション選択ページに移動し、アップグレードしてください`}
        redirectLink={`/users/option_plans/image_generator`}
        cta={`画像生成のオプションを見る`}
      />
    </>
  );
};
export default AdminsAisForm;
