import React, { useContext } from "react";

type Props = {};
const FaceswapMovieUploadForm: React.FC<Props> = (props) => {
  return (
    <>
      <div className="flex items-center justify-center">
        <div className="mx-auto w-full max-w-[550px] bg-white">
          <form className="py-4 px-9">
            <div className="mb-6 pt-4">
              <label className="mb-5 block text-md text-gray-800 font-semibold ">
                変換元動画ファイルを選択
              </label>

              <div className="mb-8">
                <input type="file" name="file" id="file" className="sr-only" />
                <label
                  htmlFor="file"
                  className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                >
                  <div>
                    <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                      ファイルをドロップ
                    </span>
                    <span className="mb-2 block text-base font-medium text-[#6B7280]">
                      Or
                    </span>
                    <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                      ファイルを選択
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FaceswapMovieUploadForm;
