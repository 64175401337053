import React, {useState} from "react";
import { AiForm } from "../../../../interfaces/ai_form";
import InputText from "../forms/InputText";
import TextArea from "../forms/TextArea";
import InputSelect from "../forms/InputSelect";
import { useForm, FieldValues } from "react-hook-form";
import { User } from "../../../../interfaces/user";
import InputFile, { UploadedFile } from "../forms/InputFile";

type Props = {
  submit: (data: FieldValues, uploadedFiles: UploadedFile[]) => void;
  aiForms: AiForm[];
  streaming: boolean;
  cancelStreaming: () => void;
  user?: User;
  isAdmin?: boolean;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const setUploadedFile = (file: UploadedFile) => {
    const newUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.inputName !== file.inputName);
    newUploadedFiles.push(file);

    setUploadedFiles(newUploadedFiles);
  }

  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const inputTag = (aiForm, idx) => {
    if (aiForm.kind == "input_text") {
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "input_radio") {
    } else if (aiForm.kind == "input_file") {
      return (
        <InputFile
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setUploadedFile={setUploadedFile}
        />
      );
    }
  };

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();
    if (result) {
      const data = getValues();
      props.submit(data, uploadedFiles);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button
          className="w-full rounded-full bg-gradient-to-r from-red-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
          onClick={props.cancelStreaming}
        >
          生成キャンセル
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="w-full rounded-full bg-gradient-to-r from-blue-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          生成する
        </button>
      );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div className="sm:shadow-lg sm:rounded-lg">
          <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-6 gap-3">
              {props.aiForms.map((aiForm, idx) => {
                return inputTag(aiForm, idx);
              })}
            </div>
          </div>
          <div className="bg-[#f7f9f9] px-4 py-3 text-right sm:px-6">
            {submitBtn()}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
