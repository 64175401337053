import React from "react";

import { User } from "@/interfaces/user";
import { Plan } from "@/interfaces/plan";

type Props = {
  plan: Plan;
  user: User;
  toYen: (amount: number) => string;
};

const ChangePlanConfirmTable: React.FC<Props> = (props) => {
  return (
    <>
      <table className="w-full text-left text-gray-900">
        <thead className="uppercase bg-gray-50">
          <tr>
            <th className="p-3 text-xs">プラン名</th>
            <th className="p-3 text-xs">契約金額</th>
            <th className="p-3 text-xs">数量</th>
            <th className="p-3 text-xs">お支払い金額</th>
            <th className="p-3 text-xs">支払いサイクル</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-3 text-sm">{props.plan.name}</td>
            <td className="p-3 text-sm">
              {props.toYen(props.plan.amount)}円（税込み）
            </td>
            <td className="p-3 text-sm">1</td>
            <td className="p-3 text-sm">
              {props.toYen(props.plan.amount)}円（税込み）
            </td>
            <td className="p-3 text-sm">{props.plan.cycleHuman}</td>
          </tr>
        </tbody>
      </table>
      {props.plan.description && (
        <>
          <div className="text-xs mt-2 text-gray-500">
            {props.plan.description}
          </div>
        </>
      )}
      <div className="text-xs mt-1 text-gray-500">
        ※お支払い方法：クレジットカードによるお支払い（一括）
        <br />
        ※サブスクリプション契約のため、お客様からの解約処理が行われるまで、自動更新されます。
        <br />
        {!props.user.hasCard && (
          <>
            ※{props.plan.cycleHuman}に{props.toYen(props.plan.amount)}
            円（税込）が引き落とされます。
            <br />
          </>
        )}
        ※サービスの利用開始時期：決済後、ただちにご利用いただけます。
        <br />
        ※キャンセル、解約について：決済後のお客様のご都合による返金はサービスの性質上、応じられません。解約については、解約方法
        <a
          href="https://crowdworks-ai.notion.site/cef79a24edca4478a0b87b54c04238c6"
          className="text-blue-500"
          target="_blank"
        >
          「有料プランをやめたい・解約したい」
        </a>
        をご確認ください。但し、サービスの利用可能期間の途中で解約いただいても、既にお支払いいただいた利用料金は、日割精算等による返金を含めた一切の返金を行いません。あらかじめご了承願います。
      </div>
    </>
  );
};

export default ChangePlanConfirmTable;
