import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import React from "react";

const faqs = [
  {
    question: "送信したデータはAIの学習に使われますか？",
    answer:
      "クラウドワークスAIには、OpenAI社が提供するChatGPT APIが使用されています。入力したデータの扱いについては、OpenAI社のポリシーに沿って扱われます。 以下は、そのポリシーを簡単に翻訳して引用したものです。 1.OpenAIは、ユーザーが意図してデータを共有することを決めない限り、APIを通じて提出されたデータを、AIの学習や改善に使いません。お客様はデータを共有することを選ぶことができます。 2.APIを使って送られたデータは、悪い使い方や誤った使い方を監視するために最大30日間保管され、その後消去されます（法律で必要な場合は除く）。 ※原文はOpenAI社の「API data usage policies」をご確認ください。 また、クラウドワークスAIを提供する株式会社AItechもユーザーが送信したデータを収集・保管しません。",
  },
  // More questions...
  {
    question: "クラウドワークスAIで生成した内容をそのまま利用していいですか？",
    answer:
      "クラウドワークスAIが生成したテキストを活用いただくことに問題はありません。ただし、ご自身のブログなどに活用する場合は、必ず内容をご自身でお確かめのうえ、第三者の知的財産を侵害したり、他者を誹謗中傷するなど、法的および社会通念上、許されない使い方はおやめください。",
  },
  // More questions...
  {
    question:
      "クラウドワークスAIで執筆した作品の権利上の扱いはどうなりますか？",
    answer:
      "あくまでAIは副操縦士であり助手ですので、あなたが執筆した作品に変わりはありません。例えば、漫画作品でもアシスタントが背景を担当することがありますがその作者の作品に変わりありません。ただし、ご自身の作品などに活用する場合は、必ず内容をご自身でお確かめのうえ、第三者の知的財産を侵害したり、他者を誹謗中傷するなど、法的および社会通念上、許されない使い方はおやめください。",
  },
  // More questions...
  {
    question: "クラウドワークスAIの内容は正確なものですか？",
    answer:
      "クラウドワークスAIにはOpenAI社のChatGPT APIを搭載した上で独自のデータなどを組み合わせて作っています。使用しているAIのモデルの特性上、必ずしも要望に対して正しい回答を提供するものではありません。固有名称や人物名、歴史上のデータなど、得られる内容の正確性は保証できませんのでご了承ください。",
  },
  // More questions...
  {
    question: "解約を忘れたのですが、返金は可能ですか？",
    answer:
      "申し訳ございません。サービスの特性上、課金後の返金は承っておりません。",
  },
  // More questions...
  {
    question: "クレジットカード払い以外も対応していますか？",
    answer:
      "申し訳ございません。クレジットカードの払いのみ対応をさせていただいております。",
  },
  // More questions...
  {
    question: "解約したいのですが、方法を教えてください。",
    answer:
      "解約は問い合わせから受け付けております。また、管理画面左サイドバーより無料のフリープランにダウングレードする事でアカウントを残したまま課金を止めることができますのでそちらの方法もご検討ください。また、有料プランの残日数に関わらず、freeプランにダウングレードいただいた時点で、有料プランのサービスが利用できなくなりますのでご注意ください。",
  },
  // More questions...
  {
    question:
      "月の途中で有料プランにした場合、次の課金はいつになるのでしょうか？契約日によっては損をしてしまう？",
    answer:
      "月の途中でご契約いただいた場合、次の課金は翌月の契約日になります。損なくご利用いただけますのでご安心ください。例えば15日に有料プランをご契約いただいた場合、翌月の15日が次の支払い日になります。",
  },
  // More questions...
  {
    question:
      "月払いプランの途中で半年払いプランや年間払いに変更することはできますか？",
    answer:
      "はい。可能です。途中で支払いサイクルをアップグレードいただいた場合も差額のみ請求させていただきますので、ユーザー様に損がない設計となっております。逆に、支払いサイクルを狭める方向でのダウングレードや途中返金等は承っておりません。",
  },
  {
    question:
      "クラウドワークスAIはクーリングオフに対応しておりますでしょうか？",
    answer: "クラウドワークスAIはクーリングオフ対象外でございます。",
  },
];

export default function Example() {
  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="mx-auto max-w-7xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
            よくある質問
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-300">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
