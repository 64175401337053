import React from "react";
import { Ai } from "../../../../interfaces/ai";
import { AiForm } from "../../../../interfaces/ai_form";
import { DifyWorkflowApp } from "../../../../interfaces/dify_workflow_app";
import { MainCategory } from "../../../../interfaces/main_category";
import { OrganizationOptions } from "../../../../interfaces/organization_options";

import CommonEditor from "../../../ais/texts/Edit";
/** API系 */
import { adminsGetSubCategories } from "../../../../libs/api/admins/sub_category";
import { adminsGetLeafCategories } from "../../../../libs/api/admins/leaf_category";
import { adminCreateAi, adminUpdateAi } from "../../../../libs/api/admins/ai";
import { adminBulkPostAiForms } from "../../../../libs/api/admins/ai_form";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  mainCategories: MainCategory[];
  difyWorkflowApps: DifyWorkflowApp[];
  organizations: OrganizationOptions[];
  permittedOrganizations: OrganizationOptions[];
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  return (
    <>
      <CommonEditor
        ai={props.ai}
        aiForms={props.aiForms}
        mainCategories={props.mainCategories}
        difyWorkflowApps={props.difyWorkflowApps}
        organizations={props.organizations}
        permittedOrganizations={props.permittedOrganizations}
        getSubCategories={adminsGetSubCategories}
        getLeafCategories={adminsGetLeafCategories}
        updateAi={adminUpdateAi}
        createAi={adminCreateAi}
        bulkPostAiForms={adminBulkPostAiForms}
      />
    </>
  );
};
export default AdminsAisEdit;
