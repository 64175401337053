import React, { useState } from "react";

import { ImageAi } from "../../../interfaces/image_ai";
import { User } from "../../../interfaces/user";
import { Activity } from "lucide-react";

type Props = { ai: ImageAi; user?: User };

const UsersAi: React.FC<Props> = (props) => {
  console.log("props", props);
  const [modalOpen, setModalOpen] = useState(false);
  const isFree = () => {
    return props.user && props.user.planName == "free";
  };
  const handleClickCard = (e) => {
    e.preventDefault();
    if (props.user) {
      location.href = `/users/image_ais/${props.ai.slug}`;
    } else {
      location.href = `/image_ais/${props.ai.slug}`;
    }
  };

  return (
    <>
      <a
        className="col-span-1 border border-gray-200 divide-y divide-gray-200 rounded-lg bg-white shadow hover:shadow-lg transition duration-500 ease-in-out"
        href={`#`}
        onClick={handleClickCard}
      >
        <div className="px-4 py-3 flex justify-between items-center rounded-t-md">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <div className="flex items-center flex-wrap text-sm font-semibold space-x-2">
                <div className="flex items-center justify-center">
                  <i
                    className={`w-4 h-4 ${props.ai.mainCategoryFontawesomeClass}`}
                  ></i>
                </div>

                <span>{props.ai.mainCategoryName}</span>
                {props.ai?.subCategoryName != null && (
                  <>
                    <div className="select-none text-slate-400">/</div>
                    <span>{props.ai?.subCategoryName}</span>
                  </>
                )}
                {props.ai?.leafCategoryName != null && (
                  <>
                    <div className="select-none text-slate-400">/</div>
                    <span>{props.ai?.leafCategoryName}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* 生成回数 */}
          <div className="flex justify-between items-center text-xs font-bold">
            <Activity className="w-4 h-4 mr-1" />
            <div className="text-[13px]">{props.ai.generateCount}</div>
          </div>
        </div>
        <div className="flex w-full items-start justify-between space-x-3 p-3">
          <img
            alt=""
            className="object-cover h-24 w-24 flex-shrink-0 rounded-md bg-gray-300"
            src={props.ai.image.thumb.url}
          />
          <div className="flex-1">
            <div className="items-center space-x-3">
              <h3 className="text-base text-gray-900 font-semibold">
                {props.ai.name}
              </h3>
            </div>
            <p className="mt-1 text-xs text-gray-500">{props.ai.intro}</p>
          </div>
        </div>
      </a>
    </>
  );
};
export default UsersAi;
