import React, { useState, useEffect } from "react";
import { Ai } from "../../../../../interfaces/ai";
import { User } from "../../../../../interfaces/user";
import NotifiyGeneratedText from "../../../../../components/partials/modals/NotifyGeneratedText";

type Props = {
  ai: Ai;
  user?: User;
  message: string;
  addEditorBody?: (s: any) => void;
  openSave?: boolean;
  setOpenSave?: (s: any) => void;
  openUpgrade?: boolean;
  setOpenUpgrade?: (s: any) => void;
  hideActionEditor?: boolean;
};

import { toast } from "react-toastify";
const UserAiChatMessgesActionBtns: React.FC<Props> = (props) => {
  const [openEmail, setOpenEmail] = useState(false);
  const [openGoogleDrive, setOpenGoogleDrive] = useState(false);
  const [openConnectGoogleDrive, setOpenConnectGoogleDrive] = useState(false);

  // ユーザーがフリープランかどうか
  const isFree = () => {
    if (props.user) {
      return props.user.planName == "free";
    } else {
      return false;
    }
  };

  // コピーボタン
  const copyClipboard = () => {
    if (isFree() && props.setOpenUpgrade) {
      props.setOpenUpgrade(true);
    } else {
      navigator.clipboard.writeText(props.message);
      toast.success("コピーしました");
    }
  };

  // グーグルドライブに保存
  const saveGoogleDocs = () => {
    alert("申し訳ございません。ただいま機能開発中です");
  };

  // ダウンロード
  const download = () => {
    if (isFree() && props.setOpenUpgrade) {
      props.setOpenUpgrade(true);
    } else {
      const filename = `${props.ai.slug}-${Date.now()}.txt`;
      const blob = new Blob([props.message], { type: "text/plain" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;

      // Append the download link to the DOM, trigger the click event, and remove the link
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  // メール
  const mail = () => {
    if (isFree() && props.setOpenUpgrade) {
      props.setOpenUpgrade(true);
    } else {
      setOpenEmail(true);
    }
  };

  // ツイート
  const tweet = () => {
    alert("申し訳ございません。ただいま機能開発中です");
  };

  // エディター編集
  const editor = () => {
    if (isFree() && props.setOpenUpgrade) {
      props.setOpenUpgrade(true);
    } else if (props.addEditorBody) {
      props.addEditorBody(props.message);
    }
  };

  return (
    <>
      <div className="w-full flex items-center justify-end pb-2 text-gray-600 font-bold">
        {/* ダウンロードボタン */}
        <span className="relative group">
          <span
            className={[
              "whitespace-nowrap",
              "rounded",
              "bg-black",
              "px-2",
              "py-1",
              "text-white",
              "absolute",
              "-top-10",
              "left-1/2",
              "-translate-x-1/2",
              "before:content-['']",
              "before:absolute",
              "before:-translate-x-1/2",
              "before:left-1/2",
              "before:top-full",
              "before:border-4",
              "before:border-transparent",
              "before:border-t-black",
              "opacity-0",
              "group-hover:opacity-100",
              "transition",
              "pointer-events-none",
              "text-xs",
            ].join(" ")}
          >
            クリックでダウンロード
          </span>
          <button
            data-tooltip-target="tooltip-copy-clipboard"
            className="text-xs text-gray-600 bg-white border-2 border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-full px-3 py-1.5 mr-2"
            onClick={() => download()}
          >
            <i className="fa-solid fa-download cursor-pointer"></i>
            <span className="hidden lg:inline-block ml-1">ダウンロード</span>
          </button>
        </span>

        {/* メールボタン */}
        <span className="relative group">
          <span
            className={[
              "whitespace-nowrap",
              "rounded",
              "bg-black",
              "px-2",
              "py-1",
              "text-white",
              "absolute",
              "-top-10",
              "left-1/2",
              "-translate-x-1/2",
              "before:content-['']",
              "before:absolute",
              "before:-translate-x-1/2",
              "before:left-1/2",
              "before:top-full",
              "before:border-4",
              "before:border-transparent",
              "before:border-t-black",
              "opacity-0",
              "group-hover:opacity-100",
              "transition",
              "pointer-events-none",
              "text-xs",
            ].join(" ")}
          >
            クリックでメールアドレスに送信
          </span>
          <button
            data-tooltip-target="tooltip-copy-clipboard"
            className="text-xs text-gray-600 bg-white border-2 border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-full px-3 py-1.5 mr-2"
            onClick={() => mail()}
          >
            <i className="fa-regular fa-envelope cursor-pointer"></i>
            <span className="hidden lg:inline-block ml-1">メール</span>
          </button>
        </span>

        {/* コピーボタン */}
        <span className="relative group">
          <span
            className={[
              "whitespace-nowrap",
              "rounded",
              "bg-black",
              "px-2",
              "py-1",
              "text-white",
              "absolute",
              "-top-10",
              "left-1/2",
              "-translate-x-1/2",
              "before:content-['']",
              "before:absolute",
              "before:-translate-x-1/2",
              "before:left-1/2",
              "before:top-full",
              "before:border-4",
              "before:border-transparent",
              "before:border-t-black",
              "opacity-0",
              "group-hover:opacity-100",
              "transition",
              "pointer-events-none",
              "text-xs",
            ].join(" ")}
          >
            クリックでコピーできます
          </span>
          <button
            data-tooltip-target="tooltip-copy-clipboard"
            className="text-xs text-gray-600 bg-white border-2 border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-full px-3 py-1.5 mr-2"
            onClick={() => copyClipboard()}
          >
            <i className="fa-regular fa-copy cursor-pointer"></i>
            <span className="hidden lg:inline-block ml-1">コピー</span>
          </button>
        </span>

        {/* 保存ボタン */}
        {!props.hideActionEditor && (
          <span className="relative group">
            <span
              className={[
                "whitespace-nowrap",
                "rounded",
                "bg-black",
                "px-2",
                "py-1",
                "text-white",
                "absolute",
                "-top-10",
                "left-1/2",
                "-translate-x-1/2",
                "before:content-['']",
                "before:absolute",
                "before:-translate-x-1/2",
                "before:left-1/2",
                "before:top-full",
                "before:border-4",
                "before:border-transparent",
                "before:border-t-black",
                "opacity-0",
                "group-hover:opacity-100",
                "transition",
                "pointer-events-none",
                "text-xs",
              ].join(" ")}
            >
              エディターに追加して編集
            </span>
            <button
              data-tooltip-target="tooltip-copy-clipboard"
              className="text-xs text-gray-600 bg-white border-2 border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-full px-3 py-1.5"
              onClick={() => editor()}
            >
              <i className="fa-solid fa-save cursor-pointer"></i>
              <span className="hidden lg:inline-block ml-1">追加→</span>
            </button>
          </span>
        )}
      </div>
      <NotifiyGeneratedText
        email={props.user ? props.user.email : ""}
        text={props.message}
        open={openEmail}
        setOpen={setOpenEmail}
      />
    </>
  );
};

export default UserAiChatMessgesActionBtns;
