import React, { useContext } from "react";
import { Ai } from "../../interfaces/ai";
import { User } from "../../interfaces/user";
import { UserSavedText } from "../../interfaces/user_saved_text";
type Props = {
  user: User;
  favorites: Ai[];
  savedTexts: UserSavedText[];
};
const Sidebar: React.FC<Props> = (props) => {
  const dotCss = () => {
    if (props.user.planName === "free") {
      return "fill-gray-400";
    } else if (props.user.planName === "basic") {
      return "fill-gray-400";
    } else if (props.user.planName === "premium") {
      return "fill-indigo-700";
    }
  };

  return (
    <div className="h-screen px-3 pb-24 overflow-y-auto bg-white">
      <div className="p-2 my-4">
        <div className="flex items-center">
          <i className="fas fa-user-circle mr-3 w-8 h-8 text-gray-400"></i>
          <div className="text-left">
            <div className="font-semibold leading-none text-gray-900 mb-0.5">
              {props.user.name} 様
            </div>
            <span
              className={`inline-flex items-center justify-center w-full gap-x-1.5 rounded-full bg-white border text-gray-900 px-2 py-1 text-xs font-medium mt-1`}
            >
              <svg
                className={`h-1.5 w-1.5 ${dotCss()}`}
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              {props.user.planName} プラン
            </span>
          </div>
        </div>
      </div>
      <ul className="space-y-1">
        <li>
          <a
            className="flex items-center p-2 text-sm font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
            href="https://s.lmes.jp/landing-qr/1661019655-yV8YWZVn?uLand=KCUaFB"
            target="_blank"
          >
            <div className="text-gray-700">
              <i className="fa-solid fa-envelope w-5"></i>
            </div>
            <span className="ml-2">お問い合わせ</span>
          </a>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
