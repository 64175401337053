import React, { useState, useEffect, useMemo } from "react";
import { ImageAi } from "../../../../interfaces/image_ai";
import { ImageAiForm } from "../../../../interfaces/image_ai_form";
import { UserGenImg } from "../../../../interfaces/user_gen_img";
import { MainCategory } from "../../../../interfaces/main_category";
import { vendorsGetSubCategories } from "../../../../libs/api/vendors/sub_category";
import { vendorsGetLeafCategories } from "../../../../libs/api/vendors/leaf_category";
import {
  vendorCreateImageAi,
  vendorUpdateImageAi,
} from "../../../../libs/api/vendors/image_ai";
import { vendorBulkPostImageAiForms } from "../../../../libs/api/vendors/image_ai_form";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { createDallE } from "../../../../libs/api/users/dall_e";
import PromptSettingForm from "../../../users/ais/image/PromptSettingForm";
import FormRow from "../../../admins/ais/form/FormRow";
import { saveAs } from "file-saver";

import { AxiosResponse } from "axios";

type Props = {
  imageAi: ImageAi;
  imageAiForms: ImageAiForm[];
  mainCategories: MainCategory[];
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  const [subCategories, setSubCategories] = useState([]);
  const [leafCategories, setLeafCategories] = useState([]);
  const [editImage, setEditImage] = useState(props.imageAi.image?.url == null);
  const [imageAiForms, setImageAiForms] = useState<ImageAiForm[]>(
    props.imageAiForms
  );
  console.log("props.imageAiForms", props.imageAiForms);
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [images, setImages] = useState<UserGenImg[]>([]);

  // 画像ごとのダウンロード状態を管理するステート
  const [downloadingStatus, setDownloadingStatus] = useState<{
    [url: string]: boolean;
  }>({});

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.imageAi.name,
      intro: props.imageAi.intro,
      image: "",
      mainCategorySlug: props.imageAi.mainCategorySlug,
      subCategorySlug: props.imageAi.subCategorySlug,
      leafCategorySlug: props.imageAi.leafCategorySlug,
      userMessage: props.imageAi.userMessage,
      prompt: props.imageAi.prompt,
      orderIdx: props.imageAi.orderIdx,
      status: props.imageAi.status,
      slug: props.imageAi.slug,
    },
  });

  useEffect(() => {
    if (props.imageAi.mainCategorySlug != null) {
      vendorsGetSubCategories({
        mainCategorySlug: props.imageAi.mainCategorySlug,
      }).then(({ data }) => {
        setSubCategories(data);
      });
    }
  }, [props.imageAi.mainCategorySlug]);

  useEffect(() => {
    if (props.imageAi.subCategorySlug != null) {
      vendorsGetLeafCategories({
        subCategorySlug: props.imageAi.subCategorySlug,
      }).then(({ data }) => {
        setLeafCategories(data);
      });
    }
  }, [props.imageAi.subCategorySlug]);

  const onSubmit = async (data) => {
    console.log("data", data);
    const formData = new FormData();
    formData.append("image_ai[name]", data.name || "");
    formData.append("image_ai[intro]", data.intro || "");
    if (data.image != undefined && data.image) {
      formData.append("image_ai[image]", data.image);
    }

    formData.append("image_ai[mainCategorySlug]", data.mainCategorySlug || "");
    formData.append("image_ai[subCategorySlug]", data.subCategorySlug || "");
    formData.append("image_ai[leafCategorySlug]", data.leafCategorySlug || "");
    formData.append("image_ai[userMessage]", data.userMessage || "");
    formData.append("image_ai[prompt]", data.prompt || "");
    formData.append("image_ai[orderIdx]", data.orderIdx || "");
    formData.append("image_ai[status]", data.status || "");

    if (data.slug != null) {
      formData.append("image_ai[slug]", data.slug || "");
    }
    try {
      if (props.imageAi?.id != null) {
        await vendorUpdateImageAi(props.imageAi.slug, formData);
        toastSuccess("更新しました");
      } else {
        await vendorCreateImageAi(formData);
        toastSuccess("作成しました");
      }
    } catch (error) {
      let errorMessage = error.response.data.errors.join(", ");
      toast.error(`更新に失敗しました。${errorMessage}`);
      console.error(error);
    }
  };
  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleFileChange = (event) => {
    setValue("image", event.target.files[0]);
  };

  const onChangeMainCategory = async (v: string) => {
    setSubCategories([]);
    setLeafCategories([]);
    let { data } = await vendorsGetSubCategories({
      mainCategorySlug: v,
      kind: "image",
    });
    setSubCategories(data);
  };

  const onChangeSubCategory = async (v: string) => {
    setLeafCategories([]);
    let { data } = await vendorsGetLeafCategories({
      subCategorySlug: v,
    });
    setLeafCategories(data);
  };

  const onSubmitPromptSettingForm = async (data: any) => {
    data.imageAiSlug = props.imageAi.slug;
    setStreaming(true);

    // 明示的に配列の型を指定
    let promises: Promise<AxiosResponse<any, any>>[] = [];

    for (let i = 0; i < data.num; i++) {
      // 各APIリクエストのプロミスを配列に追加
      promises.push(createDallE(data));
    }

    try {
      // すべてのプロミスを並行して実行
      let results = await Promise.all(promises);

      // 各リクエストの結果を処理
      for (const res of results) {
        if (res.data.is_error) {
          toast.error(res.data.message);
          break;
        } else {
          // images配列に新しいURLを追加
          setImages((prevImages) => [res.data, ...prevImages]);
        }
      }
    } catch (error) {
      // エラーハンドリング
      console.error("Error during image generation:", error);
      toast.error("画像の生成中にエラーが発生しました");
    }
    toast.success("画像を生成しました");

    setStreaming(false);
  };

  const removeAiForm = (imageAiForm: ImageAiForm, i: number) => {
    setImageAiForms((prevForms) => {
      return prevForms.filter((prevForm: ImageAiForm, j: number) => {
        if (i != j) {
          return prevForm;
        }
      });
    });
    setDeletedIds([...deletedIds, imageAiForm.id]);
  };

  const updateAiForm = (imageAiForm: ImageAiForm, i: number) => {
    setImageAiForms((prevForms) => {
      return prevForms.map((prevForm: ImageAiForm, j: number) => {
        if (i == j) {
          return imageAiForm;
        } else {
          return prevForm;
        }
      });
    });
  };

  const moveUp = (index) => {
    console.log("moveUp", index);
    if (index === 0) return;
    const newData = [...imageAiForms];
    console.log("newData", newData);
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    console.log("newData", newData);
    setImageAiForms(newData);
  };

  const moveDown = (index) => {
    if (index === imageAiForms.length - 1) return;
    const newData = [...imageAiForms];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setImageAiForms(newData);
  };

  const newAiForm: ImageAiForm = {
    id: "",
    kind: `input_text`,
    inputName: `name${imageAiForms.length}`,
    label: "",
    options: [{ name: "", value: "" }],
    helpText: "",
    col: "6",
  };

  const addAiForms = () => {
    setImageAiForms([...imageAiForms, newAiForm]);
  };

  const submit = async () => {
    let { data } = await vendorBulkPostImageAiForms({
      imageAiForms: imageAiForms,
      imageAiId: props.imageAi.id,
      deletedIds: deletedIds,
    });
    setDeletedIds([]);
    setImageAiForms(data);
    toastSuccess("更新しました");
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  const downloadImage = async (url) => {
    setDownloadingStatus((prev) => ({ ...prev, [url]: true })); // ダウンロード開始

    try {
      const encodedUrl = encodeURIComponent(url);
      await saveAs(`/api/v1/users/proxies?url=${encodedUrl}`);
    } catch (error) {
      console.error("Error during image download", error);
    } finally {
      setTimeout(() => {
        setDownloadingStatus((prev) => ({ ...prev, [url]: false }));
      }, 5000);
    }
  };

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md border">
        <form className="simple_form edit_ai" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6">
            <div>
              <div className="mb-4 string required ai_name">
                <label
                  className="block string required text-sm font-medium text-gray-600"
                  htmlFor="ai_name"
                >
                  名称
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
                  type="text"
                  id="ai_name"
                  {...register("name", { required: true })}
                />
                {errors.name?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            <div>
              {editImage ? (
                <>
                  <div className="mb-4 file required ai_image">
                    <label
                      className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                      htmlFor="ai_image"
                    >
                      アイコン画像
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        *必須
                      </span>
                    </label>
                    <div className="grid grid-cols-12">
                      <input
                        className="col-span-10 w-full text-gray-500 border rounded mt-1 file required"
                        type="file"
                        name="ai[image]"
                        id="ai_image"
                        onChange={handleFileChange}
                      />
                      <button
                        type="button"
                        className="relative col-span-2 lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setEditImage(false)}
                      >
                        キャンセル
                      </button>
                    </div>
                    {errors.image?.type === "required" && (
                      <p className="font-semibold text-sm text-red-500">
                        入力してください
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label
                    className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                    htmlFor="ai_image"
                  >
                    アイコン画像
                  </label>
                  <div className="flex mt-1 items-center mb-4">
                    <img
                      src={props.imageAi.image.url}
                      className="w-20 mr-2 flex-shrink-0 rounded-full bg-gray-300"
                    />
                    <button
                      type="button"
                      className="relative lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setEditImage(true)}
                    >
                      変更
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className="mb-4 text optional ai_intro">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_intro"
                >
                  説明文
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_intro"
                  {...register("intro")}
                ></textarea>
              </div>
            </div>
            <div>
              <div className="mb-4 select required ai_main_category">
                <label
                  className="block select required text-sm font-medium text-gray-600"
                  htmlFor="ai_main_category_id"
                >
                  大カテゴリ
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
                  id="ai_main_category_id"
                  {...register("mainCategorySlug", { required: true })}
                  onChange={(e) => onChangeMainCategory(e.target.value)}
                >
                  {props.mainCategories.map((c, i) => {
                    return (
                      <option key={`main-category-${i}`} value={c.slug}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
                {errors.mainCategorySlug?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            {subCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_sub_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_sub_category_id"
                  >
                    中カテゴリ
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_sub_category_id"
                    {...register("subCategorySlug")}
                    onChange={(e) => onChangeSubCategory(e.target.value)}
                  >
                    {subCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`sub-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            {leafCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_leaf_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_leaf_category_id"
                  ></label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_leaf_category_id"
                    {...register("leafCategorySlug")}
                  >
                    {leafCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`leaf-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            <div>
              <div className="mb-4 text optional ai_prompt">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_prompt"
                >
                  プロンプト
                </label>
                <div className="p-3 bg-gray-100 border mb-2 rounded-md">
                  <h2 className="text-sm">置換ルール</h2>
                  <div className="flex flex-wrap">
                    {props.imageAiForms.map((aiForm, i) => {
                      return (
                        <div
                          className="text-xs mt-1 text-gray-600 mr-2"
                          key={`ai-form-${i}`}
                        >
                          {aiForm.label}: %{aiForm.inputName}%
                        </div>
                      );
                    })}
                  </div>
                </div>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={20}
                  id="ai_prompt"
                  {...register("prompt")}
                ></textarea>
              </div>
            </div>

            <div>
              <div className="mb-4 integer optional ai_order_idx">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_order_idx"
                >
                  並び順
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_order_idx"
                  {...register(`orderIdx`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 enum optional ai_status">
                <label
                  className="block enum optional text-sm font-medium text-gray-600"
                  htmlFor="ai_status"
                >
                  公開ステータス
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   enum optional form-control"
                  id="ai_status"
                  {...register(`status`)}
                >
                  <option value="draft">下書き</option>
                  <option value="published">公開済み</option>
                </select>
              </div>
            </div>

            <div>
              <div className="mb-4 string optional ai_slug">
                <label
                  className="block string optional text-sm font-medium text-gray-600"
                  htmlFor="ai_slug"
                >
                  スラグ
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
                  type="text"
                  id="ai_slug"
                  {...register(`slug`)}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <input
              type="submit"
              name="commit"
              value="送信する"
              className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            />
          </div>
        </form>
      </div>
      {props.imageAi.id != null && (
        <>
          <div className="shadow sm:overflow-hidden sm:rounded-md border mt-10">
            <div className="space-y-10 bg-white px-4 py-5 sm:p-6">
              {imageAiForms.map((aiForm, i) => {
                return (
                  <div key={`ai-form-${i}-${aiForm.kind}-${aiForm.id}`} className="">
                    <FormRow
                      aiForm={aiForm}
                      updateAiForm={(aiForm) => updateAiForm(aiForm, i)}
                      removeAiForm={() => removeAiForm(aiForm, i)}
                      index={i}
                      length={imageAiForms.length}
                      moveUp={moveUp}
                      moveDown={moveDown}
                    />
                  </div>
                );
              })}
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="rounded-md bg-white py-2 px-3 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={addAiForms}
                >
                  + 追加
                </button>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={submit}
              >
                保存する
              </button>
            </div>
          </div>

          <div className="py-3 mt-5">
            <h2 className="text-xl font-bold">Preview</h2>
          </div>
          <div className="shadow sm:overflow-hidden sm:roudned-md border">
            <PromptSettingForm
              submit={(datas) => onSubmitPromptSettingForm(datas)}
              imageAiForms={imageAiForms}
              streaming={streaming}
              cancelStreaming={cancelStreaming}
              remainGenerateCap={100}
            />
          </div>

          <div className="col-span-12">
            <>
              <div className="py-3">
                <h2 className="text-xl font-bold">出力結果</h2>
              </div>
              <div className="lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-4 grid gap-3">
                {images.map((image, i) => (
                  <div
                    className="col-span-4 shadow sm:overflow-hidden sm:roudned-md border p-3"
                    key={`image-${image.source}`}
                  >
                    <img src={image.source} alt={`画像 ${i}`} className="" />
                    <div className="w-full flex items-center pt-2 text-gray-600 font-bold">
                      {/* ダウンロードボタン */}
                      {!downloadingStatus[image.source] ? (
                        <span className="relative group">
                          <button
                            className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2"
                            onClick={() => downloadImage(image.source)}
                          >
                            <i className="fa-solid fa-download cursor-pointer"></i>
                            <span className="hidden lg:inline-block ml-1">
                              ダウンロード
                            </span>
                          </button>
                        </span>
                      ) : (
                        <span className="relative group">
                          <button className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2">
                            <i className="fa-solid fa-download cursor-pointer"></i>
                            <span className="hidden lg:inline-block ml-1">
                              ダウンロード中...
                            </span>
                          </button>
                        </span>
                      )}
                      <span className="relative group">
                        <a
                          className="rounded-full bg-white py-2 px-2 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-2"
                          href={image.source}
                          target="_blank"
                        >
                          <i className="fa-solid fa-arrow-up-right-from-square cursor-pointer"></i>
                          <span className="hidden lg:inline-block ml-1">
                            URLを開く
                          </span>
                        </a>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
};
export default AdminsAisEdit;
