import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { updatePaymentStatusSuccess } from "../../../../libs/api/user";

type Props = {
  stripeKey: string;
  clientSecret: string;
};

type BtnProps = {
  clientSecret: string;
  loading: boolean;
  setLoading: (bool) => void;
};

const Btns: React.FC<BtnProps> = (props) => {
  const stripe = useStripe();

  const confirm = async (e) => {
    e.preventDefault();
    props.setLoading(true);
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      props.clientSecret
    );
    if (error) {
      // エラー処理
      toast.error("エラーが発生しました。");
      props.setLoading(false);
    } else {
      if (paymentIntent.status === "succeeded") {
        // ここでapiでユーザのステータスを変更
        await updatePaymentStatusSuccess();
        toast.success("成功しました。TOPにリダイレクトします");
        setTimeout(() => {
          location.href = "/users";
        }, 1000);
        props.setLoading(false);
      } else {
        // 3Dセキュア認証が完了していない場合の処理
        toast.error("エラーが発生しました。");
        props.setLoading(false);
      }
    }
  };

  return (
    <a
      href="#"
      className="basis-full md:basis-1/3 rounded-lg bg-blue-600 text-white p-3 shadow text-center"
      onClick={confirm}
    >
      3Dセキュア認証を開始
    </a>
  );
};

const UsersPaymentFailed: React.FC<Props> = (props) => {
  const stripePromise = loadStripe(props.stripeKey);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center mt-3">
            <ReactLoading color={`#009dff`} />
          </div>
        </>
      ) : (
        <>
          <Elements stripe={stripePromise}>
            <Btns
              clientSecret={props.clientSecret}
              loading={loading}
              setLoading={setLoading}
            />
          </Elements>
        </>
      )}
    </>
  );
};
export default UsersPaymentFailed;
