import React, { useState } from "react";

const SliderItem = ({ src, altText, active }) => {
  return (
    <img
      src={src}
      alt={altText}
      className={`transition-opacity duration-500 ease-in-out absolute left-0 transform ${
        active ? "opacity-100 scale-100 " : "opacity-0 scale-95"
      } object-cover w-full`}
      style={{
        height: "500px",
        // この値は必要に応じて調整してください
      }}
    />
  );
};

const Slider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="relative">
      <div className="w-full" style={{ height: "500px", width: "400px" }}>
        {images.map((image, index) => (
          <SliderItem
            key={index}
            src={image}
            altText={``}
            active={index === currentSlide}
          />
        ))}
      </div>

      <button
        onClick={nextSlide}
        className="absolute h-6 w-6 inset-y-1/2 right-0 flex items-center justify-center rounded-full border border-gray-200 bg-gray-50 text-gray-600 dark:border-white/10 dark:bg-white/10 dark:text-gray-200"
      >
        <div className="flex h-6 w-6 items-center justify-center text-xs">
          {">"}
        </div>
      </button>
      <button
        onClick={prevSlide}
        className="absolute h-6 w-6 inset-y-1/2 left-0 flex items-center justify-center rounded-full border border-gray-200 bg-gray-50 text-gray-600 dark:border-white/10 dark:bg-white/10 dark:text-gray-200"
      >
        <div className="flex h-6 w-6 items-center justify-center text-xs">
          {"<"}
        </div>
      </button>
    </div>
  );
};

export default Slider;
