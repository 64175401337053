import React from "react";

const Announcements: React.FC = () => {
    return (
        <section className="announcements">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">お知らせ</h2>
                <a href="#" className="text-blue-500 hover:underline">一覧</a>
            </div>
            <ul className="space-y-2">
                <li className="flex justify-between items-center">
                    <span>yyyy/mm/dd</span>
                    <span>カテゴリ</span>
                    <a href="#" className="text-blue-500 hover:underline">
                        サンプルテキストサンプルテキストサンプルテキストサンプルテキスト
                    </a>
                </li>
                <li className="flex justify-between items-center">
                    <span>yyyy/mm/dd</span>
                    <span>カテゴリ</span>
                    <a href="#" className="text-blue-500 hover:underline">
                        サンプルテキストサンプルテキストサンプルテキストサンプルテキスト
                    </a>
                </li>
                <li className="flex justify-between items-center">
                    <span>yyyy/mm/dd</span>
                    <span>カテゴリ</span>
                    <a href="#" className="text-blue-500 hover:underline">
                        サンプルテキストサンプルテキストサンプルテキストサンプルテキスト
                    </a>
                </li>
            </ul>
        </section>
    );
};

export default Announcements;
