import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

const cards = [
  {
    title: "プロンプトメーカー",
    description: "AI画像生成やChatGPTの難しいプロンプトを自動で生成します。",
    headerUrl: "/img/lp/dekirukoto/1-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/prompt.mp4",
  },
  {
    title: "AI画像生成プロンプト",
    description: "画像生成プロンプトを一瞬で作成します。",
    headerUrl: "/img/lp/dekirukoto/mid-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/35tUh_TIN5irWDfk.mp4",
  },
  {
    title: "広告のキャッチコピーを作る",
    description: "商品の特徴を入力するとキャッチコピーを自動で生成します。",
    headerUrl: "/img/lp/dekirukoto/2-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/copy.mp4",
  },
  {
    title: "ブログを書く",
    description: "ブログを執筆します。5000文字の長文ブログも一撃で可能です。",
    headerUrl: "/img/lp/dekirukoto/3-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/blog.mp4",
  },
  {
    title: "小説執筆サポート",
    description: "小説を執筆します。プロットから内容まで自動生成できます。",
    headerUrl: "/img/lp/dekirukoto/5-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/novel.mp4",
  },
  {
    title: "副業占いサポート",
    description: "ユーザーの情報や悩みを入力すると占いの鑑定文を生成できます。",
    headerUrl: "/img/lp/dekirukoto/4-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/uranai.mp4",
  },
  // {
  //   title: 'マンガ制作サポート',
  //   description: 'アイディア出しやセリフ作成などあなたのマンガ制作をサポート。',
  //   headerUrl: '/img/lp/dekirukoto/6.png',
  //   movieUrl: '/img/lp/pv.mp4',
  // },
  {
    title: "YouTube制作サポート",
    description: "タイトルやサムネ文言などあなたのYouTube制作をサポート。",
    headerUrl: "/img/lp/dekirukoto/7-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/youtube.mp4",
  },
  {
    title: "Instagramサポート",
    description: "地味に面倒なハッシュタグを考えたり説明文を作成。",
    headerUrl: "/img/lp/dekirukoto/8-min.png",
    movieUrl: "/img/lp/dekirukoto/douga/ig.mp4",
  },
];

export default function Dekirukoto() {
  const [modalContent, setModalContent] = useState<any | null>(null);

  function openModal(person: any) {
    setModalContent(person);
  }

  function closeModal() {
    setModalContent(null);
  }

  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
      >
        {cards.map((person) => (
          <li
            key={person.title}
            className="col-span-1 p-3 rounded-lg bg-[#343540] shadow hover:opacity-90 cursor-pointer"
            onClick={() => openModal(person)}
          >
            <div className="w-full">
              <img
                className="w-full flex-shrink-0 rounded-tl-[6px] rounded-[6px]  bg-gray-300"
                src={person.headerUrl}
                alt=""
              />
              <div className="px-3 py-3 pb-6">
                <div className="py-3 truncate text-lg font-bold text-[#fff]">
                  {person.title}
                </div>
                <div className="text-md text-[#fff]">{person.description}</div>
              </div>
              <div className="border-2 rounded-full py-2 w-[80%] m-auto mb-3 font-bold text-center text-white text-lg">
                動画で確認
              </div>
            </div>
          </li>
        ))}
      </ul>

      {modalContent && (
        <div
          className="fixed top-0 left-0 w-full flex items-center justify-center h-full bg-black bg-opacity-80 z-50"
          onClick={closeModal}
        >
          <div className="m-2 w-full flex items-center justify-center">
            <div
              className="w-full h-[300px] sm:w-[870px] sm:h-auto bg-[#202123] rounded-lg p-2 sm:p-4 overflow-auto flex flex-col"
              onClick={(e) => e.stopPropagation()}
            >
              <video
                className="w-full h-full"
                src={modalContent.movieUrl}
                title="Video player"
                autoPlay
                loop
                muted
              />
              <button
                className="self-center w-3/5 p-2 mt-4 bg-gray-600 text-white rounded"
                onClick={closeModal}
              >
                閉じる
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
