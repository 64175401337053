import React, { useRef, useMemo, useEffect, useState } from "react";
import AiMessage from "../../../users/ais/chat/messages/Ai";
import { Ai } from "../../../../interfaces/ai";
import { User } from "../../../../interfaces/user";
import { UserSavedText } from "../../../../interfaces/user_saved_text";
import Editor from "../../../../components/users/user_saved_texts/Editor";
type Props = {
  userSavedText: UserSavedText;
  ai: Ai;
  user: User;
  message: string;
};

const UsersSavedTextsShow: React.FC<Props> = (props) => {
  const replacedBody = props.userSavedText.body.replace(
    /(?:\r\n|\r|\n)/g,
    "<br/>"
  );
  const [editedBody, setEditedBody] = useState(
    props.userSavedText.editedBody || props.userSavedText.body
  );
  return (
    <>
      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
        <div className="p-4 pt-6">
          <Editor
            editedBody={editedBody}
            setEditedBody={setEditedBody}
            slug={props.userSavedText.slug}
          />
        </div>
      </div>
    </>
  );
};
export default UsersSavedTextsShow;
