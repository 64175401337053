import React from "react";

import { Ai } from "@/interfaces/ai";
import { toast } from "react-toastify";
import { toggleUserFavorite } from "@/libs/api/users/user_favorite_ai";

import { StarIcon as OutlineStarIcon } from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";

type Props = {
  ai: Ai;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const UsersAi: React.FC<Props> = (props) => {
  const isFavorite = () => {
    return props.favorites.map((f) => f.id).includes(props.ai.id);
  };

  const addFavorite = async (ai: Ai) => {
    props.setFavorites((prevFavorites: Ai[]) => [...prevFavorites, ai]);
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("addFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りに追加しました");
  };

  const removeFavorite = async (ai: Ai) => {
    props.setFavorites((prevFavorites: Ai[]) =>
      prevFavorites.filter((faAi) => faAi.id !== ai.id)
    );
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("removeFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りから解除しました");
  };

  return (
    <>
      <div className="relative h-full">
        <a
          className="border p-5 bg-white flex flex-col rounded-lg h-full"
          href={`/users/ais/${props.ai.slug}`}
        >
          <div className="flex-none">
            <div className="flex items-center justify-center">
              <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center text-xl">
                <img
                  alt=""
                  className="object-cover w-16 h-16 flex-shrink-0 rounded-md bg-gray-300"
                  src={props.ai.image.thumb.url}
                />
              </div>
            </div>
          </div>
          <div className="flex-auto pt-3">
            <h3 className="text-sm font-bold line-clamp-2 break-all">{props.ai.name}</h3>
            <p className="pt-2 text-xs text-gray-500 line-clamp-3 break-all">
              {props.ai.intro}
            </p>
          </div>
        </a>
        <button
          type="button"
          className="absolute top-0 right-0 m-2 p-2 bg-transparent border-none flex items-start justify-end z-50"
          onClick={() =>
            isFavorite() ? removeFavorite(props.ai) : addFavorite(props.ai)
          }
        >
          {isFavorite() ? (
            <SolidStarIcon
              className="h-6 w-6 text-yellow-500"
              aria-hidden="true"
            />
          ) : (
            <OutlineStarIcon
              className="h-6 w-6 text-gray-500"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    </>
  );
};
export default UsersAi;
