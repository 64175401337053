import getClient from "./client";
import { GetLeafCategoriesParams } from "../../interfaces/requests/leaf_category";

/**
 * サブカテゴリー一覧を取得
 */
export const getLeafCategories = async (params: GetLeafCategoriesParams) => {
  return getClient().get(`/leaf_categories.json`, {
    params: params,
  });
};
