import getClient from "../client";

export const adminUpdateWhisperAiPrompt = async (
  slug: string,
  prompt: string
) => {
  return getClient().post(`/admins/whisper_ais/${slug}/update_prompt.json`, {
    prompt: prompt,
  });
};

export const adminCloneWhisperAi = async (slug: string) => {
  return getClient().post(`/admins/whisper_ais/${slug}/clone.json`);
};

export const adminCreateWhisperAi = async (params) => {
  return getClient().post(`/admins/whisper_ais.json`, params);
};

export const adminUpdateWhisperAi = async (slug: string, params) => {
  return getClient().put(`/admins/whisper_ais/${slug}.json`, params);
};
