import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
`

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-out;
`

const SliderImage = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  background-color: #FFFFFF;
  border-radius: 15px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  }
`

const ImageSliderSP = () => {
  const images = [
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/1.png", link: "/users/plans" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/2.png", link: "/articles/ddyoybjqqjtadzzj" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/3.png", link: "https://s.lmes.jp/landing-qr/1661019655-yV8YWZVn?uLand=xp9Mmz" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/4.png", link: "/articles/biwkpmzczwqyuvav" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/5.png", link: "/articles" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/6.png", link: "https://discord.gg/er7EZNTvgh" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/1.png", link: "/users/plans" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/2.png", link: "/articles/ddyoybjqqjtadzzj" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/3.png", link: "https://s.lmes.jp/landing-qr/1661019655-yV8YWZVn?uLand=xp9Mmz" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/4.png", link: "/articles/biwkpmzczwqyuvav" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/5.png", link: "/articles" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/6.png", link: "https://discord.gg/er7EZNTvgh" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/1.png", link: "/users/plans" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/2.png", link: "/articles/ddyoybjqqjtadzzj" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/3.png", link: "https://s.lmes.jp/landing-qr/1661019655-yV8YWZVn?uLand=xp9Mmz" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/4.png", link: "/articles/biwkpmzczwqyuvav" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/5.png", link: "/articles" },
    { src: "https://ordermaid.media/wp-content/uploads/2023/07/6.png", link: "https://discord.gg/er7EZNTvgh" },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [startX, setStartX] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = (e: { clientX: React.SetStateAction<number> }) => {
    setIsDragging(true)
    setStartX(e.clientX)
  }

  const handleMouseMove = (e: { preventDefault: () => void; clientX: any }) => {
    if (!isDragging) return
    e.preventDefault()
    const endX = e.clientX
    if (startX - endX > 50) {
      // drag left
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length)
      setIsDragging(false)
    } else if (startX - endX < -50) {
      // drag right
      setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length)
      setIsDragging(false)
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length)
    }, 3000)
    return () => clearInterval(timer)
  }, [])

  return (
    <SliderContainer
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <SliderWrapper style={{ transform: `translateX(${currentSlide * -100}%)` }}>
        {images.map((image, index) => (
          <SliderImage key={index}>
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <img src={image.src} alt={`slide ${index}`} draggable="false" />
            </a>
          </SliderImage>
        ))}
      </SliderWrapper>
    </SliderContainer>
  )
}

export default ImageSliderSP
