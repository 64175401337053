import React from "react";
// import ReactDOM from 'react-dom'
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
// import { useInView } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import { Container } from "./Container";
const reviews = [
  {
    title: "ブログ記事執筆",
    body: "書きたいテーマやキーワードを入れるだけで記事をすぐに生成します。",
    image: "/img/lp/madechan/1.png",
  },
  {
    title: "文章をリライト",
    body: "既存の文章（500 文字まで）の書き方を変えたり、新たなワードを加えたりすることで、文章に変化を加えます。",
    image: "/img/lp/madechan/2.png",
  },
  {
    title: "【12星座】今日の運勢",
    body: "今日の運勢を12星座別に作成します。",
    image: "/img/lp/madechan/3.png",
  },
  {
    title: "Google広告のタイトル",
    body: "Google 広告のタイトルを提案します。",
    image: "/img/lp/madechan/4.png",
  },
  {
    title: "記事LPの作成",
    body: "商品やサービスの情報をもとに魅力的な記事LPのテキストを作成します。",
    image: "/img/lp/madechan/5.png",
  },
  {
    title: "なりきりブログ",
    body: "あなたの指定した人物になりきってブログを書きます。メルマガの配信などにも活用できます。",
    image: "/img/lp/madechan/6.png",
  },
  {
    title: "別の言い回しを考える",
    body: "キャッチコピーの別の言い回しを考えます",
    image: "/img/lp/madechan/7.png",
  },
  {
    title: "オンラインコース作成",
    body: "オンラインコースのカリキュラム作成を作成します。",
    image: "/img/lp/madechan/8.png",
  },
  {
    title: "記事タイトル",
    body: "記事のタイトルを生成します。",
    image: "/img/lp/madechan/9.png",
  },
  {
    title: "スピ系YouTube語り台本",
    body: "スピリチュアルなYouTubeの語り系台本を書きます",
    image: "/img/lp/madechan/10.png",
  },
  {
    title: "女の子の日常ツイート作成",
    body: "女の子風の日常的なツイートを作成します。",
    image: "/img/lp/madechan/11.png",
  },
  {
    title: "ポエムの生成",
    body: "テーマに沿ったポエムを作ります。",
    image: "/img/lp/madechan/12.png",
  },
  {
    title: "強烈なキャッチコピー",
    body: "商品やサービスの情報を基に強烈なキャッチコピーを提案します",
    image: "/img/lp/madechan/13.png",
  },
  {
    title: "SEOに効果的なキーワード",
    body: "SEOに効果的なキーワードを提案します。",
    image: "/img/lp/madechan/14.png",
  },
  {
    title: "要点を膨らませる",
    body: "要点をベースにして文章を簡単に膨らませます。",
    image: "/img/lp/madechan/15.png",
  },
  {
    title: "【AI画像生成】Canvaの呪文",
    body: "Canvaのtext to Imageで使用するプロンプトを生成します。",
    image: "/img/lp/madechan/16.png",
  },
  {
    title: "絵本の物語を書く",
    body: "指定されたテーマで絵本の物語を考えます。",
    image: "/img/lp/madechan/17.png",
  },
  {
    title: "今日のラッキーアイテム",
    body: "今日のラッキーアイテムのテキストを生成します。占いの集客に使えます。",
    image: "/img/lp/madechan/18.png",
  },
  {
    title: "ビジネスメール制作",
    body: "情報をもとにビジネスメールを代わりに書きます。",
    image: "/img/lp/madechan/19.png",
  },
  {
    title: "恋愛系占い鑑定文",
    body: "出会いや結婚に関する悩みの占いの鑑定文を作成します。",
    image: "/img/lp/madechan/20.png",
  },
  {
    title: "物語の設定を考える",
    body: "指定されたテーマからもしも〇〇だったら？というような物語の設定を考えます。",
    image: "/img/lp/madechan/21.png",
  },
  {
    title: "検索ボリューム",
    body: "キーワードの検索ボリュームを調べます",
    image: "/img/lp/madechan/22.png",
  },
  {
    title: "【血液型】今日の運勢",
    body: "今日の運勢を血液型別に作成します。",
    image: "/img/lp/madechan/23.png",
  },
  {
    title: "Youtubeスカット漫画",
    body: "スカッとする物語をYoutubeの台本として書きます",
    image: "/img/lp/madechan/24.png",
  },
  {
    title: "ベネフィットを考える",
    body: "いただいた情報からベネフィットを提案します。",
    image: "/img/lp/madechan/25.png",
  },
  {
    title: "ブログ記事アイディア",
    body: "テーマとターゲット層を入力すると、記事アイデアを生成します。",
    image: "/img/lp/madechan/26.png",
  },
  {
    title: "Instagramの説明欄のテキスト",
    body: "投稿内容に沿ったInstagramの説明欄の文章を生成します。",
    image: "/img/lp/madechan/27.png",
  },
  {
    title: "SEOに効果的な見出し作成",
    body: "SEOに効果的なブログ記事の見出しを作成します。",
    image: "/img/lp/madechan/28.png",
  },
  {
    title: "クリニック症例記事執筆",
    body: "SEOに効果的なクリニックの症例記事を執筆します。",
    image: "/img/lp/madechan/29.png",
  },
  {
    title: "アスキーアート生成",
    body: "指定されたテーマからアスキーアートを生成します。",
    image: "/img/lp/madechan/30.png",
  },
  {
    title: "YouTube怖い話系",
    body: "リアル系怖い話のショートシナリオを書きます",
    image: "/img/lp/madechan/31.png",
  },
  {
    title: "ビジネスシーンで使える文章に",
    body: "適当な文章をビジネスシーンで使える文章に訂正しアドバイスします。",
    image: "/img/lp/madechan/32.png",
  },
  {
    title: "Google広告の説明文",
    body: "Google広告の説明文を提案します。",
    image: "/img/lp/madechan/33.png",
  },
  {
    title: "メルマガの作成",
    body: "メルマガの文章を作成します。",
    image: "/img/lp/madechan/34.png",
  },
  {
    title: "やることリストの作成",
    body: "todoリストを作成します。",
    image: "/img/lp/madechan/35.png",
  },
  {
    title: "1週間の献立を考える",
    body: "献立を考えるのが苦手なかたへ1週間のご飯の献立を考えます。",
    image: "/img/lp/madechan/36.png",
  },
  {
    title: "ボカロ歌詞生成",
    body: "テーマからボカロ風の歌詞を作成します。",
    image: "/img/lp/madechan/37.png",
  },
  {
    title: "広告の意図を汲み取る",
    body: "広告のテキストの意図を汲み取り解説します。",
    image: "/img/lp/madechan/38.png",
  },
  {
    title: "小説の導入文",
    body: "小説の導入文を生成します。",
    image: "/img/lp/madechan/39.png",
  },
  {
    title: "TikTok説明欄テキスト",
    body: "TikTokのコメント欄に誘導するための説明欄テキストを作成します。",
    image: "/img/lp/madechan/40.png",
  },
  {
    title: "単語の説明",
    body: "調べたい単語の説明をします。",
    image: "/img/lp/madechan/41.png",
  },
  {
    title: "にじジャーニーの呪文",
    body: "AI画像生成のにじジャーニーの呪文を生成します。",
    image: "/img/lp/madechan/42.png",
  },
  {
    title: "ラノベ風タイトル作成",
    body: "ラノベ風のタイトルを作成します。",
    image: "/img/lp/madechan/43.png",
  },
  {
    title: "人物データの作成",
    body: "人物のダミーデータを3件出力します。",
    image: "/img/lp/madechan/44.png",
  },
  {
    title: "漫画広告あらすじ作成",
    body: "漫画広告のあらすじを作成します。",
    image: "/img/lp/madechan/45.png",
  },
  {
    title: "新規サービスの名称",
    body: "サービスの名称を考えます",
    image: "/img/lp/madechan/46.png",
  },
  {
    title: "口コミ返信対応",
    body: "口コミから返信対応のテキストを考えます。",
    image: "/img/lp/madechan/47.png",
  },
  {
    title: "Web広告を配信するまでの一般的な工程を提案",
    body: "商品の情報や広告の目標をを基に・ターゲットの決定・広告媒体の決定・訴求軸の洗い出し・広告文を作成まで提案します。",
    image: "/img/lp/madechan/48.png",
  },
  {
    title: "切り返しトーク",
    body: "お客様から断られそうになった時の切り返しトークを考えます。",
    image: "/img/lp/madechan/49.png",
  },
  {
    title: "一人語り系動画広告",
    body: "一人語りで経験を元にサービスの紹介をするシナリオを書きます。",
    image: "/img/lp/madechan/50.png",
  },
  {
    title: "副業系の動画広告",
    body: "副業系のショート動画広告の台本を作成します。",
    image: "/img/lp/madechan/51.png",
  },
  {
    title: "別の商品の言い回しに",
    body: "200~500文字程度のTikTok広告の台本を別の商品の言い回しに変換します。",
    image: "/img/lp/madechan/52.png",
  },
  {
    title: "ChatGPTのプロンプトを作成",
    body: "ゴールを設定するとプロンプトを生成します。 「どんなプロンプトを作成すればいいかわからない」という人は私を使ってプロンプトの構成を作成しましょう。 作成したプロンプトはあなた自身でカスタマイズしてください。",
    image: "/img/lp/madechan/53.png",
  },
  {
    title: "StableDiffusionの呪文",
    body: "【AI画像生成】StableDiffusionの呪文を生成します。",
    image: "/img/lp/madechan/54.png",
  },
  {
    title: "ツイートの作成",
    body: "テーマからツイートを作成します。",
    image: "/img/lp/madechan/55.png",
  },
  {
    title: "ゆっくり解説",
    body: "ゆっくり解説の台本を作成します。",
    image: "/img/lp/madechan/56.png",
  },
  {
    title: "Youtubeのタイトル作成",
    body: "再生数が増えるYouTubeのタイトルを考えます。",
    image: "/img/lp/madechan/57.png",
  },
  {
    title: "短文を長文に",
    body: "短文を参考にして、長文を生成します。",
    image: "/img/lp/madechan/58.png",
  },
  {
    title: "スピ系のブログ執筆",
    body: "スピリチュアル系のブログを執筆します。",
    image: "/img/lp/madechan/59.png",
  },
  {
    title: "Instagramハッシュタグ生成",
    body: "Instagramの投稿につけるおすすめハッシュタグを生成します。",
    image: "/img/lp/madechan/60.png",
  },
  {
    title: "市場規模を知る",
    body: "お題に対するフェルミ推定を返答します",
    image: "/img/lp/madechan/61.png",
  },
];

function Maid({ title, body, image, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = [
      "0s",
      "0.1s",
      "0.2s",
      "0.3s",
      "0.4s",
      "0.5s",
    ];
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ];
  }, []);

  return (
    <figure
      className={clsx(
        "rounded-3xl bg-white px-4 py-6 shadow-md shadow-gray-900/5",
        className
      )}
      style={{
        animationName: "fade-in",
        animationDelay,
        animationDuration: "0.5s",
        animationTimingFunction: "ease-in-out",
        animationFillMode: "forwards",
      }}
      {...props}
    >
      <div className="bg-white flex items-center space-x-4 rounded-lg transition transform duration-500">
        <div>
          <img
            src={image}
            className="rounded-[50%] h-[80px] w-[80px] min-w-[80px] object-cover"
            width="100%"
            alt=""
          />
        </div>
        <div>
          <h2 className="mb-1 text-base font-semibold leading-6 before:content-[''] after:content-['']">
            {title}
          </h2>
          <p className="text-sm text-gray-600">{body}</p>
        </div>
      </div>
    </figure>
  );
}

console.log(Maid);

function splitArray(array: any[], numParts: number): any[][] {
  let result: any[][] = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function MaidColumn({
  className,
  reviews,
  reviewClassName = () => undefined, // デフォルト値を設定
  msPerPixel = 0,
}: {
  className?: string;
  reviews: any[];
  reviewClassName?: (index: number) => string | undefined;
  msPerPixel?: number;
}) {
  let columnRef = useRef<HTMLDivElement>(null);
  let [columnHeight, setColumnHeight] = useState<number>(0);
  let duration = `${columnHeight * msPerPixel}ms`;
  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      if (columnRef.current) {
        setColumnHeight(columnRef.current.offsetHeight);
      }
    });
    if (columnRef.current) {
      resizeObserver.observe(columnRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration } as React.CSSProperties}
    >
      {reviews.concat(reviews).map(
        (
          review: JSX.IntrinsicAttributes & {
            [x: string]: any;
            title: any;
            body: any;
            category: any;
            image: any;
            className: any;
          },
          reviewIndex: number
        ) => (
          <Maid
            key={reviewIndex}
            aria-hidden={reviewIndex >= reviews.length}
            {...review}
            className={
              typeof reviewClassName === "function"
                ? reviewClassName(reviewIndex % reviews.length) // 非nullアサーションを削除
                : reviewClassName ?? undefined
            }
          />
        )
      )}
    </div>
  );
}

function MaidGrid() {
  const [containerRef, isInView] = useInView({
    threshold: 0.4,
    rootMargin: "0px 0px -20% 0px",
    triggerOnce: true,
  });
  let columns = splitArray(reviews, 3);
  columns = [columns[0], columns[1], splitArray(columns[2], 2)];

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <MaidColumn
            reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
            reviewClassName={(reviewIndex: number) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2][0].length &&
                  "md:hidden",
                reviewIndex >= columns[0].length && "lg:hidden"
              )
            }
            msPerPixel={10}
            className={undefined}
          />
          <MaidColumn
            reviews={[...columns[1], ...columns[2][1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex: number) =>
              clsx(reviewIndex >= columns[1].length && "lg:hidden")
            }
            msPerPixel={15}
          />
          <MaidColumn
            reviews={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-900" />
    </div>
  );
}

const Maids = () => {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="pt-12 pb-16 sm:pt-56 sm:pb-24 md:pt-36"
    >
      <Container className={undefined}>
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-xl font-bold tracking-wider text-white sm:text-6xl">
            200種類以上のテキスト生成AI
          </p>
        </div>
        <p className="text_grade font-bold tracking-wide mx-auto mt-6 max-w-full text-center text-lg sm:text-2xl sm:leading-10 leading-8 text-white">
          用途に応じて、200種類以上のテキスト生成AIが使えます。
          <br />
          ブログ、小説、台本、広告など
          <br className="sp_br" />
          様々なシチュエーションで活用できます。
        </p>
        <MaidGrid />
      </Container>
    </section>
  );
};

export default Maids;
