import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";

type Props = {
  body?: string;
  inputName: string;
  disableImage?: boolean;
  setEditorBody?: (s: string) => void;
  initEditor?: boolean;
  setInitEditor?: (bool: boolean) => void;
};

const InputCkeditor: React.FC<Props> = (props) => {
  const [body, setBody] = useState(props.body);
  const [editorInstance, setEditorInstance] = useState(null);
  var config;
  //if (props.initEditor) {
  useEffect(() => {
    //console.log("props.initEditor", props.initEditor);
    if (editorInstance) {
      editorInstance.setData(props.body);
      props.setInitEditor && props.setInitEditor(false);
    }
  }, [props.initEditor]);
  //}

  const onEditorChange = (e) => {
    if (props.setEditorBody) {
      props.setEditorBody(e.editor.getData());
    } else {
      setBody(e.editor.getData());
    }
  };

  const onEditorReady = (e) => {
    setEditorInstance(e.editor);
  };

  if (props.disableImage) {
    config = { height: "500px" };
  } else {
    config = {
      filebrowserUploadMethod: "ajax",
      extraPlugins: "uploadimage",
      filebrowserUploadUrl: "/api/v1/admins/images",
      extraAllowedContent: "iframe[*]",
      height: "500px",
    };
  }

  return (
    <>
      <CKEditor
        initData={props.body}
        config={config}
        onInstanceReady={onEditorReady}
        onChange={onEditorChange}
      />
      <input type="hidden" name={props.inputName} value={body} />
    </>
  );
};
export default InputCkeditor;
