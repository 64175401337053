import React, { Fragment, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { usersPostNotifyGeneratedText } from "../../../libs/api/users/ai";

type Props = {
  email: string;
  text: string;
  open: boolean;
  setOpen: (bool: boolean) => void;
};
const NotifiyGeneratedText: React.FC<Props> = (props) => {
  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    data.text = props.text;
    try {
      await usersPostNotifyGeneratedText(data);
      toast.success("メールアドレスに送信しました");
      props.setOpen(false);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        // サーバーからのエラーレスポンス
        console.error("Error status:", error.response.status);
        console.error("Error message:", error.response.data.errors);
        toast.error(error.response.data.errors.join(","));
      } else {
        // その他のエラー（axiosの設定など）
        console.error("Error:", error.message);
        toast.error("エラーが発生しました");
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: props.email,
    },
  });

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => props.setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        送信先のメールアドレスを入力して送信してください
                      </Dialog.Title>
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <div className="flex justify-center">
                        <ReactLoading color={`#009dff`} />
                      </div>
                    </>
                  ) : (
                    <>
                      <form
                        className="simple_form edit_user mt-4"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div>
                          <div className="mb-4 string required ai_name">
                            <label className="block string required text-sm font-medium text-gray-600">
                              送信先メールアドレス
                              <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-1">
                                *必須
                              </span>
                            </label>
                            <input
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
                              type="text"
                              {...register("email", { required: true })}
                            />
                            {errors.email?.type === "required" && (
                              <p className="font-semibold text-sm text-red-500">
                                入力してください
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-center">
                          <button
                            type="submit"
                            className="inline-flex w-full md:w-3/4 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            送信する
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NotifiyGeneratedText;
