import React from "react";
import DropdownMenu from "./DropdownMenu";
import { initializeSidebar, getSidebarItems, SidebarItem, SubmenuItem } from "./Items";
import IconWithTitle from "@/components/biz/partials/sidebar/IconWithTitle";

type SidebarProps = {
  mainCategories: any[];
  enableRag: boolean;
  currentOrganizationUserRole: string;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
  initializeSidebar(props.mainCategories);
  const sidebarItems: SidebarItem[] = getSidebarItems();
  const currentPath = window.location.pathname;
  const enhanceSidebarItems = (items: SidebarItem[]): SidebarItem[] => {
    return items.map(item => {
      let enhancedSubItems: SubmenuItem[] = [];
      if (item.items && item.items.length > 0) {
        enhancedSubItems = item.items.map(subItem => ({
          ...subItem,
          isActive: currentPath === subItem.url
        }));
      }
      return {
        ...item,
        items: enhancedSubItems
      };
    });
  };
  const enhancedSidebarItems = enhanceSidebarItems(sidebarItems);
  console.log(enhancedSidebarItems);
  return (
    <nav className="h-screen px-4 pt-2 pb-24 overflow-y-auto text-sm border-r" role="navigation"
         aria-label="Sidebar">
      <div className="pt-3 space-y-4">

        {enhancedSidebarItems.map((item, index) => {
          let isActive = currentPath === item.url;
          if (item.items && item.items.length > 0) {
            isActive = item.items.some(subItem => currentPath.includes(subItem.slug));
          }
          return item.title === "データ管理" && (!props.enableRag || props.currentOrganizationUserRole === "member") ? null : item.items && item.items.length > 0 ? (
            <div key={index}>
              <DropdownMenu
                icon={item.icon}
                title={item.title}
                items={item.items}
              />
            </div>
          ) : (
            <div key={index}>
              <IconWithTitle
                icon={item.icon}
                title={item.title}
                url={item.url}
                isActive={isActive}
              />
            </div>
          )
        })}
      </div>
    </nav>
  );
}

export default Sidebar;
