import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import { PlanDallE } from "../../../interfaces/plan_dall_e";
import { Coupon } from "../../../interfaces/coupon";
import ChangePlanConfirm from "../../partials/modals/ChangePlanConfirm";
import ChangeToFreeConfirm from "../../partials/modals/ChangeToFreeConfirm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ArrowRight } from "lucide-react";

type Props = {
  plans: PlanDallE[];
  user: User;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
  perIntervalText?: string;
};

const UsersPlansTable: React.FC<Props> = (props) => {
  const [planToChange, setPlanToChange] = useState<PlanDallE | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const stripePromise = loadStripe(props.stripeKey);
  const isFree = () => {
    return props.user.planDallE == null;
  };

  const isCurrentPlan = (plan) => {
    return props.user.planDallE?.id == plan.id;
  };

  const planBtn = (plan: PlanDallE) => {
    if (isCurrentPlan(plan)) {
      return currentPlanBtn();
    } else {
      return upgradeBtn(plan);
    }
  };

  const upgradeBtn = (plan: PlanDallE) => {
    return (
      <a
        href={`#`}
        className="text-white bg-gradient-to-r from-blue-600 to-blue-400 hover:opacity-90 focus:ring-4 focus:ring-blue-200 font-bold rounded-full text-lg py-3 text-center my-8 shadow-md"
        onClick={(e) => {
          e.preventDefault();
          handleClickPlanChange(plan);
        }}
      >
        <div className="relative flex justify-center items-center">
          {props.user.planDallE != null ? (
            <div>このオプションに変更</div>
          ) : (
            <div>このオプションを追加</div>
          )}
          <ArrowRight className="absolute right-3 w-6 h-6" />
        </div>
      </a>
    );
  };

  const downgradeFreeBtn = () => {
    return (
      <a
        href={`#`}
        className="text-gray-800 bg-gray-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-8"
        onClick={(e) => {
          e.preventDefault();
          handleClickFree();
        }}
      >
        ダウングレードする
      </a>
    );
  };

  const currentPlanBtn = () => {
    return (
      <a
        href="#"
        className="text-gray-800 bg-gray-300 focus:ring-4 font-bold rounded-lg text-md px-5 py-4 text-center my-8"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        現在のプラン
      </a>
    );
  };

  const handleClickPlanChange = (plan: PlanDallE) => {
    setPlanToChange(plan);
    setOpenModal(true);
  };
  const handleClickFree = () => {
    setOpenFreeModal(true);
  };

  const trialLabel = (trialPeriodDays) => {
    if (trialPeriodDays == null) {
      return <></>;
    }
    if (props.user.hasExperiencedTrial) {
      return <></>;
    }
    if (props.user.hasCard) {
      return <></>;
    }
    return (
      <div className="py-1 px-3 text-sm text-blue-800 bg-blue-100 rounded font-bold">
        <>クラウドワークスユーザー様限定</>
        <br />
        <>{trialPeriodDays}日間無料トライアル</>
      </div>
    );
  };

  const toYen = (amount) => {
    return amount.toLocaleString();
  };

  const toYenPerLimit = (amountPerMonth, limit) => {
    return Math.round(amountPerMonth / limit);
  };

  const orderClassName = (plan: PlanDallE) => {
    if (plan.interval == "year") {
      return "order-1";
    } else {
      if (plan.intervalCount == 6) {
        return "order-2";
      } else {
        return "order-3";
      }
    }
  };

  const planTermName = (plan: PlanDallE) => {
    return plan.name;
  };

  const planLabelName = (plan: PlanDallE) => {
    if (plan.generateLimit == 5000) {
      return "たくさん使うなら！";
    } else if (plan.generateLimit == 3000) {
      return "本格的に使う！";
    } else if (plan.generateLimit == 1000) {
      return "一番お得！";
    } else if (plan.generateLimit == 300) {
      return "少しお得";
    } else {
      return false;
    }
  };

  const planDesc = (plan: PlanDallE) => {
    return (
      "1枚あたり約" +
      toYenPerLimit(plan.amountPerMonth, plan.generateLimit) +
      "円で生成"
    );
  };

  const totalAmount = (plan) => {
    if (plan.interval == "month" && plan.intervalCount == 1) {
      return (
        <>
          <div className="text-xs mt-1 font-normal text-gray-900">
            ※契約は自動更新となります
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="text-xs mt-2 font-normal text-gray-900">
            ¥ {toYen(plan.amount)}(税込){props.perIntervalText}
          </div>
          <div className="text-xs mt-1 font-normal text-gray-900">
            ※契約は自動更新となります
          </div>
        </>
      );
    }
  };

  const couponTag = (plan) => {
    if (plan.interval == "year") {
      if (props.coupon && props.coupon?.discountAmount != 0) {
        return (
          <span
            className="py-1 px-3 text-md bg-blue-100 rounded font-bold text-white"
            style={linearGradientStyle}
          >
            {props.coupon?.discountAmount}円クーポン適応中
          </span>
        );
      }
    }
  };

  const linearGradientStyle = {
    background: "linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)",
  };

  return (
    <>
      <div className="grid gap-8 lg:grid-cols-3 xl:gap-10">
        {isFree() ? (
          <>{/* プレミアムプラン参加してない場合フリー非表示 */}</>
        ) : (
          // プラン加入してる人にフリープラン表示
          <>
            <div
              className={`order-5 flex flex-col p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
            >
              <h3 className="mb-4 text-2xl font-medium text-gray-900">
                無料枠5枚
              </h3>
              <span className="text-5xl font-extrabold text-gray-900">¥ 0</span>
              {isFree() ? <>{currentPlanBtn()}</> : <>{downgradeFreeBtn()}</>}

              <ul role="list" className="space-y-4 text-left text-gray-900">
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-ad w-5"></i>
                  <span>生成累計5枚まで</span>
                </li>
              </ul>
            </div>
          </>
        )}

        {props.plans.map((plan) => {
          return (
            <div
              key={plan.id}
              className={`${orderClassName(
                plan
              )} flex relative flex-col p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
            >
              {couponTag(plan)}
              <div className="mb-2">{trialLabel(plan.trialPeriodDays)}</div>
              <div className="flex items-center justify-between gap-x-4 mb-4">
                <h3
                  id="tier-startup"
                  className="text-lg font-semibold leading-8 text-blue-500"
                >
                  {planTermName(plan)}
                </h3>
                {plan.generateLimit != 100 && (
                  <p className="rounded-sm bg-blue-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    {planLabelName(plan)}
                  </p>
                )}
              </div>
              <div className="flex items-end justify-center">
                <div className="text-3xl font-extrabold text-gray-900">¥</div>
                <div className="text-5xl font-extrabold text-gray-900">
                  {toYen(plan.amountPerMonth)}
                </div>
                <div className="text-lg font-extrabold text-gray-900">
                  &nbsp;/&nbsp;月(税込)
                </div>
              </div>
              {totalAmount(plan)}

              <div className="rounded-sm mt-6 bg-blue-600/10 px-2.5 py-3 text-sm space-y-2 font-semibold text-blue-500">
                <div className="flex items-center">
                  <i className="fa-solid fa-circle-check mr-2"></i>
                  <span>{planDesc(plan)}</span>
                </div>
                <div className="flex items-center">
                  <i className="fa-solid fa-circle-check mr-2"></i>
                  <span>毎月{toYen(plan.generateLimit)}件まで生成可能！</span>
                </div>
                <div className="flex items-center">
                  <i className="fa-solid fa-circle-check mr-2"></i>
                  <span>画像の生成履歴閲覧</span>
                </div>
              </div>

              {planBtn(plan)}
            </div>
          );
        })}
      </div>
      <Elements stripe={stripePromise}>
        {planToChange != null && (
          <ChangePlanConfirm
            open={openModal}
            setOpen={setOpenModal}
            title={
              props.user.planDallE != null
                ? "以下のオプションに変更します。よろしいでしょうか？"
                : "以下のオプションを追加します。よろしいでしょうか？"
            }
            cta={props.user.planDallE != null ? "変更する" : "追加する"}
            plan={planToChange}
            stripeKey={props.stripeKey}
            ThreeDSecureSupported={props.ThreeDSecureSupported}
            user={props.user}
            isDallE={true}
          />
        )}
      </Elements>
      <ChangeToFreeConfirm
        open={openFreeModal}
        setOpen={setOpenFreeModal}
        title={`フリープランに変更します。`}
        message={`本当にダウングレードしますか？`}
        user={props.user}
        isDallE={true}
      />
    </>
  );
};
export default UsersPlansTable;
