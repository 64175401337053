import React, { useState, useEffect } from "react";
import { User } from "../../../../../interfaces/user";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
type Props = {
  user: User;
};
const UsersAiMessageFreeSpeedLimit: React.FC<Props> = (props) => {
  const gradientStyle = {
    background:
      "linear-gradient(90deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%)",
  };

  return (
    <div className="rounded-md bg-yellow-50 p-4" id="free-speed-limit">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-lg font-bold text-[#111]">
            フリープランのため生成速度が遅くなっています
          </h3>
          <div className="mt-2 text-sm text-[#111]">
            <p>
              <span>
                有料プランではフリープランの<strong>約10倍の速度</strong>
                で生成が可能です。
              </span>
            </p>
            <div className="flex flex-wrap items-center justify-center gap-x-4 gap-y-2 mt-2">
              <a
                href="/users/plans"
                style={gradientStyle}
                target="_blank"
                className="flex-none rounded-full bg-white px-5 py-2 text-lg font-semibold text-white shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                いますぐ詳細をみる <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersAiMessageFreeSpeedLimit;
