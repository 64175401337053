import React from "react";

import { ToastContainer } from "react-toastify";

const UsersToast: React.FC = () => {
  return (
    <>
      <ToastContainer />
    </>
  );
};
export default UsersToast;
