import React from "react";
import { AxiosResponse } from "axios";
import Loading from "../../Loading";
import getClient from "../../libs/api/client";

type Props = {
  id: number,
  disabled: boolean
};

type ApiResponseData = {
  disabled: boolean
};

const Disabled: React.FC<Props> = (props) => {
  const [disabled, setDisabled] = React.useState(props.disabled);
  const [nowLoading, setNowLoading] = React.useState(false);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNowLoading(true);

    const client = getClient();
    client
      .put(`biz/users/users/${props.id}/disabled`, {
        disabled: e.target.value, // "1" or "0"
      })
      .then((response: AxiosResponse<ApiResponseData>) => {
        setDisabled(response.data.disabled);
        setNowLoading(false);
      })
      .catch((error) => {
        setNowLoading(false);
      });
  }

  return(
    <>
      <Loading flag={nowLoading} />
      <select value={disabled ? "1" : "0"} onChange={(event) => onChange(event)}>
        <option value="0">有効</option>
        <option value="1">無効</option>
      </select>
    </>
  )
}

export default Disabled;