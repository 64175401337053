import React, { useState } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { updateUserSavedText } from "../../../libs/api/users/user_saved_text"
import { toast } from "react-toastify"
//import ClassicEditor from "../../../ckeditor5-build/classic-editor-with-font";

//import { Essentials } from "@ckeditor/ckeditor5-essentials";
//import { Bold, Italic } from "@ckeditor/ckeditor5-basic-styles";
//import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
/*
const editorConfiguration = {
  plugins: [Essentials, Paragraph],
  toolbar: ["bold", "italic"],
};
*/

type Props = {
  editedBody: string
  setEditedBody: (body: string) => void
  slug: string
}

const UserSavedTexts: React.FC<Props> = (props) => {
  const [isDirty, setIsDirty] = useState(false)
  const update = async () => {
    await updateUserSavedText(props.slug, { editedBody: props.editedBody })
    toast.success("内容を保存しました")
  }
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        //config={editorConfiguration}
        data={props.editedBody}
        config={{
          toolbar: [
            "bold",
            "italic",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
          ], // 'imageUpload'を含めない
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          //console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          //console.log({ event, editor, data });
          props.setEditedBody(data)
          setIsDirty(true)
        }}
      /*
      onBlur={(event, editor) => {
        console.log("Blur.", editor);
      }}
      onFocus={(event, editor) => {
        console.log("Focus.", editor);
      }}*/
      />
      {isDirty && (
        <div className="flex justify-end space-y-2 text-md mx-2 my-2">
          <button
            onClick={() => update()}
            className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span>内容を保存する</span>
          </button>
        </div>
      )}
    </>
  )
}
export default UserSavedTexts
