import React, {useState} from "react";
import {Ai} from "@/interfaces/ai";
import {StandardCardFull} from "@/components/biz/partials/ais/AiCards/StandardCards";

type Props = {
    relatedGeneratedAis: Ai[];
}
const RecentAiMenu: React.FC<Props> = (props) => {
    return (
        <section className="recent-ai-menu">
            <div className="bg-gray-100 p-8">
                <h2 className="text-lg font-semibold mb-4">最近使ったAIメニュー</h2>
                    <ul className="grid sm:grid-cols-4 grid-col-1 gap-4 bg-gray-100 overflow-hidden">
                        {props.relatedGeneratedAis.map((ai, index) => {
                            return (
                              <li className="bg-gray-100 w-full sm:w-auto" key={`recommend-${index}`}>
                                <StandardCardFull ai={ai} />
                              </li>
                            );
                        })}
                    </ul>
            </div>
        </section>
    )
};

export default RecentAiMenu;
