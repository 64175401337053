import React from "react";
import ReactLoading from "react-loading";

type Props = {
  flag: boolean;
};

const Loading: React.FC<Props> = (props) => {
  return props.flag && (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-black opacity-25 flex items-center">
      <ReactLoading type="spin" color="#018cf5" className="mx-auto" />
    </div>
  );
}

export default Loading;