import React, { useState } from "react";

import { User } from "../../../interfaces/user";
import GoogleBtn from "../../../components/partials/buttons/ConnectGoogleDrive";
type Props = {
  user: User;
  clientId: string;
};

const UsersService: React.FC<Props> = (props) => {
  const [user, setUser] = useState(props.user);

  const handleRes = (res) => {
    console.log("handleRes", res);
    const accessToken = res.getAuthResponse().access_token;
    const expiresIn = res.getAuthResponse().expires_in;
    console.log("accessToken", accessToken);
    console.log("expiresIn", expiresIn);
  };

  return (
    <>
      <table className="border-collapse table-auto w-full text-sm mb-10 mt-10">
        <tbody className="bg-white">
          <tr>
            <th className="border-b border-slate-100 p-4 pl-8 text-slate-500 text-left w-1/3">
              Google Drive連携
            </th>

            <td className="border-b border-slate-100 p-4 text-slate-500 flex items-center justify-around">
              {user.googleDriveConnected == false ? (
                <>
                  <span className="mr-4">未連携</span>
                  <GoogleBtn handleRes={handleRes} clientId={props.clientId} />
                </>
              ) : (
                <>
                  <span className="mr-4">連携済み</span>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default UsersService;
