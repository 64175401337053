import React, { useEffect, useState } from "react";
import { Ai } from "../../../interfaces/ai";
import { useForm } from "react-hook-form";
import { destroyUserFavorite } from "../../../libs/api/users/user_favorite_ai";
import { toast } from "react-toastify";

type Props = {
  favorite: Ai;
  delFavorites: (slug: string) => void;
};
const ListFavorite: React.FC<Props> = (props) => {
  const handleDelClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (confirm("削除します、よろしいですか？")) {
      await destroyUserFavorite(props.favorite.slug);
      toast.success("削除しました");
      props.delFavorites(props.favorite.slug);
    }
  };

  return (
    <li>
      <a
        href={`/users/ais/${props.favorite.slug}`}
        className="flex items-center w-full px-2 py-1 text-sm text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 text-sm"
      >
        <div className="w-full flex items-center">
          <img
            src={props.favorite.image.thumb.url}
            alt=""
            className="w-8 h-8 rounded-full mr-2"
          ></img>
          <div>{props.favorite.name}</div>
        </div>
        <div className="w-6">
          <div className="opacity-0 group-hover:opacity-100 h-full items-center flex justify-center">
            <button onClick={handleDelClick}>
              <i className="fas fa-trash text-md text-gray-400 z-40" />
            </button>
          </div>
        </div>
      </a>
    </li>
  );
};

export default ListFavorite;
