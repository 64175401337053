import Rails from "@rails/ujs";

Rails.start();

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-free';
library.add(fas, far, fab);

import "../libs/util";
import "../libs/flowbite.min";

var ReactRailsUJS = require("react_ujs");
import { FavoritesContext } from "../contexts/favoritesContext";
ReactRailsUJS.useContext(FavoritesContext);

const componentsContext = require.context('../components', true, /\.(js|jsx|ts|tsx)$/);

const loadComponent = (componentPath) => {
  try {
    const component = componentsContext(`./${componentPath}.tsx`).default;
    return component;
  } catch (error) {
    console.error(`Could not load component at path: ${componentPath}`, error);
    return null;
  }
};

import { createRoot } from 'react-dom/client';
import React from 'react';

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('[data-react-component]');

  elements.forEach(element => {
    const componentPath = element.dataset.reactComponent;
    const props = JSON.parse(element.dataset.props || '{}');
    const Component = loadComponent(componentPath);

    if (Component) {
      const root = createRoot(element);
      root.render(<Component {...props} />);
    }
  });
});
