import React, { useState } from "react";
type Props = {
  shareText: string;
};
const SnsShare: React.FC<Props> = (props) => {
  const url = encodeURIComponent(location.href);
  const text = encodeURIComponent(props.shareText);

  const openShareWindow = (shareUrl) => {
    window.open(shareUrl, "_blank", "width=600,height=400");
  };

  const handleFacebookShare = () => {
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    openShareWindow(fbShareUrl);
  };

  const handleLineShare = () => {
    const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=${url}&text=${text}`;
    openShareWindow(lineShareUrl);
  };

  const handleTwitterShare = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
    window.open(twitterShareUrl, "_blank", "width=600,height=400");
  };

  return (
    <>
      <aside
        aria-labelledby="sidebar-label"
        className="hidden relative ml-auto xl:block"
      >
        <div className="sticky top-6 p-2 bg-gray-50 rounded-lg border border-gray-200 dark:border-gray-700 dark:bg-gray-800">
          <button
            className="flex items-center p-2 mb-2 text-xl text-center text-gray-500 bg-gray-50 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            data-tooltip-target="share-twitter"
            type="button"
            style={{ color: "#1DA1F2" }}
            onClick={handleTwitterShare}
          >
            <i className="fa-brands fa-twitter"></i>
          </button>
          <button
            className="flex items-center p-2 mb-2 text-xl text-center text-gray-500 bg-gray-50 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            data-tooltip-target="share-facebook"
            type="button"
            onClick={handleFacebookShare}
            style={{
              color: "#4267B2",
            }}
          >
            <i className="fa-brands fa-facebook"></i>
          </button>
          <button
            className="flex items-center p-2 mb-2 text-xl text-center text-gray-500 bg-gray-50 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            data-tooltip-target="share-line"
            type="button"
            onClick={handleLineShare}
            style={{ color: "#06C755" }}
          >
            <i className="fa-brands fa-line"></i>
          </button>
        </div>
      </aside>
      <div
        className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
        id="share-twitter"
        role="tooltip"
        data-popper-placement="top"
        style={{
          position: "absolute",
          inset: "auto auto 0px 0px",
          margin: "0px",
          transform: "translate3d(60.5px, -406px, 0px)",
        }}
      >
        {" "}
        Share on Twitter
        <div
          className="tooltip-arrow"
          data-popper-arrow=""
          style={{
            position: "absolute",
            left: "0px",
            transform: "translate3d(66.5px, 0px, 0px)",
          }}
        ></div>
      </div>
      <div
        className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
        id="share-facebook"
        role="tooltip"
        data-popper-placement="top"
        style={{
          position: "absolute",
          inset: "auto auto 0px 0px",
          margin: "0px",
          transform: "translate3d(51.5px, -362px, 0px)",
        }}
      >
        {" "}
        Share on Facebook
        <div
          className="tooltip-arrow"
          data-popper-arrow=""
          style={{
            position: "absolute",
            left: "0px",
            transform: "translate3d(75px, 0px, 0px)",
          }}
        ></div>
      </div>

      <div
        className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
        id="share-line"
        role="tooltip"
        data-popper-placement="top"
        style={{
          position: "absolute",
          inset: "auto auto 0px 0px",
          margin: "0px",
          transform: "translate3d(51.5px, -362px, 0px)",
        }}
      >
        {" "}
        Share on LINE
        <div
          className="tooltip-arrow"
          data-popper-arrow=""
          style={{
            position: "absolute",
            left: "0px",
            transform: "translate3d(105px, 0px, 0px)",
          }}
        ></div>
      </div>
    </>
  );
};
export default SnsShare;
