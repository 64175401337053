import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import { RefundRequest } from "../../../interfaces/refund_request";
import { toast } from "react-toastify";
import { createRefundRequest } from "../../../libs/api/users/refund_request";
import RefundForm from "../../../components/users/settings/Refund";
import EmptyBankAccountAlert from "../../partials/modals/EmptyBankAccountAlert";

type Props = {
  user: User;
  refundRequest?: RefundRequest;
};

const UserRefundIndex: React.FC<Props> = (props) => {
  console.log("props.user.refundRequest", props.user.refundRequest);
  const [hasBankAccount, setHasBankAccount] = useState(
    props.user.bankAccount != null
  );
  const [hasRefundRequest, setHasRefundRequest] = useState(
    props.user.refundRequest != null
  );
  const [showEmptyBankModal, setShowEmptyBankModal] = useState(false);

  const onSubmit = async (data) => {
    if (hasBankAccount) {
      try {
        await createRefundRequest(data);
        toast.success("登録しました");
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      setShowEmptyBankModal(true);
    }
  };
  return (
    <>
      <>
        <div className="mb-4">
          {hasRefundRequest ? (
            <>返金リクエストを受け付けました</>
          ) : (
            <>
              <a
                href="/users/bank_account"
                target="_blank"
                className="text-blue-600 mr-2"
              >
                振込先銀行口座情報
              </a>
              <span>
                より返金先の口座をご登録の上、以下から返金申請をしてください
              </span>
            </>
          )}
        </div>
        <RefundForm
          onSubmit={onSubmit}
          refundRequest={props.user.refundRequest}
        />
        <EmptyBankAccountAlert
          open={showEmptyBankModal}
          setOpen={setShowEmptyBankModal}
          bankAccount={props.user.bankAccount}
          onSubmit={() => {
            setShowEmptyBankModal(false);
            setHasBankAccount(true);
          }}
        />
      </>
    </>
  );
};
export default UserRefundIndex;
