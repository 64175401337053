import React, { useEffect, useState } from "react";
import { User } from "../../../../interfaces/user";
import { UserPlan } from "../../../../interfaces/user_plan";
import { Plan } from "../../../../interfaces/plan";
import { Coupon } from "../../../../interfaces/coupon";
type Props = {
  plans: Plan[];
  user: User;
  userPlan: UserPlan;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
  serviceType: "text" | "image" | "whisper" | "video" | "voice";
  filterType: "span" | "generateLimit";
};
import PlanTable from "../../../users/omni_plans/Table";
import PlanFaqs from "../../../users/plans/Faqs";
import PlanMenu from "../../../users/omni_plans/MenuSpan";
import PlanMenuLimit from "../../../users/omni_plans/MenuLimit";

const UsersPlans: React.FC<Props> = (props) => {
  const [plans, setPlans] = useState(props.plans);
  const [interval, setInterval] = useState(props.userPlan?.plan?.interval);
  const [intervalCount, setIntervalCount] = useState(
    props.userPlan?.plan?.intervalCount
  );
  const [generateLimit, setGenerateLimit] = useState(
    props.userPlan?.plan?.generateLimit
  );

  const filterPlans = (interval, intervalCount) => {
    const filteredPlans = props.plans.filter(
      (plan) =>
        plan.interval === interval && plan.intervalCount === intervalCount
    );
    setInterval(interval);
    setIntervalCount(intervalCount);
    setPlans(filteredPlans);
  };

  const filterPlansByLimit = (limit) => {
    const filteredPlans = props.plans.filter(
      (plan) => plan.generateLimit === limit
    );
    setGenerateLimit(limit);
    setPlans(filteredPlans);
  };

  useEffect(() => {
    if (props.filterType == "span") {
      if (props.userPlan == null) {
        filterPlans("year", 1);
      } else {
        filterPlans(
          props.userPlan.plan?.interval,
          props.userPlan.plan?.intervalCount
        );
      }
    } else if (props.filterType == "generateLimit") {
      if (props.userPlan == null) {
        let defaultGenerateLimit;
        if (props.serviceType == "whisper") {
          defaultGenerateLimit = 600;
        }
        filterPlansByLimit(defaultGenerateLimit);
      } else {
        filterPlansByLimit(props.userPlan.plan?.generateLimit);
      }
    }
  }, [props.userPlan]);

  const intervalText = () => {
    if (interval == "year") {
      return "一年分一括払い";
    } else if (interval == "month" && intervalCount == 6) {
      return "6ヶ月分一括払い";
    }
  };

  const perIntervalText = () => {
    if (interval == "year") {
      return "の年間ごとのお支払いになります";
    } else if (interval == "month" && intervalCount == 6) {
      return "の6ヶ月ごとのお支払いになります";
    }
  };

  const generateLimits = (): number[] => {
    const uniqueLimits = new Set<number>();
    props.plans.forEach((plan) => {
      uniqueLimits.add(plan.generateLimit);
    });
    return Array.from(uniqueLimits);
  };
  return (
    <>
      {!(interval == "month" && intervalCount == 1) &&
        props.filterType == "span" && (
          <p className="mb-6 text-center">
            {intervalText()}
            になるので月払いプランより一回辺りのお値段がかなりお安い価格になっています。
          </p>
        )}
      <div className="flex justify-center mb-12">
        {props.filterType == "span" ? (
          <PlanMenu
            user={props.user}
            userPlan={props.userPlan}
            filterPlans={filterPlans}
            serviceType={props.serviceType}
          />
        ) : (
          <PlanMenuLimit
            user={props.user}
            userPlan={props.userPlan}
            filterPlansByLimit={filterPlansByLimit}
            serviceType={props.serviceType}
            generateLimit={generateLimit}
            setGenerateLimit={setGenerateLimit}
            generateLimits={generateLimits()}
          />
        )}
      </div>
      <PlanTable
        plans={plans}
        userPlan={props.userPlan}
        user={props.user}
        stripeKey={props.stripeKey}
        ThreeDSecureSupported={props.ThreeDSecureSupported}
        coupon={props.coupon}
        serviceType={props.serviceType}
        filterType={props.filterType}
      />
      <PlanFaqs />
    </>
  );
};
export default UsersPlans;
