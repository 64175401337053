import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ImageSlider from "../ImageSlider";
type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
  images: string[];
};

const AffiliateGuide: React.FC<Props> = (props) => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative max-w-md transform overflow-hidden rounded-lg bg-white shadow-xl transition-all">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="p-4">
                      {props.images.length == 1 ? (
                        <>
                          <img src={props.images[0]} className="w-full" />
                        </>
                      ) : (
                        <>
                          <ImageSlider images={props.images} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-4 sm:flex sm:flex-col">
                  <button
                    type="button"
                    className="bg-gray-100 text-gray-600 font-bold py-3 w-full rounded-full hover:opacity-80"
                    onClick={() => props.setOpen(false)}
                  >
                    閉じる
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default AffiliateGuide;
