import React, { useState, useEffect } from "react";
import { Ai } from "@/interfaces/ai";
import { Meta } from "@/interfaces/meta";
import { MainCategory } from "@/interfaces/main_category";
import { searchAi } from "@/libs/api/vendors/ai";
import { toast } from "react-toastify";
import Pagination from "@/components/partials/navs/Pagination";
import DebounceText from "@/components/partials/forms/input/DebounceText";
import MainCategoryNavigation from "@/components/partials/navs/MainCategoryNavigation";
import Actions from "@/components/ais/list/Actions";

type Props = {
  mainCategories: MainCategory[];
  selectMainCategoryId?: string;
  selectCategoryPath: string;
};

const VendorsIndex: React.FC<Props> = (props) => {
  const [searchAis, setSearchAis] = useState<Ai[]>([]);
  const [searchMeta, setSearchMeta] = useState<Meta | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await searchAi('', props.selectMainCategoryId);
        setSearchAis(response.data.ais);
        setSearchMeta(response.data.meta);
      } catch (error) {
        toast.error("データの取得に失敗しました");
      }
    };

    fetchInitialData();
  }, [props.selectMainCategoryId]);

  const movePage = async (page: number) => {
    try {
      const response = await searchAi(searchText, props.selectMainCategoryId, page);
      setSearchAis(response.data.ais);
      setSearchMeta(response.data.meta);
      updateURLParams('page', response.data.meta.currentPage);
    } catch (error) {
      toast.error('検索に失敗しました');
    }
  };

  const updateURLParams = (key: string, value: string) => {
    const url = new URL(window.location.href);
  
    url.searchParams.set(key, value);
  
    window.history.replaceState({}, '', url.toString());
  };

  const textChanged = async (text: string) => {
    setSearchText(text);
    try {
      const response = await searchAi(text, props.selectMainCategoryId);
      setSearchAis(response.data.ais);
      setSearchMeta(response.data.meta);
      updateURLParams('page', response.data.meta.currentPage);
    } catch (error) {
      toast.error('検索に失敗しました');
    }
  };

  return (
    <>
      <h2 className="text-md font-semibold text-gray-900 mt-3">カテゴリを絞り込む</h2>
      <MainCategoryNavigation
        mainCategories={props.mainCategories}
        selectMainCategoryId={props.selectMainCategoryId}
        selectCategoryPath={props.selectCategoryPath}
      />
      <div className="mt-5 relative inline-block text-left w-full">
        <label
          htmlFor="search"
          className="block text-md font-semibold text-gray-900"
        >
          生成ツールをキーワードで検索
        </label>
        <div className="relative mt-2 flex items-center">
          <DebounceText
            onChange={textChanged}
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="例)ブログ"
            name="search"
          />
        </div>
      </div>
      <div className="relative overflow-x-auto mt-5">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-3 py-3" scope="col">画像</th>
              <th className="px-3 py-3" scope="col">名前</th>
              <th className="px-3 py-3" scope="col">大カテゴリー</th>
              <th className="px-3 py-3" scope="col">中カテゴリー</th>
              <th className="px-3 py-3" scope="col">小カテゴリー</th>
              <th className="px-3 py-3" scope="col">並び順</th>
              <th className="px-3 py-3" scope="col">公開ステータス</th>
              <th className="px-3 py-3" scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            {searchAis.map((ai) => {
              return (
                <tr key={`ai-${ai.id}-${ai.slug}`} className="bg-white border-b">
                  <th className="px-3 py-4 w-24">
                    <img className="w-full max-w-xs flex-shrink-0 rounded-full bg-gray-300" src={ai.image.thumb.url}></img>
                  </th>
                  <th className="px-3 py-4">{ai.name}</th>
                  <td className="px-3 py-4">{ai.mainCategoryName}</td>
                  <td className="px-3 py-4">{ai.subCategoryName}</td>
                  <td className="px-3 py-4">{ai.leafCategoryName}</td>
                  <td className="px-3 py-4">{ai.orderIdx}</td>
                  <td className="px-3 py-4">{ai.status}</td>
                  <td className="px-3 py-4">
                    <Actions
                      editPath={ai.editVendorsAiPath}
                      deletePath={ai.vendorsAiPath}
                      slug={ai.slug}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {searchMeta && (
        <Pagination meta={searchMeta} selectPage={movePage} />
      )}
    </>
  );
};

export default VendorsIndex;
