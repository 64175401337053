import React, { useRef, useMemo, useEffect, useState } from "react";
import Header from "../../users/ais/chat/Header";
import AiMessage from "../../users/ais/chat/messages/Ai";
import UserMessage from "../../users/ais/chat/messages/User";
import PromptSettingForm from "../../users/ais/chat/PromptSettingForm";
import { Ai } from "../../../interfaces/ai";
import { AdMovie } from "../../../interfaces/ad_movie";
import { Message } from "../../../interfaces/message";
import { AiForm } from "../../../interfaces/ai_form";
import { createConsumer } from "@rails/actioncable";
import RegisterAlert from "../../../components/partials/modals/RegisterAlert";
import Ckeditor from "../../../components/partials/forms/input/Ckeditor";
import { FileSignature } from "lucide-react";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  cableEndpoint: string;
  favorites: Ai[];
  adCount: number;
  adMovies: AdMovie[];
};

const UsersAisShow: React.FC<Props> = (props) => {
  const [subscription, setSubscription] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [streaming, setStreaming] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [editorBody, setEditorBody] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [initEditor, setInitEditor] = useState(false);
  const freeSpeedLimitRef = useRef<HTMLDivElement>(null);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const [channelToken, setChannelToken] = useState(generateRandomString(20));
  const [formToken, setFormToken] = useState("");

  const addFavorite = async (ai: Ai) => {
    setModalOpen(true);
  };

  const removeFavorite = async (ai: Ai) => {
    setModalOpen(true);
  };

  const messageBottomRef = useRef(null);

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription != null) {
      subscription.perform("stop", {
        token: formToken,
      });
    }
  };

  const asystMessage = () => {
    if (props.ai.assistMessage == null) {
      return "以下の情報を入れてください";
    } else {
      return props.ai.assistMessage;
    }
  };

  const cable = useMemo(() => createConsumer(props.cableEndpoint), []);

  const onPromptSettingFormSubmit = async (data) => {
    //ここでモーダル表示
    setModalOpen(true);
  };

  // useEffect を用いて subscription のクリーンアップを行う
  useEffect(() => {
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [subscription]);

  const toggleEditor = () => {
    setShowEditor(!showEditor);
  };

  const addEditorBody = (s) => {
    //console.log("addEditorBody", editorBody + "\n" + s);
    const formattedString = (editorBody + "\n" + s).replace(/\n/g, "<br />");
    setEditorBody(formattedString);
    setShowEditor(true);
    //editor初期化
    setInitEditor(true);
  };

  return (
    <>
      <div className="flex-1 justify-between flex flex-col pb-32">
        <Header
          ai={props.ai}
          favorites={[]}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
        />
        <div className="sm:grid sm:grid-cols-2">
          <div className="py-4 md:px-4 pt-10">
            <AiMessage
              ai={props.ai}
              message={asystMessage()}
              streaming={false}
            />
            <div className="mt-10">
              <PromptSettingForm
                submit={(data) => onPromptSettingFormSubmit(data)}
                aiForms={props.aiForms}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
              />
            </div>
          </div>
          <div className="p-4">
            <div ref={freeSpeedLimitRef} />
            <div className="flex flex-col space-y-12 py-5 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
              {messages.map((message, i) => {
                if (message.type == "userSent") {
                  return (
                    <UserMessage
                      message={message.body}
                      key={`user-sent-${i}`}
                    />
                  );
                } else {
                  return (
                    <AiMessage
                      ai={props.ai}
                      message={message.body}
                      showCounter={true}
                      showAction={true}
                      streaming={false}
                      key={`ai-sent-${i}`}
                      addEditorBody={addEditorBody}
                      openSave={openSave}
                      openUpgrade={openUpgrade}
                      setOpenSave={setOpenSave}
                      setOpenUpgrade={setOpenUpgrade}
                      setMessage={setMessage}
                    />
                  );
                }
              })}

              {showMessage && (
                <AiMessage
                  ai={props.ai}
                  message={message}
                  addEditorBody={addEditorBody}
                  showCounter={true}
                  showAction={true}
                  streaming={streaming}
                  openSave={openSave}
                  openUpgrade={openUpgrade}
                  setOpenSave={setOpenSave}
                  setOpenUpgrade={setOpenUpgrade}
                  setMessage={setMessage}
                />
              )}
              <div ref={messageBottomRef}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative justify-end items-center hidden md:block">
        <div className="relative min-h-screen flex justify-end items-center">
          <div
            className={`transform transition-transform duration-500 ease-in-out fixed right-0 top-16 flex justify-end items-start ${
              showEditor ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <button
              onClick={toggleEditor}
              className={`transform transition-transform duration-500 ease-in-out bg-white border p-1 rounded-tl-lg rounded-bl-lg rounded-tr-none rounded-br-none text-xs ${
                showEditor ? "translate-y-0" : "translate-y-full"
              }`}
              style={{ marginLeft: "-30px" }}
            >
              <span className="block">
                {/* <i className="fa-solid fa-save cursor-pointer"></i> */}
                <FileSignature />
              </span>
              {showEditor
                ? "閉じる".split("").map((char, index) => (
                    <span key={index} className="block text-sm">
                      {char}
                    </span>
                  ))
                : "エディタI".split("").map((char, index) => (
                    <span key={index} className="block text-sm">
                      {char}
                    </span>
                  ))}
            </button>
            <div className="top-0">
              <Ckeditor
                inputName={`aiEditor`}
                body={editorBody}
                disableImage={true}
                setEditorBody={setEditorBody}
                initEditor={initEditor}
                setInitEditor={setInitEditor}
              />
              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-full bg-indigo-500 w-full py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  テキストを保存する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditor && (
        <div className="md:hidden">
          <div
            className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => setShowEditor(false)}
          ></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setShowEditor(false)}
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center">
                    <Ckeditor
                      inputName={`aiEditor`}
                      body={editorBody}
                      disableImage={true}
                      setEditorBody={setEditorBody}
                      initEditor={initEditor}
                      setInitEditor={setInitEditor}
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 flex justify-end">
                  <button
                    type="button"
                    className="inline-flex mr-2 justify-center rounded-md bg-gray-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    onClick={() => {
                      setShowEditor(false);
                    }}
                  >
                    閉じる
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    保存する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {streaming && (
        <>
          <div className="fixed bottom-0 left-0 w-full flex justify-center">
            <button
              type="button"
              className="inline-flex mb-5 justify-center items-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={cancelStreaming}
            >
              <i className="fa-solid fa-stop mr-2"></i>
              <span>生成をストップ</span>
            </button>
          </div>
        </>
      )}

      <RegisterAlert open={modalOpen} setOpen={setModalOpen} />
    </>
  );
};
export default UsersAisShow;
