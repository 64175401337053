import React from "react";
import { UseFormRegister } from "react-hook-form";
type Props = {
  register: UseFormRegister<any>;
  errors: {
    [x: string]: any;
  };
};
const InputSelect: React.FC<Props> = (props) => {
  return (
    <>
      <div className={`col-span-6`}>
        <label
          htmlFor="street-address"
          className="block text-sm font-semibold leading-6 text-gray-900"
        >
          使用model
        </label>
        <div className="mt-1">
          <select
            id="col"
            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
            defaultValue={`gpt-3.5-turbo-16k`}
            {...props.register("model", {
              required: true,
            })}
          >
            <option value={`gpt-3.5-turbo-16k`}>gpt-3.5-turbo-16k</option>
            <option value={`gpt-3.5-turbo-0125`}>gpt-3.5-turbo-0125</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default InputSelect;
