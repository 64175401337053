import getClient, { getRootClient } from "./client";
import { PostUpdateUserParams } from "../../interfaces/requests/user";

/**
 * サブカテゴリー一覧を取得
 */
export const updateSelfUser = async (params: PostUpdateUserParams) => {
  return getClient().put(`/users/update_self.json`, params);
};

export const getUnreadNotificationCount = async () => {
  return getClient().get(`/users/unread_notifications_count.json`);
};

export const toggleBulkEmailNotify = async () => {
  return getClient().put(`/users/toggle_bulk_email_notify.json`);
};

export const toggleStepMailNotify = async () => {
  return getClient().put(`/users/toggle_step_mail_notify.json`);
};

export const updatePaymentStatusSuccess = async () => {
  return getClient().put(`/users/update_payment_status_success.json`);
};

export const checkEmailConfirmStatus = async () => {
  return getClient().put(`/users/check_email_confirm_status.json`);
};

export const signOut = async () => {
  try {
    await getRootClient().delete("/users/sign_out");
  } catch (error) {
    console.error("ログアウト中にエラーが発生しました:", error);
  }
};
