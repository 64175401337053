import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toggleFavoriteAPI} from '@/components/biz/store/favoritesSlice';
import {RootState} from "@/components/biz/store/store";
import {StarIcon as SolidStarIcon} from "@heroicons/react/24/solid";
import {StarIcon as OutlineStarIcon} from "@heroicons/react/24/outline";

interface FavoriteButtonProps {
  slug: string;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({slug}) => {
  const dispatch = useDispatch();
  const favorites = useSelector((state: RootState) => state.favorites.favorites);
  const isFavorite = favorites.includes(slug);
  const handleClick = () => {
    dispatch(toggleFavoriteAPI(slug));
  };

  return (
    <button
      onClick={handleClick}
      className="absolute top-0 right-0 m-2 p-2 bg-transparent border-none flex items-start justify-end z-50"
    >
      {isFavorite ? (
        <SolidStarIcon className="h-6 w-6 text-yellow-500" aria-hidden="true"/>
      ) : (
        <OutlineStarIcon className="h-6 w-6 text-gray-500" aria-hidden="true"/>
      )}
    </button>
  );
};

export default FavoriteButton;
