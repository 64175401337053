import React, { useState } from "react";

const cards = [
  {
    title:
      "冗談抜きで国内で一番使い易いと思ったAI文章生成サービス【クラウドワークスAI】",
    role: "YouTube",
    imageUrl: "/img/lp/grid/0.jpg",
    contentsUrl: "https://youtu.be/8sNi8_xlauE",
  },
  {
    title: "オーダーメイドAIでYouTubeショート動画を量産する方法！",
    role: "YouTube",
    imageUrl:
      "https://ordermaid.media/wp-content/uploads/2024/06/maxresdefault-1.jpg",
    contentsUrl: "https://youtu.be/D3DYvSU4nUI",
  },
  {
    title:
      "【生成AIでお金を稼ぐ方法】実際に月40万円以上稼いだ実例を含めた5選を紹介！早い者勝ちだから急いで！",
    role: "YouTube",
    imageUrl:
      "https://ordermaid.media/wp-content/uploads/2024/06/maxresdefault-2.jpg",
    contentsUrl: "https://youtu.be/JwWQ2aAttTs",
  },
  {
    title:
      "【AI画像×ショート動画】今バズりまくっているAI動画の作り方を徹底解説！初心者でも簡単に作成できます。",
    role: "YouTube",
    imageUrl:
      "https://ordermaid.media/wp-content/uploads/2024/06/maxresdefault-3.jpg",
    contentsUrl: "https://youtu.be/5yLi4yeO8bU",
  },
  {
    title: "【TIkTok×AIで稼ぐ】たった10分でAI動画量産して稼ぐAI方法【AI副業】",
    role: "YouTube",
    imageUrl:
      "https://ordermaid.media/wp-content/uploads/2024/06/maxresdefault-4.jpg",
    contentsUrl: "https://youtu.be/d9sJp7VGNyM",
  },
  {
    title:
      "【クラウドワークスAI】知ってる人は10倍効率よく仕事しています!!副業で高額単価のYouTube台本作成やブログ記事執筆などを自動生成してくれるAIツール!!便利すぎてヤバい。。。【ゆっくり】",
    role: "YouTube",
    imageUrl: "/img/lp/grid/2.jpg",
    contentsUrl: "https://youtu.be/oG5jXVTrtx4",
  },
  // {
  //   title:
  //     "【ChatGPT】GPT系新サービス「クラウドワークスAI」！記事作成を自動で楽に【AIツール】【AI】",
  //   role: "YouTube",
  //   imageUrl: "/img/lp/grid/1.jpg",
  //   contentsUrl: "https://youtu.be/HAvgH11q6tI",
  // },
  // More cards...
];

function getEmbedUrl(contentsUrl: string) {
  let videoId = contentsUrl.split("youtu.be/")[1];
  return `https://www.youtube.com/embed/${videoId}`;
}

export default function Grid() {
  const [modalContent, setModalContent] = useState<{
    type: string;
    url: string;
  } | null>(null);

  function openModal(person: any) {
    if (person.role === "YouTube") {
      setModalContent({
        type: "YouTube",
        url: getEmbedUrl(person.contentsUrl),
      });
    } else {
      setModalContent({
        type: "Blog",
        url: person.contentsUrl,
      });
    }
  }

  function closeModal() {
    setModalContent(null);
  }

  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {cards.map((person) => (
          <li
            key={person.title}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-[#343540] shadow hover:opacity-90 cursor-pointer"
            onClick={() => openModal(person)}
          >
            <div className="flex w-full h-full flex-col items-center justify-between">
              <div>
                <img
                  className="w-full flex-shrink-0 rounded-tl-[6px] rounded-tr-[6px] rounded-br-[0px] rounded-bl-[0px] bg-gray-300"
                  src={person.imageUrl}
                  alt=""
                />
                <div className="px-3 py-3 pb-6">
                  <div className="flex items-center space-x-3">
                    <h3 className="py-3 text-md font-bold text-[#fff]">
                      {person.title}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-between items-center px-4 pb-4">
                <span
                  className={`inline-flex flex-shrink-0 items-center rounded-[3px] px-1.5 py-0.5 text-sm font-medium text-[#fff] ${
                    person.role === "YouTube" ? "bg-[#DA1725]" : "bg-[#2ca488]"
                  }`}
                >
                  {person.role}
                </span>
                <div className="border-2 font-bold border-white px-4 py-1 text-white text-sm rounded-lg">
                  詳細をみる
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {modalContent && (
        <div
          className="fixed top-0 left-0 w-full flex items-center justify-center h-full bg-black bg-opacity-80 z-50"
          onClick={closeModal}
        >
          {modalContent && (
            <div className="m-2 w-full flex items-center justify-center">
              {modalContent.type === "YouTube" ? (
                <div
                  className="w-full h-[300px] sm:w-[870px] sm:h-[500px] bg-[#202123] rounded-lg p-2 sm:p-4 overflow-auto flex flex-col"
                  onClick={(e) => e.stopPropagation()}
                >
                  <iframe
                    className="w-full h-full"
                    src={modalContent.url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                  <button
                    className="self-center w-3/5 p-2 mt-4 bg-gray-600 text-white rounded"
                    onClick={closeModal}
                  >
                    閉じる
                  </button>
                </div>
              ) : (
                <div
                  className="w-full h-[600px] sm:w-[450px] sm:h-[800px] bg-[#202123] rounded-lg p-2 sm:p-4 overflow-auto flex flex-col"
                  onClick={(e) => e.stopPropagation()}
                >
                  <iframe className="w-full h-full" src={modalContent.url} />
                  <button
                    className="self-center w-3/5 p-2 mt-4 bg-gray-600 text-white rounded"
                    onClick={closeModal}
                  >
                    閉じる
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
