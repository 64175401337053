import { configureStore, combineReducers } from '@reduxjs/toolkit';
import favoritesReducer from './favoritesSlice';

const rootReducer = combineReducers({
  favorites: favoritesReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
