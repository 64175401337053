import React from "react";

type Props = {
  loading: boolean;
  onClickBtn: () => void;
};

const DowngradeToFree: React.FC<Props> = (props) => {
  return (
    <div className="mt-5 flex justify-center items-center">
      {props.loading ? (
        <>
          <button
            type="button"
            className="inline-flex justify-center rounded-md bg-blue-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            onClick={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            loading...
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            onClick={props.onClickBtn}
          >
            フリープランにダウングレード
          </button>
        </>
      )}
    </div>
  );
};
export default DowngradeToFree;
