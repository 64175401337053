import React from "react";
import { MainCategory } from "@/interfaces/main_category";

type Props = {
  mainCategories: MainCategory[];
  selectMainCategoryId?: string;
  selectCategoryPath: string;
};

const MainCategoryNavigation: React.FC<Props> = (props) => {
  const generateHref = (category: MainCategory | undefined): string => {
    return category ? `${props.selectCategoryPath}?main_category_id=${category.id}` : props.selectCategoryPath;
  };

  return (
    <div className="flex flex-wrap">
      {props.mainCategories.map((category) => (
        <a
          key={`main-category-${category.slug}`}
          className={`rounded-md py-2 px-5 text-sm shadow-sm font-semibold flex items-center flex-wrap mr-3 mt-2 ${
            category.id == props.selectMainCategoryId
              ? 'bg-blue-700 text-white hover:bg-blue-60 shadow-sm'
              : 'bg-white text-gray-600 hover:bg-gray-50 ring-1 ring-inset ring-gray-300'
          }`}
          href={category.id == props.selectMainCategoryId ? generateHref(undefined) : generateHref(category)}
        >
          <span className="mr-2">
            <i className={`${category.fontawesomeClass}`}></i>
          </span>
          <span>{category.name}</span>
        </a>
      ))}
    </div>
  );
};

export default MainCategoryNavigation;
