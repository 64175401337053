import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";

const frequencies = [
  { value: "monthly", label: "月払い", priceSuffix: "/月" },
  { value: "annually", label: "年払い", priceSuffix: "/月" },
];
const tiers = [
  {
    name: "年間プラン",
    id: "2",
    href: "/plan_redirect/premium?amount=35760",
    price: { monthly: "¥2,980", annually: "¥2,483" },
    description: "一括 ¥ 35,760(税込)",
    features: [
      "広告なし",
      "生成速度 → 10倍速",
      "テキスト生成回数 → 無制限",
      "文字数 → 無制限",
      "個別チャットサポート",
      "有料会員限定コンテンツの閲覧",
      "エディター編集機能",
      "生成コンテンツの保存機能",
      "生成コンテンツのメール送信機能",
      "優先サーバー使用",
      "1日約99円で使い放題",
    ],
    btnText: "このプランにする",
    mostPopular: true,
    mostPremium: "一番お得！",
    btnTopText: "最大40%お得なプラン",
  },
  {
    name: "6ヶ月プラン",
    id: "3",
    href: "/plan_redirect/premium?amount=23880",
    price: { monthly: "¥3,980", annually: "¥2,483" },
    description: "一括 ¥ 23,880(税込)",
    features: [
      "広告なし",
      "生成速度 → 10倍速",
      "テキスト生成回数 → 無制限",
      "文字数 → 無制限",
      "個別チャットサポート",
      "有料会員限定コンテンツの閲覧",
      "エディター編集機能",
      "生成コンテンツの保存機能",
      "生成コンテンツのメール送信機能",
      "優先サーバー使用",
      "1日約133円で使い放題",
    ],
    btnText: "このプランにする",
    mostPopular: false,
    mostPremium: "少しお得",
    btnTopText: "最大20%お得なプラン",
  },
  {
    name: "1ヶ月プラン",
    id: "4",
    href: "/plan_redirect/premium?amount=4980",
    price: { monthly: "¥4,980", annually: "¥2,483" },
    description: "通常価格",
    features: [
      "広告なし",
      "生成速度 → 10倍速",
      "テキスト生成回数 → 無制限",
      "文字数 → 無制限",
      "個別チャットサポート",
      "有料会員限定コンテンツの閲覧",
      "エディター編集機能",
      "生成コンテンツの保存機能",
      "生成コンテンツのメール送信機能",
      "優先サーバー使用",
      "1日約166円で使い放題",
    ],
    btnText: "このプランにする",
    mostPopular: false,
    mostPremium: "通常価格",
    btnTopText: "通常価格",
  },
  {
    name: "お試しプラン",
    id: "1",
    href: "/plan_redirect/free",
    price: { monthly: "¥0", annually: "¥0" },
    description: "「まずはお試しでツールを触ってみたい」という方はこちら。",
    features: [
      "広告あり",
      "生成速度 → ゆっくり",
      "テキスト生成回数 → 無制限",
      "文字数 → 無制限",
      "個別チャットサポート",
      "無料会員限定コンテンツの閲覧",
      "機能制限あり",
    ],
    btnText: "無料で使ってみる",
    mostPopular: false,
    mostFree: "無制限使い放題",
    btnTopText: "まずは無料でお試し",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-white">
            ＼ 割引キャンペーン中 ／
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            料金プラン
          </p>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {[...tiers].map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "bg-white/5 ring-2 ring-indigo-500"
                  : "ring-1 ring-white/10",
                "rounded-3xl p-8 xl:p-10"
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className="text-lg font-semibold leading-8 text-white"
                >
                  {tier.name}
                </h3>
                {tier.mostPremium ? (
                  <p className="text_gurade_free rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    {tier.mostPremium}
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-300">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-white">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-300">
                  {frequency.priceSuffix}
                </span>
              </p>
              <span className="rounded-full mt-6 w-full block text-center bg-pink-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                {tier.btnTopText}
              </span>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500"
                    : "bg-indigo-500 text-white hover:bg-white/20 focus-visible:outline-white",
                  "mt-6 block rounded-md py-3 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                )}
              >
                {tier.btnText}
              </a>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-white"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="text-white mt-4 text-center">
          ※画像生成ツールは別途追加オプションになります。
        </div>
      </div>
    </div>
  );
}
