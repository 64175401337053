import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
};

const UpgradeAfterLogin: React.FC<Props> = (props) => {
  const cancelButtonRef = useRef(null);

  const redirectUpgradePage = () => {
    window.location.href = "/users/plans";
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-2xl sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900 text-center mb-3"
                >
                  Premiumプランへのアップグレードのご案内
                </Dialog.Title>
                <div className="flex gap-x-2">
                  <div
                    className={`flex flex-col p-2 md:p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
                  >
                    <div className="flex items-center justify-center gap-x-4 mb-4">
                      <p className="rounded-full bg-gray-200 px-2.5 py-1 text-xs md:text-sm font-semibold leading-5 text-gray-600">
                        Freeプラン
                      </p>
                    </div>
                    <ul
                      role="list"
                      className="text-sm space-y-4 text-left text-gray-900"
                    >
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-ad w-5"></i>
                        <span>広告表示あり</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-person-dress w-5"></i>
                        <span>生成数無制限</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-regular fa-message w-5"></i>
                        <span>文字数→無制限</span>
                      </li>
                      <li className="flex items-center space-x-3 text-gray-400">
                        <i className="fas fa-fast-forward w-5"></i>
                        <span className="line-through">高速文字生成</span>
                      </li>
                      <li className="flex items-center space-x-3 text-gray-400">
                        <i className="fa-solid fa-download w-5"></i>
                        <span className="line-through">
                          生成コンテンツのダウンロード
                        </span>
                      </li>
                      <li className="flex items-center space-x-3 text-gray-400">
                        <i className="fa-solid fa-save w-5"></i>
                        <span className="line-through">
                          生成コンテンツの保存機能
                        </span>
                      </li>
                      <li className="flex items-center space-x-3 text-gray-400">
                        <i className="fa-solid fa-envelope w-5"></i>
                        <span className="line-through">
                          生成コンテンツのメール送信機能
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center items-center">→</div>
                  <div
                    className={`flex flex-col p-2 md:p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
                  >
                    <div className="flex items-center justify-center gap-x-4 mb-4">
                      <p className="rounded-full bg-orange-500 px-2.5 py-1 text-xs md:text-sm font-semibold leading-5 text-white">
                        Premiumプラン
                      </p>
                    </div>
                    <ul
                      role="list"
                      className="text-sm space-y-4 text-left text-gray-900"
                    >
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-ad w-5"></i>
                        <span className="font-bold underline decoration-orange-500 decoration-4">
                          広告表示なし
                        </span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fas fa-fast-forward w-5"></i>
                        <span className="font-bold underline decoration-orange-500 decoration-4">
                          高速文字生成
                        </span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-person-dress w-5"></i>
                        <span>生成数無制限</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-regular fa-message w-5"></i>
                        <span>文字数→無制限</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-download w-5"></i>
                        <span>生成コンテンツのダウンロード</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-save w-5"></i>
                        <span>生成コンテンツの保存機能</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-envelope w-5"></i>
                        <span>生成コンテンツのメール送信機能</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <i className="fa-solid fa-angle-down w-5"></i>
                        <span>作業時間90%削減</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-col">
                  <button
                    type="button"
                    className="bg-gradient-to-r from-blue-500 to-purple-600 font-bold text-white py-3 w-full rounded-full mb-4 hover:opacity-80"
                    onClick={() => redirectUpgradePage()}
                  >
                    プレミアムプランの詳細をみる
                  </button>
                  <button
                    type="button"
                    className="bg-gray-100 text-gray-600 font-bold py-3 w-full rounded-full hover:opacity-80"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default UpgradeAfterLogin;
