import React, { useRef, useMemo, useEffect, useState } from "react";
import Header from "../../../users/ais/image/Header";
import { ImageAi } from "../../../../interfaces/image_ai";
import { User } from "../../../../interfaces/user";
import { ImageAiForm } from "../../../../interfaces/image_ai_form";
import { UserGenImg } from "../../../../interfaces/user_gen_img";
import UpgradeAlert from "../../../../components/partials/modals/UpgradeAlert";
import EmailConfirm from "../../../../components/partials/modals/EmailConfirm";
import AffiliateGuide from "../../../../components/partials/modals/AffiliateGuide";
import Image from "../../../users/user_gen_imgs/Image";
import UserGenImgDetailModal from "../../../partials/modals/UserGenImgDetail";
import { toast } from "react-toastify";
import { createDallE } from "../../../../libs/api/users/dall_e";
import { AxiosResponse } from "axios";
import { checkEmailConfirmStatus } from "../../../../libs/api/user";
import PromptSettingForm from "../../../users/ais/image/PromptSettingForm";
import ImageCard from "../../../users/user_gen_imgs/ImageCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { paginateUserGenImgs } from "../../../../libs/api/user_gen_img";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

interface MetaData {
  currentPage?: number;
  nextPage?: number;
  prevPage?: number;
  totalPage?: number;
  totalCount?: number;
}
interface UserGenImgsResponse {
  userGenImgs: UserGenImg[];
  meta: MetaData;
}

type Props = {
  imageAi: ImageAi;
  imageAiForms: ImageAiForm[];
  user: User;
  userGenImg?: UserGenImg;
};

const UsersAisShowWrapper: React.FC<Props> = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <UsersAisShow {...props} />
    </QueryClientProvider>
  );
};

const UsersAisShow: React.FC<Props> = (props) => {
  const [streaming, setStreaming] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSpamOpen, setSpamModalOpen] = useState(false);
  const [switchModalOpen, setSwitchModalOpen] = useState(false);
  const [emailConfirmModalOpen, setEmailConfirmModalOpen] = useState(false);
  const [guideModalOpen, setGuideModalOpen] = useState(false);
  const [firstGuideModalOpen, setFirstGuideModalOpen] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [modalDetailImg, setModalDetailImg] = useState<UserGenImg>();

  const [images, setImages] = useState<UserGenImg[]>([]);

  const [imageAiForms, setImageAiForms] = useState<ImageAiForm[]>(
    props.imageAiForms
  );

  const [totalImageCount, setTotalImageCount] = useState(
    props.user?.currentImageGenerateCount
  );

  const { ref, inView } = useInView({
    threshold: 0, // 0は要素が少しでも表示されるとトリガーされる
  });

  const getUserGenImgsQuery = useInfiniteQuery<UserGenImgsResponse>(
    ["image_ais", props.imageAi.slug],
    ({ pageParam }) => {
      return paginateUserGenImgs({
        imageAiSlug: props.imageAi.slug,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: {
        userGenImgs: UserGenImg[];
        meta: MetaData;
      }) => {
        //console.log("lastPage?.meta?", lastPage?.meta);
        if (lastPage?.meta?.nextPage == null) {
          //console.log("lastPage?.meta?", lastPage?.meta);
          return undefined;
        } else {
          //console.log("lastPage?.meta?", lastPage?.meta);
          return lastPage?.meta?.nextPage;
        }
      },
      initialData: {
        pages: [{ userGenImgs: [], meta: {} }],
        pageParams: [1],
      },
    }
  );

  const remainGenerateCap = () => {
    return props.user.imageGenerateLimit - totalImageCount;
  };

  const openUserGenImgDetail = (img: UserGenImg) => {
    setModalDetailImg(img);
    setImgModalOpen(true);
  };

  const onSubmitPromptSettingForm = async (data: any) => {
    data.imageAiSlug = props.imageAi.slug;

    if (
      props.user.multipleAccount &&
      !props.user.isPaidDallE &&
      !props.user.ignoreImageLimitCheckForSpam
    ) {
      setSpamModalOpen(true);
      return;
    }

    if (!props.user.emailConfirmed && !props.user.isPaidDallE) {
      let res = await checkEmailConfirmStatus();
      if (!res.data.emailConfirmed) {
        setEmailConfirmModalOpen(true);
        return;
      }
    }

    if (totalImageCount >= props.user.imageGenerateLimit) {
      setModalOpen(true);
      return;
    }

    setStreaming(true);
    let newTotalImageCount = totalImageCount;

    // 明示的に配列の型を指定
    let promises: Promise<AxiosResponse<any, any>>[] = [];

    for (let i = 0; i < data.num; i++) {
      // 各APIリクエストのプロミスを1秒ごとに追加
      await new Promise((resolve) => setTimeout(resolve, 500));
      promises.push(createDallE(data));
    }
    try {
      // すべてのプロミスを並行して実行
      let results = promises.map((promise) =>
        promise
          .then((res) => {
            if (res.data.is_error) {
              toast.error(res.data.message);
            } else {
              // images配列に新しいURLを追加
              setImages((prevImages) => [res.data, ...prevImages]);

              newTotalImageCount++;
              setTotalImageCount(newTotalImageCount);

              let event = new CustomEvent("incrementGenerateCount", {
                detail: newTotalImageCount,
              });
              window.dispatchEvent(event);

              if (newTotalImageCount >= props.user.imageGenerateLimit) {
                setModalOpen(true);
              }
              toast.success("画像を生成しました");
              if (!props.user.isPaidDallE && totalImageCount == 0) {
                setFirstGuideModalOpen(true);
              }
            }
          })
          .catch((error) => {
            // エラーハンドリング
            console.error("Error during image generation:", error);
            toast.error("画像の生成中にエラーが発生しました");
          })
      );

      // ここですべて終わるまで待機
      await Promise.all(results);
    } catch (error) {
      // エラーハンドリング
      console.error("Error during image generation:", error);
      toast.error("画像の生成中にエラーが発生しました");
    }

    setStreaming(false);
  };

  const cancelStreaming = () => {
    setStreaming(false);
  };

  useEffect(() => {
    if (
      totalImageCount >= props.user.imageGenerateLimit &&
      !props.user.multipleAccount
    ) {
      setModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (
      inView &&
      !getUserGenImgsQuery.isFetching &&
      getUserGenImgsQuery.hasNextPage
    ) {
      getUserGenImgsQuery.fetchNextPage();
    }
  }, [inView, getUserGenImgsQuery]);

  const currentUserGenImgs =
    getUserGenImgsQuery.data &&
    getUserGenImgsQuery.data.pages.flatMap((page) => page.userGenImgs);

  return (
    <>
      <div className="flex-1 justify-between flex flex-col pb-32">
        <Header ai={props.imageAi} />
        <div className="sm:grid sm:grid-cols-2 mt-6">
          <div className="col-span-12">
            <div className="shadow sm:overflow-hidden sm:roudned-md border">
              <PromptSettingForm
                submit={(datas) => onSubmitPromptSettingForm(datas)}
                imageAiForms={imageAiForms}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
                remainGenerateCap={remainGenerateCap()}
                user={props.user}
                setSwitchModalOpen={setSwitchModalOpen}
                userGenImg={props.userGenImg}
              />
            </div>
          </div>
          <div className="col-span-12">
            <>
              <div className="py-3">
                <h2 className="text-xl font-bold">出力結果</h2>
              </div>
              <div className="lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-4 grid gap-3">
                {images.map((image, i) => (
                  <ImageCard
                    image={image}
                    imageAi={props.imageAi}
                    setGuideModalOpen={setGuideModalOpen}
                    key={`image-card-${i}`}
                  />
                ))}
              </div>
              <h2 className="text-gray-700 font-bold mr-3 text-lg mt-3 mb-2">
                このAIが生成した画像一覧
              </h2>

              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 5 }}
              >
                <Masonry gutter="5px">
                  {currentUserGenImgs &&
                    currentUserGenImgs.map((userGenImg, i) => {
                      return (
                        <Image
                          image={userGenImg}
                          showCredit={true}
                          onClick={() => openUserGenImgDetail(userGenImg)}
                          key={`user-gen-img-${i}`}
                        />
                      );
                    })}
                  <div ref={ref}>
                    {getUserGenImgsQuery.isFetchingNextPage && (
                      <p>Loading more...</p>
                    )}
                  </div>
                </Masonry>
              </ResponsiveMasonry>
            </>
          </div>

          <UpgradeAlert
            open={modalOpen}
            setOpen={setModalOpen}
            title={`画像生成上限に達しました`}
            message={`現状のプランでは画像生成が「${props.user.imageGenerateLimit}枚」までとなっております。以下のボタンから画像プレミアムプラン加入ページに移動し、アップグレードしてください`}
            redirectLink={`/users/option_plans/image_generator`}
            cta={`画像プレミアムプランを見る`}
          />

          <UpgradeAlert
            open={modalSpamOpen}
            setOpen={setSpamModalOpen}
            title={`画像プレミアムへの登録をお願いします`}
            message={`以下のボタンから画像プレミアムプラン加入ページに移動し、アップグレードしてください`}
            redirectLink={`/users/option_plans/image_generator`}
            cta={`画像プレミアムプランを見る`}
          />

          <UpgradeAlert
            open={switchModalOpen}
            setOpen={setSwitchModalOpen}
            title={`アップグレードが必要です`}
            message={`生成画像の非公開機能は、「画像プレミアムプラン」へのアップグレードが必要となります`}
            redirectLink={`/users/option_plans/image_generator`}
            cta={`画像プレミアムプランを見る`}
          />

          <EmailConfirm
            open={emailConfirmModalOpen}
            setOpen={setEmailConfirmModalOpen}
            title={`メールアドレスの確認をお願いします`}
            message={`ご登録頂いたメールアドレスに、登録確認URLを送信いたしましたのでURLにアクセス後、再度機能をご利用ください`}
          />

          <AffiliateGuide
            open={guideModalOpen}
            setOpen={setGuideModalOpen}
            images={["/img/image_ai_afi.png"]}
          />
          <AffiliateGuide
            open={firstGuideModalOpen}
            setOpen={setFirstGuideModalOpen}
            images={["/img/image_ai_afi2.png", "/img/image_ai_afi.png"]}
          />

          <UserGenImgDetailModal
            open={imgModalOpen}
            setOpen={setImgModalOpen}
            img={modalDetailImg}
            imageAi={props.imageAi}
            user={props.user}
          />
        </div>
      </div>
    </>
  );
};
export default UsersAisShowWrapper;
