import React, { useState, useEffect } from "react";
interface SurveyLabels {
  discoveryMedium: string;
  interests: string;
  earnWithAi: string;
  challenges: string;
  solutions: string;
  benefits: string;
  interestedInAiUsage: string;
}
type Props = {
  labels: SurveyLabels;
  discoveryMedia: Record<string, string>;
  interests: Record<string, string>;
  earnWithAis: Record<string, string>;
  challenges: Record<string, string>;
  solutions: Record<string, string>;
  benefits: Record<string, string>;
  interestedInAiUsages: Record<string, string>;
};
import { useForm } from "react-hook-form";
import { createTrialPromptSurvey } from "../../../libs/api/users/trial_prompt_survey";
import { toast } from "react-toastify";
const UserTrialPromptSurveyForm: React.FC<Props> = (props) => {
  const [showDiscoveryMediaText, setShowDiscoveryMediaText] = useState(true);
  const [showInterestsText, setShowInterestsText] = useState(false);
  const [showChallengesText, setShowChallengesText] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchedDiscoveryMedium = watch("discoveryMedium");
  const watchedInterests = watch("interests");
  const watchedChallenges = watch("challenges");

  useEffect(() => {
    if (watchedDiscoveryMedium && watchedDiscoveryMedium.includes("other")) {
      setShowDiscoveryMediaText(true);
    } else {
      setShowDiscoveryMediaText(false);
    }
  }, [watchedDiscoveryMedium]);

  useEffect(() => {
    if (watchedInterests && watchedInterests.includes("other")) {
      setShowInterestsText(true);
    } else {
      setShowInterestsText(false);
    }
  }, [watchedInterests]);

  useEffect(() => {
    if (watchedChallenges && watchedChallenges.includes("other")) {
      setShowChallengesText(true);
    } else {
      setShowChallengesText(false);
    }
  }, [watchedChallenges]);

  const onSubmit = async (data) => {
    console.log("data", data);
    await createTrialPromptSurvey(data);
    toast.success("アンケートを送信しました。プラン一覧ページに移動します");
    setTimeout(() => {
      location.href = "/users/trial_prompt_surveys/redirect_after_created";
    }, 2000);
  };
  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="location"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.labels.discoveryMedium}
        </label>
        <select
          id="discoveryMedium"
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          {...register("discoveryMedium", { required: true })}
        >
          <option value="">▼ 選択</option>;
          {Object.entries(props.discoveryMedia).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["discoveryMedium"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
        {showDiscoveryMediaText && (
          <div className="mt-3">
            <label className="block mb-2 text-sm font-medium text-gray-900">
              入力してください
            </label>
            <textarea
              id="discoveryMediumText"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              {...register("discoveryMediumText", { required: true })}
            />
          </div>
        )}
        {errors["discoveryMediumText"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            入力してください
          </p>
        )}
      </div>
      <div>
        <label
          htmlFor="location"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.labels.interests}
        </label>
        <select
          {...register("interests", { required: true })}
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">▼ 選択</option>;
          {Object.entries(props.interests).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["interests"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
        {showInterestsText && (
          <div className="mt-3">
            <label className="block mb-2 text-sm font-medium text-gray-900">
              入力してください
            </label>
            <textarea
              id="interestsText"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              {...register("interestsText", { required: true })}
            />
          </div>
        )}
        {errors["interestsText"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            入力してください
          </p>
        )}
      </div>
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.labels.earnWithAi}
        </label>
        <select
          {...register("earnWithAi", { required: true })}
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">▼ 選択</option>;
          {Object.entries(props.earnWithAis).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["earnWithAi"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
      </div>
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.labels.challenges}
        </label>
        <select
          {...register("challenges", { required: true })}
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">▼ 選択</option>
          {Object.entries(props.challenges).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["challenges"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
        {showChallengesText && (
          <div className="mt-3">
            <label className="block mb-2 text-sm font-medium text-gray-900">
              入力してください
            </label>
            <textarea
              id="challengesText"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              {...register("challengesText", { required: true })}
            />
          </div>
        )}
        {errors["challengesText"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            入力してください
          </p>
        )}
      </div>
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.labels.solutions}
        </label>
        <select
          {...register("solutions", { required: true })}
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">▼ 選択</option>
          {Object.entries(props.solutions).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["solutions"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
      </div>
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.labels.benefits}
        </label>
        <select
          {...register("benefits", { required: true })}
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">▼ 選択</option>
          {Object.entries(props.benefits).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["benefits"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
      </div>
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.labels.interestedInAiUsage}
        </label>
        <select
          {...register("interestedInAiUsage", { required: true })}
          className="mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">▼ 選択</option>
          {Object.entries(props.interestedInAiUsages).map(([key, value]) => {
            return <option value={key}>{value}</option>;
          })}
        </select>
        {errors["interestedInAiUsage"]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500 mt-2">
            選択してください
          </p>
        )}
      </div>
      <div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          送信する
        </button>
      </div>
    </form>
  );
};
export default UserTrialPromptSurveyForm;
