import React from "react";
import { WhisperAi } from "../../../../interfaces/whisper_ai";
import { InputField } from "../../../../interfaces/input_field";
import { MainCategory } from "../../../../interfaces/main_category";
import { Ai } from "../../../../interfaces/ai";

import { adminsGetSubCategories } from "../../../../libs/api/admins/sub_category";
import { adminsGetLeafCategories } from "../../../../libs/api/admins/leaf_category";
import {
  adminCreateWhisperAi,
  adminUpdateWhisperAi,
} from "../../../../libs/api/admins/whisper_ai";
import { adminBulkPostInputFields } from "../../../../libs/api/admins/input_field";

import CommonEditor from "../../../ais/whisper/Edit";

type Props = {
  whisperAi: WhisperAi;
  inputFields: InputField[];
  mainCategories: MainCategory[];
  textAis: Ai[];
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  return (
    <CommonEditor
      whisperAi={props.whisperAi}
      mainCategories={props.mainCategories}
      getSubCategories={adminsGetSubCategories}
      getLeafCategories={adminsGetLeafCategories}
      createWhisperAi={adminCreateWhisperAi}
      updateWhisperAi={adminUpdateWhisperAi}
      bulkPostInputFields={adminBulkPostInputFields}
      textAis={props.textAis}
    />
  );
};
export default AdminsAisEdit;
