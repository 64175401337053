import React, { useState } from "react";
import { createOgpSkinAiImage } from "../../../libs/api/users/ogp_skin";
import { ImageAi } from "../../../interfaces/image_ai";
import { UserGenImg } from "../../../interfaces/user_gen_img";
type Props = {
  userGenImg: UserGenImg;
  imageAi: ImageAi;
};
const UsersImageAiTweetBtn: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const onClick = async (e: any) => {
    setLoading(true);
    e.preventDefault;
    // 仮のウィンドウを開く
    const newWindow = window.open(
      "/plains/loading",
      "_blank",
      "width=500,height=400"
    );
    if (!newWindow) {
      setLoading(false);
      return; // ウィンドウが開けない場合は処理を中止
    }
    try {
      let { data } = await createOgpSkinAiImage(props);
      // ツイートの内容をフォーマットします
      const tweetText = encodeURIComponent(
        `AI画像生成なら、クラウドワークスAI。\n\n` +
          `#クラウドワークスAI\n` +
          `#AI画像生成\n` +
          `#AIイラスト\n` +
          `#ad\n`
      );
      const url = encodeURIComponent(
        `https://ordermaid.ai/image_ais/${props.imageAi.slug}/?ogp=${data.slug}&utm_campaign=img_share`
      );

      // TwitterのWeb Intent URLを作成します
      const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${url}`;

      // 新しいウィンドウでTwitterのツイートダイアログを開きます
      //window.open(twitterIntentUrl, "_blank", "width=500,height=400");
      newWindow.location.href = twitterIntentUrl;
    } catch (error) {
      newWindow.close(); // エラーが発生した場合はウィンドウを閉じる
      console.error("Error:", error);
    }
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <div className="text-center">
          <a
            className="inline-flex cursor-pointer items-center justify-center text-gray-600 bg-gray-200 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55"
            rel="nofollow"
          >
            loading...
          </a>
        </div>
      ) : (
        <div className="text-center">
          <a
            className="inline-flex cursor-pointer items-center justify-center text-white bg-gradient-to-r from-yellow-500 to-orange-400 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-xs px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55"
            rel="nofollow"
            onClick={onClick}
          >
            <svg
              className="svg-inline--fa fa-x-twitter mr-2"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="x-twitter"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
              ></path>
            </svg>
            シェアしてアフィリエイト
          </a>
        </div>
      )}
    </>
  );
};
export default UsersImageAiTweetBtn;
