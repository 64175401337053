import React from "react";
import { AiForm } from "../../../../interfaces/ai_form";
import { UseFormRegister } from "react-hook-form";
type Props = {
  aiForm: AiForm;
  register: UseFormRegister<any>;
  errors: {
    [x: string]: any;
  };
  defaultValue?: string;
};
const InputSelect: React.FC<Props> = (props) => {
  if (props.aiForm.inputName == "") {
    return null;
  }
  return (
    <>
      <div className={`col-span-${props.aiForm.col}`}>
        <label
          htmlFor="street-address"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.aiForm.label}
        </label>
        <div className="mt-1">
          <select
            id="col"
            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
            defaultValue={props.defaultValue}
            {...props.register(props.aiForm.inputName, { required: true })}
          >
            {props.aiForm.options.map((option, i) => {
              return (
                <option key={`kind-option-${i}`} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
        {props.errors[`${props.aiForm.inputName}`]?.type === "required" && (
          <p className="font-semibold text-sm text-red-400">選択してください</p>
        )}
        <p className="mt-1 text-xs text-gray-500">{props.aiForm.helpText}</p>
      </div>
    </>
  );
};

export default InputSelect;
