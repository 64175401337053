import React, {useState} from "react";
import {Ai} from "@/interfaces/ai";
import {WideCardFull} from "@/components/biz/partials/ais/AiCards/WideCards";

type Props = {
    generalAis: Ai[];
    salesAis: Ai[];
    marketingAis: Ai[];
};

const rankColor = (index: number): string => {
    switch (index) {
        case 0:
            return "bg-yellow-500";
        case 1:
            return "bg-gray-500";
        case 2:
            return "bg-yellow-700";
        default:
            return "bg-gray-400";
    }
};

const TabContent: React.FC<{ ais: Ai[] }> = ({ais}) => (
    <ul className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {ais.map((ai, index) => (
            <li className="flex items-center w-full" key={`rankin-${index}`}>
                <div className="pr-4">
                    <span
                        className={`flex items-center justify-center w-8 h-8 text-white rounded-full ${rankColor(index)}`}>
                        {index + 1}
                    </span>
                </div>
                <div className="flex-grow min-w-[300px]">
                    <WideCardFull ai={ai} />
                </div>
            </li>
        ))}
    </ul>
);

type TabButtonProps = {
    isActive: boolean;
    onClick: () => void;
    children: React.ReactNode;
};

const TabButton: React.FC<TabButtonProps> = ({isActive, onClick, children}) => {
    const buttonClass = `w-full py-2 text-center ${isActive ? "border-b-2 border-purple-500 text-cwaipurple-800" : "text-gray-600"}`;
    return (
        <button className={buttonClass} onClick={onClick}>
            <div className="w-full h-full">{children}</div>
        </button>
    );
};

const Ranking: React.FC<Props> = ({generalAis, salesAis, marketingAis}) => {
    const [activeTab, setActiveTab] = useState("general");
    return (
        <section className="ranking">
            <div className="bg-gray-100 p-8">
                <h2 className="text-lg font-semibold mb-4">人気ランキング</h2>
                <div>
                    <ul className="flex justify-between border-b">
                        <li className="w-full">
                            <TabButton isActive={activeTab === "general"} onClick={() => setActiveTab("general")}>
                                総合
                            </TabButton>
                        </li>
                        <li className="w-full">
                            <TabButton isActive={activeTab === "sales"} onClick={() => setActiveTab("sales")}>
                                営業
                            </TabButton>
                        </li>
                        <li className="w-full">
                            <TabButton isActive={activeTab === "marketing"} onClick={() => setActiveTab("marketing")}>
                                マーケティング
                            </TabButton>
                        </li>
                    </ul>
                </div>
                <div className="mt-6">
                    {activeTab === "general" && <TabContent ais={generalAis}/>}
                    {activeTab === "sales" && <TabContent ais={salesAis}/>}
                    {activeTab === "marketing" && <TabContent ais={marketingAis}/>}
                </div>
            </div>
        </section>
    );
};

export default Ranking;
