import React, { useState, useEffect } from "react";

import { MainCategory } from "../../../../interfaces/main_category";
import { SubCategory } from "../../../../interfaces/sub_category";
import { LeafCategory } from "../../../../interfaces/leaf_category";
import { ImageAi } from "../../../../interfaces/image_ai";
import CategoryBtn from "../../../users/index/CategoryBtn";
import ImageAiCard from "../../../users/index/ImageAiCard";
import { usersGetSubCategories } from "../../../../libs/api/users/sub_category";
import { usersGetLeafCategories } from "../../../../libs/api/users/leaf_category";
import { usersPaginateImageAis } from "../../../../libs/api/users/image_ai";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

type Props = {
  mainCategories: [MainCategory];
  imageAis: ImageAi[];
};

interface MetaData {
  currentPage?: number;
  nextPage?: number;
  prevPage?: number;
  totalPage?: number;
  totalCount?: number;
}
interface ImageAisResponse {
  image_ais: ImageAi[];
  meta: MetaData;
}

const UsersAisIndexWrapper: React.FC<Props> = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <UsersAisIndex {...props} />
    </QueryClientProvider>
  );
};

const UsersAisIndex: React.FC<Props> = (props) => {
  const [selectedMainCategory, setSelectedMainCategory] =
    useState<MainCategory | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SubCategory | null>(null);
  const [selectedLeafCategory, setSelectedLeafCategory] =
    useState<LeafCategory | null>(null);

  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [leafCategories, setLeafCategories] = useState<LeafCategory[]>([]);
  const [imageAis, setImageAis] = useState(props.imageAis);

  const { ref, inView } = useInView({
    threshold: 0, // 0は要素が少しでも表示されるとトリガーされる
  });

  const queryClient = new QueryClient();

  const onSelectMainCategory = async (category: MainCategory | null) => {
    //子カテゴリはクリア
    setSubCategories([]);
    setLeafCategories([]);
    //選択済みカテゴリ系もクリア
    setSelectedSubCategory(null);
    setSelectedLeafCategory(null);
    //ここで選択
    setSelectedMainCategory(category);
    if (category != null) {
      let { data } = await usersGetSubCategories({
        mainCategorySlug: category?.slug,
      });
      //サブカテゴリ一覧をセット
      setSubCategories(data);
    }
    // クエリを無効化して再フェッチを強制
    queryClient.invalidateQueries([
      "image_ais",
      category?.slug, // 新しく選択されたmainCategoryのslug
      null,
      null,
    ]);
  };

  const onSelectSubCategory = async (category: SubCategory | null) => {
    //子カテゴリはクリア
    setLeafCategories([]);
    //選択済みカテゴリ系もクリア
    setSelectedLeafCategory(null);
    //ここで選択
    setSelectedSubCategory(category);
    if (category != null) {
      let { data } = await usersGetLeafCategories({
        subCategorySlug: category?.slug,
      });
      //サブカテゴリ一覧をセット
      setLeafCategories(data);
    }
    queryClient.invalidateQueries([
      "image_ais",
      selectedMainCategory?.slug,
      category?.slug, // 新しく選択されたsubCategoryのslug
      null,
    ]);
  };

  const onSelectLeafCategory = async (category: LeafCategory | null) => {
    setSelectedLeafCategory(category);

    // クエリを無効化して再フェッチを強制
    queryClient.invalidateQueries([
      "image_ais",
      selectedMainCategory?.slug,
      selectedSubCategory?.slug,
      category?.slug, // 新しく選択されたleafCategoryのslug
    ]);
  };

  const getAisQuery = useInfiniteQuery<ImageAisResponse>(
    [
      "image_ais",
      selectedMainCategory?.slug,
      selectedSubCategory?.slug,
      selectedLeafCategory?.slug,
    ],
    ({ pageParam = 1 }) => {
      return usersPaginateImageAis({
        mainCategorySlug: selectedMainCategory?.slug,
        subCategorySlug: selectedSubCategory?.slug,
        leafCategorySlug: selectedLeafCategory?.slug,
        page: pageParam,
        perPage: 20,
      });
    },
    {
      getNextPageParam: (lastPage: {
        image_ais: ImageAi[];
        meta: MetaData;
      }) => {
        if (lastPage?.meta?.nextPage == null) {
          return undefined;
        } else {
          return lastPage?.meta?.nextPage;
        }
      },
      initialData: {
        pages: [{ image_ais: props.imageAis, meta: {} }],
        pageParams: [1],
      },
    }
  );

  useEffect(() => {
    if (inView && !getAisQuery.isFetching && getAisQuery.hasNextPage) {
      getAisQuery.fetchNextPage();
    }
  }, [inView, getAisQuery]);
  const currentAis =
    getAisQuery.data &&
    getAisQuery.data.pages.flatMap((page) => page.image_ais);

  return (
    <>
      <div className="mt-4">
        <h2 className="text-md font-semibold text-gray-900">
          カテゴリを絞り込む
        </h2>
      </div>
      <div className="flex flex-wrap">
        {props.mainCategories.map((category) => {
          return (
            <CategoryBtn
              key={`main-category-${category.slug}`}
              setSelected={onSelectMainCategory}
              category={category}
              selected={selectedMainCategory}
            />
          );
        })}
      </div>
      {subCategories.length > 0 && (
        <>
          <div className="mt-5">
            <h2 className="text-md font-semibold text-gray-900">
              中カテゴリを絞り込む
            </h2>
          </div>
          <div className="flex flex-wrap">
            {subCategories.map((category) => {
              return (
                <CategoryBtn
                  key={`sub-category-${category.slug}`}
                  setSelected={onSelectSubCategory}
                  category={category}
                  selected={selectedSubCategory}
                />
              );
            })}
          </div>
        </>
      )}
      {leafCategories.length > 0 && (
        <>
          <div className="mt-5">
            <h2 className="text-md font-semibold text-gray-900">
              小カテゴリを絞り込む
            </h2>
          </div>
          <div className="flex flex-wrap">
            {leafCategories.map((category) => {
              return (
                <CategoryBtn
                  key={`leaf-category-${category.slug}`}
                  setSelected={onSelectLeafCategory}
                  category={category}
                  selected={selectedLeafCategory}
                />
              );
            })}
          </div>
        </>
      )}
      {currentAis && currentAis.length > 0 && (
        <div className="mt-10">
          <div
            className="gap-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
            role="list"
          >
            {currentAis.map((ai) => {
              return <ImageAiCard key={`ai-${ai.slug}`} ai={ai} />;
            })}
          </div>
        </div>
      )}
      <div ref={ref}>
        {getAisQuery.isFetchingNextPage && <p>Loading more...</p>}
      </div>
    </>
  );
};
export default UsersAisIndexWrapper;
