import React, { useEffect } from "react";
type Props = {
  clientId: string;
  slotId: string;
};
const AdsenceCard: React.FC<Props> = (props) => {
  useEffect(() => {
    if (window.adsbygoogle) {
      window.adsbygoogle.push({});
    }
  }, [props.clientId, props.slotId]);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client={props.clientId}
      data-ad-slot={props.slotId}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};
export default AdsenceCard;
