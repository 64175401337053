import getClient from "./client";
import { RagFile } from "../../../../interfaces/biz/rag_file";

export const createRagFile = async (file: File | null) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }

  return getClient().post(`/biz/rag_files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const destroyRagFile = async (deleteFile: RagFile) => {
  return getClient().delete(`/biz/rag_files/${deleteFile.id}`);
};
