import React, { useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import { Ai } from "../../../../../interfaces/ai";
import { User } from "../../../../../interfaces/user";
import ActionBtns from "./ActionBtns";
type Props = {
  ai: Ai;
  user?: User;
  message: string;
  showCounter?: boolean;
  showAction?: boolean;
  hideActionEditor?: boolean;
  streaming: boolean;
  setMessage?: (s: any) => void;
  addEditorBody?: (s: any) => void;
  openSave?: boolean;
  setOpenSave?: (s: any) => void;
  openUpgrade?: boolean;
  setOpenUpgrade?: (s: any) => void;
  editable?: boolean;
};

const UserAiChatMessagesAi: React.FC<Props> = (props) => {
  //console.log("props", props);
  if (props.ai == undefined) {
    return <></>;
  }
  const [loading, setLoading] = useState(false);

  const spanRef = useRef<HTMLTextAreaElement>(null);

  const messageClassName = () => {
    if (props.streaming) {
      return "px-4 py-2 rounded-lg w-full inline-block rounded-tl-none bg-[#f7f9f9] text-gray-800 whitespace-pre-wrap break-words streaming";
    } else {
      return "px-4 py-2 rounded-lg w-full inline-block rounded-tl-none border bg-[#f7f9f9] text-gray-800 whitespace-pre-wrap break-words";
    }
  };

  return (
    <>
      <div className="chat-message">
        <div className="flex items-start">
          <div className="flex flex-col space-y-2 text-md mx-2 order-2 items-start w-full">
            <div className="-mb-4 w-full">
              {props.showAction && (
                <ActionBtns
                  ai={props.ai}
                  user={props.user}
                  message={props.message}
                  addEditorBody={props.addEditorBody}
                  openSave={props.openSave}
                  openUpgrade={props.openUpgrade}
                  setOpenSave={props.setOpenSave}
                  setOpenUpgrade={props.setOpenUpgrade}
                  hideActionEditor={props.hideActionEditor}
                />
              )}

              <span
                ref={spanRef}
                className={messageClassName()}
                contentEditable={props.editable}
              >
                {props.message}
                {loading && (
                  <div className="w-16">
                    <ReactLoading
                      type={`balls`}
                      color={`#8dabd8`}
                      height={"10%"}
                      width={"30%"}
                    />
                  </div>
                )}
              </span>

              {props.showCounter && (
                <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                  文字数: {props.message.length}
                </div>
              )}
            </div>
          </div>
          <img
            src={props.ai.image.thumb.url}
            alt=""
            className="w-12 h-12 rounded-full order-1 -mt-2 object-cover"
          />
        </div>
      </div>
    </>
  );
};

export default UserAiChatMessagesAi;
