import React, { useState, useEffect } from "react";

type Props = {
  onChange: (string) => void;
  className: string;
  placeholder: string;
  name: string;
};

const DebounceText: React.FC<Props> = (props) => {
  const [inputText, setinputText] = useState<string>('');
  const [debouncedText, setDebouncedText] = useState<string>(inputText);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedText(inputText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputText]);

  useEffect(() => {
    props.onChange(debouncedText);
  }, [debouncedText]);

  const textChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setinputText(e.target.value);
  };

  return (
    <>
      <input
        type="text"
        onChange={textChanged}
        className={props.className}
        placeholder={props.placeholder}
        name={props.name}
      />
    </>
  );
};

export default DebounceText;
