import React from "react";
type Props = {
  slug: string;
  onCopy: () => void;
};
const AffiliateCode: React.FC<Props> = (props) => {
  return (
    <div>
      <div className="mt-2 flex rounded-md shadow-sm">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
          {window.location.protocol}//{window.location.host}/d/
        </span>
        <input
          type="text"
          name="affiliate_url"
          id="affiliate_url"
          className="block min-w-0 flex-1 rounded-none border-0 px-2 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={props.slug}
        />
        <button
          type="button"
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={props.onCopy}
        >
          コピー
        </button>
      </div>
    </div>
  );
};

export default AffiliateCode;
