import getClient from "../client";
import { UsersAi, UsersAiDetail } from "../../../interfaces/users_ai";
import { AxiosResponse } from "axios";

export const usersTextGenerateHistories = async (
  slug: string
): Promise<AxiosResponse<{ date: string; usersAis: UsersAi[] }[]>> => {
  return getClient().get(`/users/ais/${slug}/users_ais/history.json`);
};

export const usersOthersTextGenerateHistories = async (
  slug: string
): Promise<AxiosResponse<{ date: string; usersAis: UsersAi[] }[]>> => {
  return getClient().get(`/users/ais/${slug}/users_ais/others_history.json`);
};

export const usersTextGenerateDetail = async (
  slug: string,
  id: string
): Promise<AxiosResponse<UsersAiDetail>> => {
  return getClient().get(`/users/ais/${slug}/users_ais/${id}.json`);
};

export const updateUsersTextGenerate = async (
  slug: string,
  content: string | null,
  formToken: string
): Promise<AxiosResponse<UsersAiDetail>> => {
  return getClient().put(
    `/users/ais/${slug}/users_ais/update_generated_text.json`,
    { content: content, formToken: formToken }
  );
};

export const updateGenerationResultPrivateFlag = async (
  // 生成結果ID
  id: number,
  privateFlag: boolean
): Promise<AxiosResponse> => {
  return getClient().put(
    `/biz/generation_results/${id}.json`,
    { private: privateFlag }
  );
};
