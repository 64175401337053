import React from "react";
import { useForm } from "react-hook-form";
import { User } from "../../../../interfaces/user";

type Props = {
  submit: (data: any) => void;
  streaming: boolean;
  cancelStreaming?: () => void;
  user?: User;
};

const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const style_options = [
    "実写",
    "マンガ",
    "アニメ",
    "モダンアート",
    "西洋のクラシックアート",
    "ポップアート",
    "リアリズム",
    "チャイルドアート",
    "伝統的な日本画風",
    "コンセプトアート",
    "ピクセルアート",
    "水彩",
    "グラフティ",
    "線画風",
    "バロック",
  ];

  const num_options = [];
  for (let i = 1; i <= 10; i++) {
    num_options.push(i);
  }

  const size_options = [
    {
      name: "正方形(1:1)",
      val: "1024x1024",
    },
    {
      name: "縦長(9:16)",
      val: "1024x1792",
    },
    {
      name: "横長(16:9)",
      val: "1792x1024",
    },
  ];

  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      num: 1, // 生成数のデフォルト値
      prompt: "",
      style: "",
      size: "",
      // 他のフィールドのデフォルト値もここに設定できます
    },
  });

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();
    if (result) {
      const data = getValues();
      props.submit(data);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button
          className="px-4 rounded-full bg-gradient-to-r from-red-500 to-purple-600 py-3 text-md font-semibold text-white shadow-sm hover:opacity-90"
          onClick={props.cancelStreaming}
        >
          生成中...30秒ほどかかります
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="px-4 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 py-3 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          生成する
        </button>
      );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div className="sm:shadow-lg sm:rounded-lg">
          <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
            <div className="grid lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-3">
              <div className={`col-span-6`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  生成したい画像
                </label>
                <textarea
                  id={`prompt`}
                  {...register(`prompt`, {
                    required: true,
                  })}
                  className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1"
                />

                {errors[`prompt`]?.type === "required" && (
                  <p className="font-semibold text-sm text-red-400">
                    テキストを入力してください
                  </p>
                )}
              </div>
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  画風
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`style`, {
                      required: true,
                    })}
                  >
                    {style_options.map((option) => {
                      return (
                        <option key={`kind-option-${option}`} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors[`style`]?.type === "required" && (
                  <p className="font-semibold text-sm text-red-400">
                    選択してください
                  </p>
                )}
              </div>
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  サイズ
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`size`, {
                      required: true,
                    })}
                  >
                    {size_options.map((option, i) => {
                      return (
                        <option key={`kind-option-${i}`} value={option.val}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors[`size`]?.type === "required" && (
                  <p className="font-semibold text-sm text-red-400">
                    選択してください
                  </p>
                )}
              </div>
              <div className={`col-span-2`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  生成数
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`num`, {
                      required: true,
                    })}
                  >
                    {num_options.map((option) => {
                      return (
                        <option key={`kind-option-${option}`} value={option}>
                          {option}枚
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#f7f9f9] px-4 py-2 text-left sm:px-6">
            {submitBtn()}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
