import React, { useEffect, useState } from "react";
import { Ai } from "../../../interfaces/ai";
import { User } from "../../../interfaces/user";
import { UserSavedText } from "../../../interfaces/user_saved_text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUnreadNotificationCount } from "../../../libs/api/user";
import AffiliateGuide from "../modals/AffiliateGuide";
import UpgradeAlert from "../modals/UpgradeAlert";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

type Props = {
  user?: User;
  favorites: Ai[];
  savedTexts: UserSavedText[];
};
import ListSavedText from "./ListSavedText";
import ListFavorite from "./ListFavorite";
import {
  BadgeCheck,
  Book,
  ChevronRightCircle,
  Heart,
  Home,
  KeyRound,
  LogOut,
  Mail,
  Settings,
  UserCircle2,
  Bell,
  BellOff,
  Link,
  Code,
  Wallet,
  JapaneseYen,
  Users,
  Image,
  PenLine,
  HelpCircle,
  Crown,
  Text,
  Unplug,
  HelpCircleIcon,
} from "lucide-react";
const SidebarLists: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState(props.favorites);
  const [savedTexts, setSavedTexts] = useState(props.savedTexts);
  const [unreadCount, setUnreadCount] = useState(0);
  const [imageHistoryModalOpen, setImageHistoryModalOpen] = useState(false);
  const [openFavorite, setOpenFavorite] = useState(() => {
    const storedState = localStorage.getItem("openFavorite");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [openSavedTexts, setOpenSavedTexts] = useState(() => {
    const storedState = localStorage.getItem("openSavedTexts");
    return storedState ? JSON.parse(storedState) : false;
  });

  const [openAISection, setOpenAISection] = useState(() => {
    const storedState = localStorage.getItem("openAISection");
    return storedState ? JSON.parse(storedState) : false;
  });

  const [openSetting, setOpenSetting] = useState(() => {
    const storedState = localStorage.getItem("openSetting");
    return storedState ? JSON.parse(storedState) : false;
  });

  const [openPlan, setOpenPlan] = useState(() => {
    const storedState = localStorage.getItem("openPlan");
    return storedState ? JSON.parse(storedState) : false;
  });

  const [openAffiliate, setOpenAffiliate] = useState(() => {
    const storedState = localStorage.getItem("openAffiliate");
    return storedState ? JSON.parse(storedState) : false;
  });

  const [guideModalOpen, setGuideModalOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("addFavorite", handleAddFavorite);
    window.addEventListener("removeFavorite", handleRemoveFavorite);
    //保存済みへの保存処理
    window.addEventListener("addSavedText", handleAddSavedText);
    getUnreadNotificationCount().then((res) => {
      setUnreadCount(res.data.unreadCount);
    });
    return () => {
      window.removeEventListener("addFavorite", handleAddFavorite);
      window.removeEventListener("removeFavorite", handleRemoveFavorite);
      window.removeEventListener("addSavedText", handleAddSavedText);
    };
  }, [props.favorites]);

  useEffect(() => {
    localStorage.setItem("openFavorite", JSON.stringify(openFavorite));
  }, [openFavorite]);

  useEffect(() => {
    localStorage.setItem("openSavedTexts", JSON.stringify(openSavedTexts));
  }, [openSavedTexts]);

  useEffect(() => {
    localStorage.setItem("openSetting", JSON.stringify(openSetting));
  }, [openSetting]);

  useEffect(() => {
    localStorage.setItem("openPlan", JSON.stringify(openPlan));
  }, [openPlan]);

  useEffect(() => {
    localStorage.setItem("openAISection", JSON.stringify(openAISection));
  }, [openAISection]);

  useEffect(() => {
    localStorage.setItem("openAffiliate", JSON.stringify(openAffiliate));
  }, [openAffiliate]);

  const handleAddFavorite = (event) => {
    const ai = event.detail.ai;
    setFavorites([...favorites, ai]);
    setOpenFavorite(true);
  };
  const handleRemoveFavorite = (event) => {
    const ai = event.detail.ai;
    setFavorites(favorites.filter((favoriteAi) => favoriteAi.id !== ai.id));
  };
  const handleAddSavedText = (event) => {
    setSavedTexts([...savedTexts, event.detail]);
    setOpenSavedTexts(true);
  };

  const toggleOpenFavorite = () => {
    setOpenFavorite(!openFavorite);
  };

  const toggleOpenSavedTexts = () => {
    setOpenSavedTexts(!openSavedTexts);
  };

  const toggleOpenSetting = (e) => {
    e.preventDefault();
    setOpenSetting(!openSetting);
  };

  const toggleOpenPlan = (e) => {
    e.preventDefault();
    setOpenPlan(!openPlan);
  };

  const toggleOpenAISection = (e) => {
    e.preventDefault();
    setOpenAISection(!openAISection);
  };

  const toggleOpenAffilaite = (e) => {
    e.preventDefault();
    setOpenAffiliate(!openAffiliate);
  };

  const delSavedTexts = (slug) => {
    setSavedTexts(savedTexts.filter((savedText) => savedText.slug !== slug));
  };

  const delFavorites = (slug) => {
    setFavorites(favorites.filter((favorite) => favorite.slug !== slug));
  };

  const isPremium = () => {
    return props.user && props.user.planName == "premium";
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const isPaid = () => {
    return props.user && props.user.planName != "free";
  };

  const isFree = () => {
    return props.user && props.user.planName == "free";
  };

  const onClickImageHistory = () => {
    if (props.user && props.user.isPaidDallE) {
      location.href = "/users/image_ais/histories";
    } else {
      setImageHistoryModalOpen(true);
    }
  };

  return (
    <>
      <ul className="space-y-1">
        <li>
          <a
            className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
            href="/users/image_ais"
          >
            <div className="text-gray-700">
              <Image className="w-5 h-5" />
            </div>
            <span className="ml-2">画像生成ツール</span>
          </a>
        </li>
        {!isPremium() && (
          <li>
            <a
              className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
              href="/users/option_plans/image_generator"
            >
              <div className="text-gray-700">
                <BadgeCheck className="w-5 h-5" />
              </div>
              <span className="ml-2 font-bold underline decoration-orange-500 decoration-4 ">
                画像生成の枚数追加する
              </span>
            </a>
          </li>
        )}

        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="space-y-1">
              <li>
                <a
                  href={`#`}
                  className={classNames(
                    "group flex rounded-md px-2 py-2 text-md leading-6 font-semibold text-gray-700 items-center hover:bg-gray-100 relative"
                  )}
                  onClick={toggleOpenPlan}
                >
                  <div className="text-gray-700">
                    <Crown className="w-5 h-5" />
                  </div>
                  <span className="ml-2 font-bold underline decoration-orange-500 decoration-4">
                    有料プラン一覧
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  />
                </a>
                <ul>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100"
                      href="/users/option_plans/image_generator"
                    >
                      <div className="text-gray-700">
                        <Image className="w-5 h-5" />
                      </div>
                      <span className="ml-2">画像生成プレミアム</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        {/* AIマーケ大学講座 */}
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="space-y-1">
              <li>
                <a
                  href={`#`}
                  className={classNames(
                    "group flex rounded-md px-2 py-2 text-md leading-6 font-semibold text-gray-700 items-center hover:bg-gray-100 relative"
                  )}
                  onClick={toggleOpenAISection}
                >
                  <div className="text-gray-700">
                    <Book className="w-5 h-5" />
                  </div>
                  <span className="ml-2">AIマーケ大学講座</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  />
                </a>
                <ul>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">すべての講座</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles/article_categories/tiktok"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">TikTok収益化部</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles/article_categories/youtube"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">YouTube収益化部</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles/article_categories/uranai"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">副業占い部</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles/article_categories/eigyou"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">営業入門講座</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles/article_categories/collabo"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">コラボ記事</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/articles/article_categories/katuyou"
                    >
                      <div className="text-gray-700">
                        <ChevronRightCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">クラウドワークスAI活用</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <li>
          <a
            className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
            href="/users/notifications"
          >
            <div className="text-gray-700">
              <Bell className="w-5 h-5" />
            </div>
            <span className="ml-2">お知らせ</span>
            {unreadCount != 0 && (
              <span className="ml-2 inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">
                {unreadCount}
              </span>
            )}
          </a>
        </li>
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="space-y-1">
              <li>
                <a
                  href={`#`}
                  className={classNames(
                    "group flex rounded-md px-2 py-2 text-md leading-6 font-semibold text-gray-700 items-center hover:bg-gray-100 relative"
                  )}
                  onClick={toggleOpenAffilaite}
                >
                  <div className="text-gray-700">
                    <Link className="w-5 h-5" />
                  </div>
                  <span className="ml-2">アフィリエイト</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  />
                </a>
                <ul>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/affiliates/users"
                    >
                      <div className="text-gray-700">
                        <Users className="w-5 h-5" />
                      </div>
                      <span className="ml-2">獲得ユーザ一覧</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/affiliates"
                    >
                      <div className="text-gray-700">
                        <UserCircle2 className="w-5 h-5" />
                      </div>
                      <span className="ml-2">成果一覧</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/affiliates/new"
                    >
                      <div className="text-gray-700">
                        <Code className="w-5 h-5" />
                      </div>
                      <span className="ml-2">タグ</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/affiliates/bank_account"
                    >
                      <div className="text-gray-700">
                        <Wallet className="w-5 h-5" />
                      </div>
                      <span className="ml-2">振込先銀行口座</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/affiliates/payout_request"
                    >
                      <div className="text-gray-700">
                        <JapaneseYen className="w-5 h-5" />
                      </div>
                      <span className="ml-2">振込申請一覧</span>
                    </a>
                  </li>

                  <li className="pl-2">
                    <button
                      onClick={() => setGuideModalOpen(true)}
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                    >
                      <div className="text-gray-700">
                        <HelpCircle className="w-5 h-5" />
                      </div>
                      <span className="ml-2">報酬について</span>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="space-y-1">
              <li>
                <a
                  href={`#`}
                  className={classNames(
                    "group flex rounded-md px-2 py-2 text-md leading-6 font-semibold text-gray-700 items-center hover:bg-gray-100 relative"
                  )}
                  onClick={toggleOpenSetting}
                >
                  <div className="text-gray-700">
                    <Settings className="w-5 h-5" />
                  </div>
                  <span className="ml-2">設定</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  />
                </a>
                <ul>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/profile"
                    >
                      <div className="text-gray-700">
                        <UserCircle2 className="w-5 h-5" />
                      </div>
                      <span className="ml-2">登録情報</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100"
                      href="/users/edit"
                    >
                      <div className="text-gray-700">
                        <KeyRound className="w-5 h-5" />
                      </div>
                      <span className="ml-2">ログイン情報変更</span>
                    </a>
                  </li>

                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/connect"
                    >
                      <div className="text-gray-700">
                        <Unplug className="w-5 h-5" />
                      </div>
                      <span className="ml-2">サービス連携</span>
                    </a>
                  </li>

                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100"
                      href="/users/myplan"
                    >
                      <div className="text-gray-700">
                        <i className="fa-solid fa-bag-shopping w-5"></i>
                      </div>
                      <span className="ml-2">契約プラン情報</span>
                    </a>
                  </li>

                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100"
                      href="/users/invoices"
                    >
                      <div className="text-gray-700">
                        <i className="fa-solid fa-file w-5"></i>
                      </div>
                      <span className="ml-2">請求一覧</span>
                    </a>
                  </li>

                  {/*}
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-sm font-normal text-gray-700 rounded-lg hover:bg-gray-100"
                        href="/users/services"
                      >
                        <div className="text-gray-700">
                          <i className="fa-solid fa-plug w-5"></i>
                        </div>
                        <span className="ml-2">サービス連携</span>
                      </a>
                    </li>
                    {*/}
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="/users/notify_settings"
                    >
                      <div className="text-gray-700">
                        <BellOff className="w-5 h-5" />
                      </div>
                      <span className="ml-2">通知設定</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="https://crowdworks-ai.notion.site/a370de76749b4e4b815b43479cc45591?pvs=4"
                      target="_blank"
                    >
                      <div className="text-gray-700">
                        <HelpCircleIcon className="w-5 h-5" />
                      </div>
                      <span className="ml-2">よくある質問</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                      href="https://s.lmes.jp/landing-qr/1661019655-yV8YWZVn?uLand=xp9Mmz"
                      target="_blank"
                    >
                      <div className="text-gray-700">
                        <Mail className="w-5 h-5" />
                      </div>
                      <span className="ml-2">お問い合わせ</span>
                    </a>
                  </li>
                  <li className="pl-2">
                    <a
                      className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100"
                      rel="nofollow"
                      data-method="delete"
                      href="/users/sign_out"
                    >
                      <div className="text-gray-700">
                        <LogOut className="w-5 h-5" />
                      </div>
                      <span className="ml-2">ログアウト</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </ul>
      {props.user && (
        <>
          {favorites.length > -1 && (
            <ul className="pt-4 mt-4 space-y-2 font-medium text-md border-t border-gray-200">
              <li>
                <button
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 w-full"
                  onClick={onClickImageHistory}
                >
                  <div className="text-gray-800">
                    <Image className="w-5 h-5" />
                  </div>
                  <span className="ml-2">画像生成履歴</span>
                </button>
              </li>

              <li>
                <div
                  className="flex-1 ml-2 text-left whitespace-nowrap w-full cursor-pointer relative font-semibold text-gray-700 "
                  onClick={toggleOpenFavorite}
                >
                  <div className="flex justify-start items-center">
                    <Heart className="w-5 h-5" />
                    <span className="ml-2">お気に入り</span>
                  </div>

                  <FontAwesomeIcon
                    icon={openFavorite ? faChevronDown : faChevronRight}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  />
                </div>
                {openFavorite && (
                  <ul className="mt-3">
                    {favorites.map((favorite) => {
                      return (
                        <ListFavorite
                          key={`favorite-${favorite.slug}`}
                          delFavorites={delFavorites}
                          favorite={favorite}
                        />
                      );
                    })}
                  </ul>
                )}
              </li>
            </ul>
          )}

          {isFree() && (
            <>
              <div className="hidden sm:block mt-4">
                <a
                  href="https://cwdojo.app/redirect/b199b3fe-04db-4665-bd12-94a2001ec9af"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    className="rounded-lg shadow"
                    src="/img/pureline.png"
                    draggable="false"
                  />
                </a>
              </div>
            </>
          )}
        </>
      )}
      <AffiliateGuide
        open={guideModalOpen}
        setOpen={setGuideModalOpen}
        images={["/img/image_ai_afi.png"]}
      />
      <UpgradeAlert
        open={imageHistoryModalOpen}
        setOpen={setImageHistoryModalOpen}
        title={`アップグレードが必要です`}
        message={`AI画像の生成履歴閲覧には「画像プレミアムプラン」へのアップグレードが必要となります`}
        redirectLink={`/users/option_plans/image_generator`}
        cta={`画像プレミアムプランを見る`}
      />
    </>
  );
};

export default SidebarLists;
