import React from "react";
import { User } from "../../../../interfaces/user";
import { Plan } from "../../../../interfaces/plan";
import { Coupon } from "../../../../interfaces/coupon";
type Props = {
  plans: Plan[];
  user: User;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
};
import PlanTable from "../../../users/plans/Table";
import PlanFaqs from "../../../users/plans/Faqs";

const UsersPlans: React.FC<Props> = (props) => {
  return (
    <>
      <PlanTable
        plans={props.plans}
        user={props.user}
        stripeKey={props.stripeKey}
        ThreeDSecureSupported={props.ThreeDSecureSupported}
        coupon={props.coupon}
      />
      <PlanFaqs />
    </>
  );
};
export default UsersPlans;
