import React, { useState } from "react";

import UpgradeAfterLoginModal from "../partials/modals/UpgradeAfterLogin";
import LineGiftModal from "../partials/modals/LineGift";
import UpdateEmptyEmail from "../partials/modals/UpdateEmptyEmail";
import UpdatePrefectureModal from "../partials/modals/UpdatePrefecture";

import { Prefecture } from "../../interfaces/prefecture";

type Props = {
  openUpgradeModal: boolean;
  openLineGiftModal: boolean;
  openPrefectureModal: boolean;
  openUpdateEmptyEmailModal: boolean;
  lineGiftImageUrl: string;
  lineGiftLink: string;
  prefectures: Prefecture[];
};

const UsersLayout: React.FC<Props> = (props) => {
  const [openUpgradeModal, setOpenUpgradeModal] = useState(
    props.openUpgradeModal
  );
  const [openLineGiftModal, setOpenLineGiftModal] = useState(
    props.openLineGiftModal
  );
  const [openUpdateEmptyEmailModal, setOpenUpdateEmptyEmailModal] = useState(
    props.openUpdateEmptyEmailModal
  );
  const [openPrefectureModal, setOpenPrefectureModal] = useState(
    props.openPrefectureModal
  );
  return (
    <>
      <UpgradeAfterLoginModal
        open={openUpgradeModal}
        setOpen={setOpenUpgradeModal}
      />
      <LineGiftModal
        open={openLineGiftModal}
        setOpen={setOpenLineGiftModal}
        imageUrl={props.lineGiftImageUrl}
        link={props.lineGiftLink}
      />
      <UpdateEmptyEmail
        open={openUpdateEmptyEmailModal}
        setOpen={setOpenUpdateEmptyEmailModal}
      />
      <UpdatePrefectureModal
        open={openPrefectureModal}
        setOpen={setOpenPrefectureModal}
        prefectures={props.prefectures}
      />
    </>
  );
};
export default UsersLayout;
