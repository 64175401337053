import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import BankForm from "../../users/affiliates/BankForm";
import { updateUserBankAccount } from "../../../libs/api/users/bank_account";
import { BankAccount } from "../../../interfaces/bank_account";
type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  onSubmit: () => void;
  bankAccount: BankAccount;
};

const EmptyBankAccountAlert: React.FC<Props> = (props) => {
  const onSubmit = async (data) => {
    try {
      await updateUserBankAccount(data);
      toast.success("登録しました");
      props.onSubmit();
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            return false;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      振込先の銀行口座を登録してください
                    </Dialog.Title>
                    <div className="mt-2">
                      <BankForm
                        onSubmit={onSubmit}
                        bankAccount={props.bankAccount}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default EmptyBankAccountAlert;
