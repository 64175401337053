import React from "react";
import {NewBadge} from "@/components/biz/partials/Badge";

type Props = {
  publishedAt: string;
};

export const New: React.FC<Props> = ({publishedAt}) => {
  const isNew = (publishedAt: string): boolean => {
    const publishedDate = new Date(publishedAt);
    const now = new Date();
    const twoWeeks = 14 * 24 * 60 * 60 * 1000;
    const twoWeeksAgo = new Date(now.getTime() - twoWeeks);
    return publishedDate > twoWeeksAgo;
  };

  return isNew(publishedAt) ? (
    <div className="pr-1">
      <NewBadge/>
    </div>
  ) : null;
};
