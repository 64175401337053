import React, { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { suggestAi } from "../../libs/api/users/ai";
import { AiList } from "../../../../interfaces/ai";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SuggestionForm = () => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<Ai[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await suggestAi(inputValue);
        setSuggestions(response); // 取得したデータで状態を更新
        setShowDropdown(true);
      } catch (error) {
        console.error("サジェスチョンの取得に失敗しました", error);
        setShowDropdown(false);
      }
    };

    if (inputValue) {
      fetchSuggestions(); // 入力値がある場合のみサジェスチョンを取得
    } else {
      setSuggestions([]); // 入力がない場合はサジェスチョンをクリア
      setShowDropdown(false);
    }
  }, [inputValue]); // inputValueが変更されたときにuseEffectを実行

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <label
        htmlFor="search"
        className="block text-md font-semibold text-gray-900"
      >
        生成ツールをキーワードで検索
      </label>
      <div className="relative mt-2 flex items-center">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="border border-gray-300 rounded-md p-2 w-full"
          placeholder="例)ブログ"
          name="search"
        />
      </div>

      <Transition
        as={"div"}
        show={showDropdown}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {showDropdown && (
          <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {suggestions.map((ai, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <a
                      href={`/users/ais/${ai.slug}`}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm"
                      )}
                    >
                      <img
                        src={`${ai.image.thumb.url}`}
                        className="mr-3 h-12 w-12 rounded-full"
                      />
                      {ai.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        )}
      </Transition>
    </Menu>
  );
};

export default SuggestionForm;
