import React from "react";
import { Ai } from "@/interfaces/ai";
import { toast } from "react-toastify";
import { toggleUserFavorite } from "@/libs/api/users/user_favorite_ai";
import { StarIcon as OutlineStarIcon } from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";

type Props = {
  ai: Ai;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const FavoriteButton: React.FC<Props> = ({ ai, favorites, setFavorites }) => {
  const isFavorite = () => {
    return favorites.map((f) => f.id).includes(ai.id);
  };

  const addFavorite = async (ai: Ai) => {
    setFavorites((prevFavorites: Ai[]) => [...prevFavorites, ai]);
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("addFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りに追加しました");
  };

  const removeFavorite = async (ai: Ai) => {
    setFavorites((prevFavorites: Ai[]) =>
        prevFavorites.filter((faAi) => faAi.id !== ai.id)
    );
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("removeFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りから解除しました");
  };

  return (
      <button
          type="button"
          className="absolute top-0 right-0 m-2 p-2 bg-transparent border-none flex items-start justify-end z-50"
          onClick={() => (isFavorite() ? removeFavorite(ai) : addFavorite(ai))}
      >
        {isFavorite() ? (
            <SolidStarIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        ) : (
            <OutlineStarIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
        )}
      </button>
  );
};

export default FavoriteButton;
